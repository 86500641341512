import { useEffect, useState } from 'react';
import HeaderNew2 from "../components/HeaderNew2";
import Footer from "../components/Footer";
import pandcoin from '../assets/images/pand.png';
import oreocoin from '../assets/images/oreo.png';
import overlayimg1 from '../assets/images/overlayimg1.jpg';
import "../assets/css/blog.scss";
import "../assets/css/faq.scss";
import { Loader } from '../components/Loader';
import { LOADER_TIMER } from '../constants';
import loadingimg from '../assets/images/Loadingwhite.webm';
import FloatingSearch from '../components/FloatingSearch';
import logotech from '../assets/images/tchy logo.png';
import Accordion from 'react-bootstrap/Accordion';
import Dropdown from 'react-bootstrap/Dropdown';
import JustifiedExample from "../components/TabsCommon";
import TabsCommonBlog from "../components/TabsCommonBlog";

export const BlogPage = () => {

    const [loader, setLoader] = useState(true);
    const [discoverSectionLoader, setDiscoverSectionLoader] = useState(false)
    const [isDiscoverSectionVisible, setIsDiscoverSectionVisible] = useState(false)
    const [timeout, setTimeoutState] = useState(null)

    useEffect(() => {
        if (discoverSectionLoader) {
            const timeout = setTimeout(() => {
                setDiscoverSectionLoader(false);
                setIsDiscoverSectionVisible(true);
                console.log(isDiscoverSectionVisible)
            }, 700)
            setTimeoutState(timeout)
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }


    }, [discoverSectionLoader])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoader(false);
        }, LOADER_TIMER)
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [])

    const loaderActionDiscoverSection = (value) => {
        setDiscoverSectionLoader(value)
    }
    return (
        <>
            <Loader show={loader} />
            <main className='blogpage_main'>
                <HeaderNew2 />

                <div className='container'>
                    <div className=" heep_sec_detail2 mb-5 m_top">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="heep_sec_detail2_right m_top">
                                    <div className="row">
                                        <div className="col-xl-3">
                                            <div className="heep_sec_detail2_left newsticky_blog">
                                                <h3>Blog <br />
                                                    <span className='s_et'> Crypto News</span></h3>
                                                <p className='b_bottom'>Browse the best premium and lastest crypto news</p>
                                                <div className='acc1_detail cate_select_check'>
                                                    <Accordion defaultActiveKey="0">
                                                        <Accordion.Item eventKey="0">
                                                            <Accordion.Header>Category</Accordion.Header>
                                                            <Accordion.Body>
                                                                <div className='cat_all'>
                                                                    <div className='blog_tick_cat'>
                                                                        <div><span>All Categories</span></div>
                                                                        <label class="check_custom"><input type="checkbox" checked class="drop_input" /><span class="checkmark"></span></label>
                                                                    </div>
                                                                    <div className='blog_tick_cat'>
                                                                        <div><span>Finance</span></div>
                                                                        <label class="check_custom"><input type="checkbox" class="drop_input" /><span class="checkmark"></span></label>
                                                                    </div>
                                                                    <div className='blog_tick_cat'>
                                                                        <div><span>Bitcoin</span></div>
                                                                        <label class="check_custom"><input type="checkbox" class="drop_input" /><span class="checkmark"></span></label>
                                                                    </div>
                                                                    <div className='blog_tick_cat'>
                                                                        <div><span>NFT</span></div>
                                                                        <label class="check_custom"><input type="checkbox" class="drop_input" /><span class="checkmark"></span></label>
                                                                    </div>
                                                                    <div className='blog_tick_cat'>
                                                                        <div><span>NFT</span></div>
                                                                        <label class="check_custom"><input type="checkbox" class="drop_input" /><span class="checkmark"></span></label>
                                                                    </div>
                                                                    <div className='blog_tick_cat'>
                                                                        <div><span>NFT</span></div>
                                                                        <label class="check_custom"><input type="checkbox" class="drop_input" /><span class="checkmark"></span></label>
                                                                    </div>
                                                                </div>

                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                    <Accordion defaultActiveKey="1">
                                                        <Accordion.Item eventKey="1">
                                                            <Accordion.Header>Sort By</Accordion.Header>
                                                            <Accordion.Body>
                                                                <ul>
                                                                    <li>   <a>Latest</a>
                                                                    </li>
                                                                    <li>
                                                                        <a>Most Relevant</a>
                                                                    </li>
                                                                    <li><a>Most Seen</a></li>
                                                                </ul>
                                                            </Accordion.Body>
                                                        </Accordion.Item>



                                                    </Accordion>
                                                </div>

                                            </div>

                                        </div>
                                        <div className='col-xl-9'>
                                            <div className='row heep_sec_detail New_blog'>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img className='logoimg' src={logotech} />
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Tchy Team </div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Category</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Build a web app wide Lorem Ipsum has been the industry  web app wide Lorem</h3>
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img src={oreocoin} />
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Category</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Modern website Lorem Ipsum has been the industry</h3>
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img src={pandcoin} />
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Category</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Set-up automations Lorem Ipsum has been the industry text of the printing</h3>
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                            </p>

                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img src={pandcoin} />
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Category</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Build a web app wide Lorem Ipsum has been the industry</h3>
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img src={oreocoin} />
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Category</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Modern website Build a web app wide Lorem Ipsum has been the industry</h3>
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img src={pandcoin} />
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Category</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Set-up automations Build a web app wide Lorem Ipsum has been the industry</h3>
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                            </p>

                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img src={pandcoin} />
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Category</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Build a web app Build a web app wide Lorem Ipsum has been the industry</h3>
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img src={pandcoin} />
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Category</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Build a web app wide Lorem Ipsum has been the industry</h3>
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img src={oreocoin} />
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Category</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Modern website Build a web app wide Lorem Ipsum has been the industry</h3>
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img src={oreocoin} />
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Category</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Modern website Build a web app wide Lorem Ipsum has been the industry</h3>
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img src={pandcoin} />
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Category</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Set-up automations Build a web app wide Lorem Ipsum has been the industry</h3>
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                            </p>

                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img src={pandcoin} />
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Category</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Build a web app Build a web app wide Lorem Ipsum has been the industry</h3>
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img src={pandcoin} />
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Category</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Build a web app wide Lorem Ipsum has been the industry</h3>
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img src={oreocoin} />
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Category</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Modern website Build a web app wide Lorem Ipsum has been the industry</h3>
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img src={pandcoin} />
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Category</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Set-up automations Build a web app wide Lorem Ipsum has been the industry</h3>
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                            </p>

                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img src={pandcoin} />
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Category</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Build a web app Build a web app wide Lorem Ipsum has been the industry</h3>
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img src={pandcoin} />
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Category</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Build a web app wide Lorem Ipsum has been the industry</h3>
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img src={oreocoin} />
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Category</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Modern website Build a web app wide Lorem Ipsum has been the industry</h3>
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img src={pandcoin} />
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Category</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Set-up automations Build a web app wide Lorem Ipsum has been the industry</h3>
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                            </p>

                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img src={pandcoin} />
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Category</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Build a web app Build a web app wide Lorem Ipsum has been the industry</h3>
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img src={pandcoin} />
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Category</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Build a web app wide Lorem Ipsum has been the industry</h3>
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="f-right text-center justify-content-center mt-0">
                                                    <ul class="pagination just-center">
                                                        <li class="page-item">
                                                            <a class="page-link" href="#" aria-label="Previous">
                                                                <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                                                                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                                                </svg></span>
                                                                <span class="sr-only"></span>
                                                            </a>
                                                        </li>
                                                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                        <li class="page-item">
                                                            <a class="page-link" href="#" aria-label="Next">
                                                                <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                                                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                                                </svg></span>
                                                                <span class="sr-only"></span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>




                                                <div className="More_blog_tabs">
                            <div className="">
                           
                                <div className="built_maker_heep_right">
                                    <div className="content-tabs">
                                    <div class="coinpage_head text-center"><h3 className=''>Coins</h3><h6>Discover our cryptocurrencies directory  </h6></div>
                                    <div className='filter_head coin_filter_head'>
                              
                                    
                                    </div>
                                    <TabsCommonBlog/>
                                        {/* <div className="table_pagination mt-3">
                                            <div aria-label="Page navigation example">
                                                <ul class="pagination">
                                                    <li class="page-item">
                                                        <a class="page-link" href="#" aria-label="Previous">
                                                            <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                                            </svg></span>
                                                            <span class="sr-only"></span>
                                                        </a>
                                                    </li>
                                                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                    <li class="page-item">
                                                        <a class="page-link" href="#" aria-label="Next">
                                                            <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                                            </svg></span>
                                                            <span class="sr-only"></span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                                            </div>
                          
                                        </div>

                                    </div>
                                </div>

                            </div>

                        </div>



                    </div>

                </div>
                <Footer />
                <FloatingSearch />
            </main>
        </>
    )
}