import React from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import bsccoin from '../../assets/images/bsc.png';
import icoin4 from '../../assets/images/icon4.png';
import icoin3 from '../../assets/images/icon3.png';
import icoin10 from '../../assets/images/icon10.png';
import icoin21 from '../../assets/images/icon21.png';
import icoin22 from '../../assets/images/icon22.png';
import icoin23 from '../../assets/images/icon23.png';
import icoin24 from '../../assets/images/icon24.png';
import icoin25 from '../../assets/images/icon25.png';
import icoin26 from '../../assets/images/icon26.png';
import icoin27 from '../../assets/images/icon27.png';


function TabcommData4() {
    return (
        <>
        <div className='table-responsive'>
            <table className="table table-control">
                <thead>
                    <tr>
                        <th colspan="6" width="25%">Coin Name</th>
                        <th scope="col" width="8%">Chain <svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                            <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                            <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                        </svg></th>
                                                                                <th scope="col" width="15%">Price <svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                            <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                            <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                        </svg></th>
                        <th scope="col" width="15%">24H <svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                            <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                            <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                        </svg></th>
                        <th scope="col" width="15%">Market Cap <svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                            <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                            <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                        </svg></th>

                        <th scope="col" width="15%">Age <svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                            <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                            <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                        </svg></th>
                        <th scope="col" width="8%" className="text-center">Company </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colspan="6">
                            <div className="listing_coin_detail">
                                <div className="coin_logo"><img src={icoin21} /></div>
                                <div className="list_detail">
                                    <div class="info">
                                        <div><a href="#"><strong></strong></a><strong>Bitcoin</strong></div>
                                        <div class="hidden-sm">BTC</div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td><div className='coin_box'><div className="coinimg"><div className="coinlogo"><img src={bsccoin} /></div><span className="ps-2">BSC</span></div></div></td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>4 months ago </td>
                        <td className="text-center vote_drpdwn_btn">   <Dropdown>
                            <Dropdown.Toggle variant="btn" id="dropdown-basic" className="new-bg">
                                <svg width="47" height="137" viewBox="0 0 47 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.2367 13.0401L4.77261 29.5754L0 25.1268L23.5 0C31.332 8.37562 39.168 16.7512 47 25.1268L42.2274 29.5754L26.7633 13.0401V123.96L42.2274 107.425L47 111.873L23.5 137L0 111.873L4.77261 107.425L20.2367 123.96V13.0401Z" fill="white" />
                                </svg>
                                <span>50958</span></Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Up</Dropdown.Item>
                                <Dropdown.Item href="#/action-2"> Down</Dropdown.Item>

                            </Dropdown.Menu>
                        </Dropdown></td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div className="listing_coin_detail">
                                <div className="coin_logo"><img src={icoin4} /></div>
                                <div className="list_detail">
                                    <div class="info">
                                        <div><a href="#"><strong></strong></a><strong>Shiba Inu</strong></div>
                                        <div class="hidden-sm">SHiB</div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td><div className='coin_box'><div className="coinimg"><div className="coinlogo"><img src={bsccoin} /></div><span className="ps-2">AETH</span></div></div></td>

                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>7 days ago</td>
                        <td className="text-center vote_drpdwn_btn">   <Dropdown>
                            <Dropdown.Toggle variant="btn" id="dropdown-basic" className="new-bg">
                                <svg width="47" height="137" viewBox="0 0 47 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.2367 13.0401L4.77261 29.5754L0 25.1268L23.5 0C31.332 8.37562 39.168 16.7512 47 25.1268L42.2274 29.5754L26.7633 13.0401V123.96L42.2274 107.425L47 111.873L23.5 137L0 111.873L4.77261 107.425L20.2367 123.96V13.0401Z" fill="white" />
                                </svg>
                                <span>50958</span></Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Up</Dropdown.Item>
                                <Dropdown.Item href="#/action-2"> Down</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown></td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div className="listing_coin_detail">
                                <div className="coin_logo"><img src={icoin22} /></div>
                                <div className="list_detail">
                                    <div class="info">
                                        <div><a href="#"><strong></strong></a><strong>NoriGO!</strong></div>
                                        <div class="hidden-sm">GO!</div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td><div className='coin_box'><div className="coinimg"><div className="coinlogo"><img src={bsccoin} /></div><span className="ps-2">BSC</span></div></div></td>
                        <td className="green">$ 0.000099</td>
                        <td><span className="red-text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="red" viewBox="0 0 16 16"><path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"></path></svg>0.89%</span></td>
                        <td>$ 9,902</td>
                        <td>13 days ago</td>
                        <td className="text-center vote_drpdwn_btn">   <Dropdown>
                            <Dropdown.Toggle variant="btn" id="dropdown-basic" className="new-bg"><svg width="47" height="137" viewBox="0 0 47 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.2367 13.0401L4.77261 29.5754L0 25.1268L23.5 0C31.332 8.37562 39.168 16.7512 47 25.1268L42.2274 29.5754L26.7633 13.0401V123.96L42.2274 107.425L47 111.873L23.5 137L0 111.873L4.77261 107.425L20.2367 123.96V13.0401Z" fill="white" />
                            </svg><span>50958</span></Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Up</Dropdown.Item>
                                <Dropdown.Item href="#/action-2"> Down</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown></td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div className="listing_coin_detail">
                                <div className="coin_logo"><img src={icoin23} /></div>
                                <div className="list_detail">
                                    <div class="info">
                                        <div><a href="#"><strong></strong></a><strong>STEPN</strong></div>
                                        <div class="hidden-sm">GMT</div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td><div className='coin_box'><div className="coinimg"><div className="coinlogo"><img src={bsccoin} /></div><span className="ps-2">BSC</span></div></div></td>
                        <td className="green">$ 0.0</td>
                        <td>-</td>
                        <td>$ 121</td>
                        <td>8 days ago</td>
                        <td className="text-center vote_drpdwn_btn">   <Dropdown>
                            <Dropdown.Toggle variant="btn" id="dropdown-basic" className="new-bg"><svg width="47" height="137" viewBox="0 0 47 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.2367 13.0401L4.77261 29.5754L0 25.1268L23.5 0C31.332 8.37562 39.168 16.7512 47 25.1268L42.2274 29.5754L26.7633 13.0401V123.96L42.2274 107.425L47 111.873L23.5 137L0 111.873L4.77261 107.425L20.2367 123.96V13.0401Z" fill="white" />
                            </svg><span>50958</span></Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Up</Dropdown.Item>
                                <Dropdown.Item href="#/action-2"> Down</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown></td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div className="listing_coin_detail">
                                <div className="coin_logo"><img src={icoin3} /></div>
                                <div className="list_detail">
                                    <div class="info">
                                        <div><a href="#"><strong></strong></a><strong>PancakeSwap</strong></div>
                                        <div class="hidden-sm">CAKE</div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td><div className='coin_box'><div className="coinimg"><div className="coinlogo"><img src={bsccoin} /></div><span className="ps-2">BSC</span></div></div></td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>6 days ago</td>
                        <td className="text-center vote_drpdwn_btn">   <Dropdown>
                            <Dropdown.Toggle variant="btn" id="dropdown-basic" className="new-bg"><svg width="47" height="137" viewBox="0 0 47 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.2367 13.0401L4.77261 29.5754L0 25.1268L23.5 0C31.332 8.37562 39.168 16.7512 47 25.1268L42.2274 29.5754L26.7633 13.0401V123.96L42.2274 107.425L47 111.873L23.5 137L0 111.873L4.77261 107.425L20.2367 123.96V13.0401Z" fill="white" />
                            </svg><span>50958</span></Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Up</Dropdown.Item>
                                <Dropdown.Item href="#/action-2"> Down</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown></td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div className="listing_coin_detail">
                                <div className="coin_logo"><img src={icoin24} /></div>
                                <div className="list_detail">
                                    <div class="info">
                                        <div><a href="#"><strong></strong></a><strong>Polygon</strong></div>
                                        <div class="hidden-sm">MATIC</div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td><div className='coin_box'><div className="coinimg"><div className="coinlogo"><img src={bsccoin} /></div><span className="ps-2">BSC</span></div></div></td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>in 5 days</td>
                        <td className="text-center vote_drpdwn_btn">   <Dropdown>
                            <Dropdown.Toggle variant="btn" id="dropdown-basic" className="new-bg"><svg width="47" height="137" viewBox="0 0 47 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.2367 13.0401L4.77261 29.5754L0 25.1268L23.5 0C31.332 8.37562 39.168 16.7512 47 25.1268L42.2274 29.5754L26.7633 13.0401V123.96L42.2274 107.425L47 111.873L23.5 137L0 111.873L4.77261 107.425L20.2367 123.96V13.0401Z" fill="white" />
                            </svg><span>850958</span></Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Up</Dropdown.Item>
                                <Dropdown.Item href="#/action-2"> Down</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown></td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div className="listing_coin_detail">
                                <div className="coin_logo"><img src={icoin25} /></div>
                                <div className="list_detail">
                                    <div class="info">
                                        <div><a href="#"><strong></strong></a><strong>Optimism</strong></div>
                                        <div class="hidden-sm">OP</div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td><div className='coin_box'><div className="coinimg"><div className="coinlogo"><img src={bsccoin} /></div><span className="ps-2">BSC</span></div></div></td>
                        <td>-</td>
                        <td>-</td>
                        <td>Presale</td>
                        <td>in 5 months</td>
                        <td className="text-center vote_drpdwn_btn">   <Dropdown>
                            <Dropdown.Toggle variant="btn" id="dropdown-basic" className="new-bg"><svg width="47" height="137" viewBox="0 0 47 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.2367 13.0401L4.77261 29.5754L0 25.1268L23.5 0C31.332 8.37562 39.168 16.7512 47 25.1268L42.2274 29.5754L26.7633 13.0401V123.96L42.2274 107.425L47 111.873L23.5 137L0 111.873L4.77261 107.425L20.2367 123.96V13.0401Z" fill="white" />
                            </svg><span>10258</span></Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Up</Dropdown.Item>
                                <Dropdown.Item href="#/action-2"> Down</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown></td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div className="listing_coin_detail">
                                <div className="coin_logo"><img src={icoin26} /></div>
                                <div className="list_detail">
                                    <div class="info">
                                        <div><a href="#"><strong></strong></a><strong>Ethereum</strong></div>
                                        <div class="hidden-sm">ETH</div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td><div className='coin_box'><div className="coinimg"><div className="coinlogo"><img src={bsccoin} /></div><span className="ps-2">AETH</span></div></div></td>

                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>7 days ago</td>
                        <td className="text-center vote_drpdwn_btn">   <Dropdown>
                            <Dropdown.Toggle variant="btn" id="dropdown-basic" className="new-bg">
                                <svg width="47" height="137" viewBox="0 0 47 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.2367 13.0401L4.77261 29.5754L0 25.1268L23.5 0C31.332 8.37562 39.168 16.7512 47 25.1268L42.2274 29.5754L26.7633 13.0401V123.96L42.2274 107.425L47 111.873L23.5 137L0 111.873L4.77261 107.425L20.2367 123.96V13.0401Z" fill="white" />
                                </svg>
                                <span>50958</span></Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Up</Dropdown.Item>
                                <Dropdown.Item href="#/action-2"> Down</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown></td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div className="listing_coin_detail">
                                <div className="coin_logo"><img src={icoin10} /></div>
                                <div className="list_detail">
                                    <div class="info">
                                        <div><a href="#"><strong></strong></a><strong>XRP</strong></div>
                                        <div class="hidden-sm">XRP </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td><div className='coin_box'><div className="coinimg"><div className="coinlogo"><img src={bsccoin} /></div><span className="ps-2">BSC</span></div></div></td>
                        <td className="green">$ 0.000099</td>
                        <td><span className="red-text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="red" viewBox="0 0 16 16"><path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"></path></svg>0.89%</span></td>
                        <td>$ 9,902</td>
                        <td>13 days ago</td>
                        <td className="text-center vote_drpdwn_btn">   <Dropdown>
                            <Dropdown.Toggle variant="btn" id="dropdown-basic" className="new-bg"><svg width="47" height="137" viewBox="0 0 47 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.2367 13.0401L4.77261 29.5754L0 25.1268L23.5 0C31.332 8.37562 39.168 16.7512 47 25.1268L42.2274 29.5754L26.7633 13.0401V123.96L42.2274 107.425L47 111.873L23.5 137L0 111.873L4.77261 107.425L20.2367 123.96V13.0401Z" fill="white" />
                            </svg><span>50958</span></Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Up</Dropdown.Item>
                                <Dropdown.Item href="#/action-2"> Down</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown></td>
                    </tr>
                    <tr>
                        <td colspan="6">
                            <div className="listing_coin_detail">
                                <div className="coin_logo"><img src={icoin27} /></div>
                                <div className="list_detail">
                                    <div class="info">
                                        <div><a href="#"><strong></strong></a><strong>Fantom</strong></div>
                                        <div class="hidden-sm">FTM</div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td><div className='coin_box'><div className="coinimg"><div className="coinlogo"><img src={bsccoin} /></div><span className="ps-2">BSC</span></div></div></td>
                        <td className="green">$ 0.0</td>
                        <td>-</td>
                        <td>$ 121</td>
                        <td>8 days ago</td>
                        <td className="text-center vote_drpdwn_btn">   <Dropdown>
                            <Dropdown.Toggle variant="btn" id="dropdown-basic" className="new-bg"><svg width="47" height="137" viewBox="0 0 47 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.2367 13.0401L4.77261 29.5754L0 25.1268L23.5 0C31.332 8.37562 39.168 16.7512 47 25.1268L42.2274 29.5754L26.7633 13.0401V123.96L42.2274 107.425L47 111.873L23.5 137L0 111.873L4.77261 107.425L20.2367 123.96V13.0401Z" fill="white" />
                            </svg><span>50958</span></Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">Up</Dropdown.Item>
                                <Dropdown.Item href="#/action-2"> Down</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown></td>
                    </tr>
                </tbody>
            </table>
        </div>


        </>
    );
}

export default TabcommData4;