export const LOADER_TIMER = 700;
export const WebinarAndVirtualEventsItems = [
    {
        value: "Weekly",
        label: "Weekly"
    },
    {
        value: "Monthly",
        label: "Monthly"
    },
    {
        value: "Quartely",
        label: "Quartely"
    },
    {
        value: "Annually",
        label: "Annually"
    },
    {
        value: "Never- But I'd like to start",
        label: "Never- But I'd Like To Start"
    }
];

export const primarilywebinarEventsItems = [
    {
        value: "Pipeline/Lead Generation",
        label: "Pipeline/Lead Generation"
    },
    {
        value: "Customer Marketing/Community Building",
        label: "Customer Marketing/Community Building"
    },
    {
        value: "Product Training",
        label: "Product Training"
    },
    {
        value: "Content Creation",
        label: "Content Creation"
    },
    {
        value: "All Of The Above",
        label: "All Of The Above"
    },
    {
        value: "Other",
        label: "Other"
    }
];
export const listingplatformItems = [
    {
        value: "PancakeSwap",
        label: "PancakeSwap"
    },
    {
        value: "Yodeswap",
        label: "Yodeswap"
    },
    {
        value: "QuickSwap",
        label: "QuickSwap"
    },
    {
        value: "ApeSwap",
        label: "ApeSwap"
    },
    {
        value: "Biswap",
        label: "Biswap"
    },
    {
        value: "MM Finance",
        label: "MM Finance"
    }
];
export const categoryselectItems = [
    {
        value: "DeFi",
        label: "DeFi"
    },
    {
        value: "Gaming",
        label: "Gaming"
    },
    {
        value: "Metaverse",
        label: "Metaverse"
    },
    {
        value: "Yield Farm",
        label: "Yield Farm"
    },
    {
        value: "Launchpad",
        label: "Launchpad"
    },
    {
        value: "NFT",
        label: "NFT"
    },
    {
        value: "Meme",
        label: "Meme"
    },
    {
        value: "Gambling",
        label: "Gambling"
    },
    {
        value: "Music",
        label: "Music"
    },
    {
        value: "Exchange",
        label: "Exchange"
    },
    {
        value: "DApp",
        label: "DApp"
    },
    {
        value: "Play2Earn",
        label: "Play2Earn"
    },
    {
        value: "Move2Earn",
        label: "Move2Earn"
    },
    {
        value: "Web3",
        label: "Web3"
    },
    {
        value: "Other",
        label: "Other"
    }
];

export const SEARCH_BUTTONS = {
    ESCAPE: "ESCAPE",
    CLOSE: "SEARCH_BUTTONS"
}