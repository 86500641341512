import * as React from "react";
import coin from '../assets/images/resn.jpeg';
import coin2 from '../assets/images/coin.png';
import coin3 from '../assets/images/banimg.png';
import '../assets/css/banner.scss';
const Banner = () => {
    return (
        <section className="banner_section">
            <div className="container">
                <div className="prom_project">
                    <div className="prom_head">
                        <h2>Promoted Projects</h2>
                    </div>
                    <div className="prm_detail">
                        <div className="row ">
                            <div className="prom_left">

                            </div>
                            <div className="col-sm-6">
                                <div className="prom_left">
                                    <h3>Promoted Cryptocurrencies</h3>
                                    <div className="prom_left_detail">
                                        <div className="prom_lists prom-left-1">
                                            <div className="prom_list_all">
                                                <div className="prom_list_data">
                                                    <div className="list_logo"><img src="https://s2.coinmarketcap.com/static/img/coins/64x64/1.png" /></div><span className="ps-2">Bitcoin</span>
                                                </div>
                                                <div className="list_bottom">
                                                    <p className="d-flex">Price:<p className="ps-1">$21155.14</p></p>
                                                    <p className="d-flex">Age:<p className="ps-1">14.0 years</p></p>
                                                    {/* <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                    </svg>7.77%</span> */}
                                                </div>
                                            </div>
                                            <div className="prom_list_all">
                                                <div className="prom_list_data">
                                                    <div className="list_logo"><img src="https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/32/eth.webp" /></div><span className="ps-2">ETH</span>
                                                </div>
                                                <div className="list_bottom red">
                                                    <p className="d-flex">Price:<p className="ps-1">$1562.98</p></p>
                                                    <p className="d-flex">Age:<p className="ps-1">7.5 years</p></p>
                                                    {/* <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  className="red"  viewBox="0 0 16 16">
                                                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                    </svg>7.77%</span> */}
                                                </div>
                                            </div>
                                            <div className="prom_list_all">
                                                <div className="prom_list_data">
                                                    <div className="list_logo"><img src="https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/32/usdt.webp" /></div><span className="ps-2">USDT</span>
                                                </div>
                                                <div className="list_bottom red">
                                                    <p className="d-flex">Price:<p className="ps-1">$1.00</p></p>
                                                    <p className="d-flex">Age:<p className="ps-1">8.6 years</p></p>
                                                    {/* <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  className="red"  viewBox="0 0 16 16">
                                                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                    </svg>7.77%</span> */}
                                                </div>
                                            </div>
                                            <div className="prom_list_all">
                                                <div className="prom_list_data">
                                                    <div className="list_logo"><img src="https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/32/bnb.webp" /></div><span className="ps-2">BNB</span>
                                                </div>
                                                <div className="list_bottom">
                                                    <p className="d-flex">Price:<p className="ps-1">$301.51</p></p>
                                                    <p className="d-flex">Age:<p className="ps-1">5.6 years</p></p>
                                                    {/* <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                    </svg>7.77%</span> */}
                                                </div>
                                            </div>
                                            <div className="prom_list_all">
                                                <div className="prom_list_data">
                                                    <div className="list_logo"><img src="https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/32/usdc.webp" /></div><span className="ps-2">USDC</span>
                                                </div>
                                                <div className="list_bottom red">
                                                    <p className="d-flex">Price:<p className="ps-1">$1.00</p></p>
                                                    <p className="d-flex">Age:<p className="ps-1">4.3 years</p></p>
                                                    {/* <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  className="red"  viewBox="0 0 16 16">
                                                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                    </svg>7.77%</span> */}
                                                </div>
                                            </div>
                                            <div className="prom_list_all">
                                                <div className="prom_list_data">
                                                    <div className="list_logo"><img src="https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/32/xrp.webp" /></div><span className="ps-2">XRP</span>
                                                </div>
                                                <div className="list_bottom">
                                                    <p className="d-flex">Price:<p className="ps-1">$0.388258</p></p>
                                                    <p className="d-flex">Age:<p className="ps-1">11.1 years</p></p>
                                                    {/* <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                    </svg>7.77%</span> */}
                                                </div>
                                            </div>
                                            <div className="prom_list_all">
                                                <div className="prom_list_data">
                                                    <div className="list_logo"><img src="https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/32/busd.webp" /></div><span className="ps-2">BUSD</span>
                                                </div>
                                                <div className="list_bottom red">
                                                    <p className="d-flex">Price:<p className="ps-1">$1.00</p></p>
                                                    <p className="d-flex">Age:<p className="ps-1">3.3 years</p></p>
                                                    {/* <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"  className="red"  viewBox="0 0 16 16">
                                                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                    </svg>7.77%</span> */}
                                                </div>
                                            </div>
                                            <div className="prom_list_all">
                                                <div className="prom_list_data">
                                                    <div className="list_logo"><img src="	https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/32/ada.webp" /></div><span className="ps-2">ADA</span>
                                                </div>
                                                <div className="list_bottom">
                                                    <p className="d-flex">Price:<p className="ps-1">$0.351590</p></p>
                                                    <p className="d-flex">Age:<p className="ps-1">5.3 years</p></p>
                                                    {/* <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                        <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                    </svg>7.77%</span> */}
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="prom_left prom_right">
                                    <h3>Promoted Exchanges</h3>
                                    <div className="prom_list_all prom_list_wide">
                                        <div className="prom_list_data">
                                            <div className="list_logo"><img src="https://lcw.nyc3.cdn.digitaloceanspaces.com/production/exchanges/changelly.png" /></div><span className="ps-2">Changelly PRO</span>
                                        </div>
                                        <div className="list_bottom_detail">
                                            <div className="list_bottom">
                                                <p> 24h Volume</p>
                                                <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>7.77%</span>
                                            </div>
                                            <div className="list_bottom">
                                                <p>Liquidity ±2%</p>
                                                <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>7.77%</span>
                                            </div>
                                            <div className="list_bottom">
                                                <p> vol share</p>
                                                <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>7.77%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="prom_list_all prom_list_wide">
                                        <div className="prom_list_data">
                                            <div className="list_logo"><img src="https://lcw.nyc3.cdn.digitaloceanspaces.com/production/exchanges/bingx.png" /></div><span className="ps-2">BingX</span>
                                        </div>
                                        <div className="list_bottom_detail">
                                            <div className="list_bottom">
                                                <p> 24h Volume</p>
                                                <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>7.77%</span>
                                            </div>
                                            <div className="list_bottom">
                                                <p>Liquidity ±2%</p>
                                                <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>7.77%</span>
                                            </div>
                                            <div className="list_bottom">
                                                <p> vol share</p>
                                                <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>7.77%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="prom_list_all prom_list_wide">
                                        <div className="prom_list_data">
                                            <div className="list_logo"><img src="https://lcw.nyc3.cdn.digitaloceanspaces.com/production/exchanges/coinbasepro.png" /></div><span className="ps-2">Coinbase Pro</span>
                                        </div>
                                        <div className="list_bottom_detail">
                                            <div className="list_bottom">
                                                <p> 24h Volume</p>
                                                <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>7.77%</span>
                                            </div>
                                            <div className="list_bottom">
                                                <p>Liquidity ±2%</p>
                                                <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>7.77%</span>
                                            </div>
                                            <div className="list_bottom">
                                                <p> vol share</p>
                                                <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>7.77%</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="prom_list_all prom_list_wide">
                                        <div className="prom_list_data">
                                            <div className="list_logo"><img src="https://lcw.nyc3.cdn.digitaloceanspaces.com/production/exchanges/mxc.png" /></div><span className="ps-2">MEXC Global</span>
                                        </div>
                                        <div className="list_bottom_detail">
                                            <div className="list_bottom">
                                                <p> 24h Volume</p>
                                                <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>7.77%</span>
                                            </div>
                                            <div className="list_bottom">
                                                <p>Liquidity ±2%</p>
                                                <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>7.77%</span>
                                            </div>
                                            <div className="list_bottom">
                                                <p> vol share</p>
                                                <span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-up-fill" viewBox="0 0 16 16">
                                                    <path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                                                </svg>7.77%</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="banner_header">
                    <div className="prom_project">
                        <div className="prom_head">
                            <h2 className="pd-top-60">Nft platform of the month</h2>
                        </div>
                    </div>
                    <div className="banner_marque_text">
                        <div className="mar_text"><strong>Weeb3</strong> — Nft platform of the month - <span>7.53</span> — Jan, 2023 —</div>
                        <div className="mar_text"><strong>Weeb3</strong> — Nft platform of the month - <span>7.53</span> — Jan, 2023 —</div>
                        <div className="mar_text"><strong>Weeb3</strong> — Nft platform of the month - <span>7.53</span> — Jan, 2023 —</div>
                        <div className="mar_text"><strong>Weeb3</strong> — Nft platform of the month - <span>7.53</span> — Jan, 2023 —</div>
                        <div className="mar_text"><strong>Weeb3</strong> — Nft platform of the month - <span>7.53</span> — Jan, 2023 —</div>
                        <div className="mar_text"><strong>Weeb3</strong> — Nft platform of the month - <span>7.53</span> — Jan, 2023 —</div>
                        <div className="mar_text"><strong>Weeb3</strong> — Nft platform of the month - <span>7.53</span> — Jan, 2023 —</div>
                        <div className="mar_text"><strong>Weeb3</strong> — Nft platform of the month - <span>7.53</span> — Jan, 2023 —</div>
                        <div className="mar_text"><strong>Weeb3</strong> — Nft platform of the month - <span>7.53</span> — Jan, 2023 —</div>
                        <div className="mar_text"><strong>Weeb3</strong> — Nft platform of the month - <span>7.53</span> — Jan, 2023 —</div>
                        <div className="mar_text"><strong>Weeb3</strong> — Nft platform of the month - <span>7.53</span> — Jan, 2023 —</div>
                        <div className="mar_text"><strong>Weeb3</strong> — Nft platform of the month - <span>7.53</span> — Jan, 2023 —</div>
                        <div className="mar_text"><strong>Weeb3</strong> — Nft platform of the month - <span>7.53</span> — Jan, 2023 —</div>
                        <div className="mar_text"><strong>Weeb3</strong> — Nft platform of the month - <span>7.53</span> — Jan, 2023 —</div>
                        <div className="mar_text"><strong>Weeb3</strong> — Nft platform of the month - <span>7.53</span> — Jan, 2023 —</div>
                        <div className="mar_text"><strong>Weeb3</strong> — Nft platform of the month - <span>7.53</span> — Jan, 2023 —</div>
                        <div className="mar_text"><strong>Weeb3</strong> — Nft platform of the month - <span>7.53</span> — Jan, 2023 —</div>
                        <div className="mar_text"><strong>Weeb3</strong> — Nft platform of the month - <span>7.53</span> — Jan, 2023 —</div>
                        <div className="mar_text"><strong>Weeb3</strong> — Nft platform of the month - <span>7.53</span> — Jan, 2023 —</div>
                        <div className="mar_text"><strong>Weeb3</strong> — Nft platform of the month - <span>7.53</span> — Jan, 2023 —</div>
                        <div className="mar_text"><strong>Weeb3</strong> — Nft platform of the month - <span>7.53</span> — Jan, 2023 —</div>
                        <div className="mar_text"><strong>Weeb3</strong> — Nft platform of the month - <span>7.53</span> — Jan, 2023 —</div>
                        <div className="mar_text"><strong>Weeb3</strong> — Nft platform of the month - <span>7.53</span> — Jan, 2023 —</div>
                        <div className="mar_text"><strong>Weeb3</strong> — Nft platform of the month 123 - <span>7.53</span> — Jan, 2023 —</div>
                    </div>
                </div>
                <div className="banner_inner">
                    <a href="https://foundation.app/world/weeb3-world" className="full-anchor" target="_blank">
                        <div className="banner_inner_text">
                            <div className="banner_inner_text1"><p>Overall Score</p></div>
                            <div className="banner_inner_text2"><h3>Weeb3</h3></div>
                        </div>
                    </a>
                </div>
                <div className="banner_lower_text">
                    <span>Curated by</span>
                    <a className="banner_tag" href="https://foundation.app/world/weeb3-world">
                        <div className="banner_lower_img"><a href="https://foundation.app/world/weeb3-world" target="_blank"><img src={coin3} /></a></div>
                        <div className="banner_lower_imgtext"><a href="https://foundation.app/world/weeb3-world" className="link_underline" target="_blank">@rwx</a></div></a>
                    <a href="https://foundation.app/world/weeb3-world" className="triimg mb-1"><svg width="98" height="21" viewBox="0 0 98 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_208_2)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M64.8935 16.456C64.8935 25.5444 57.5259 32.912 48.4375 32.912C39.3491 32.912 31.9815 25.5444 31.9815 16.456C31.9815 7.36761 39.3491 0 48.4375 0C57.5259 0 64.8935 7.36761 64.8935 16.456ZM16.9024 1.56741C17.204 1.045 17.958 1.045 18.2596 1.56741L35.0558 30.6593C35.3575 31.1817 34.9804 31.8347 34.3772 31.8347H0.784788C0.181557 31.8347 -0.195461 31.1817 0.106154 30.6593L16.9024 1.56741ZM68.6143 0.979362C67.7488 0.979362 67.0471 1.68104 67.0471 2.5466V30.3651C67.0471 31.2307 67.7488 31.9324 68.6143 31.9324H96.4328C97.2984 31.9324 98.0001 31.2307 98.0001 30.3651V2.5466C98.0001 1.68104 97.2984 0.979362 96.4328 0.979362H68.6143Z" fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_208_2">
                                <rect width="98" height="33" fill="white" />
                            </clipPath>
                        </defs>
                    </svg></a>
                </div>


            </div>
        </section>
    )
}
export default Banner;