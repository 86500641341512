import React, { useCallback, useEffect, useRef, useState } from "react";
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import gencoin from '../../assets/images/gen.png';
import utick from '../../assets/images/u_tick.svg';
import Search from "../Search";
import { SEARCH_BUTTONS } from "../../constants";
import logotech from '../../assets/images/tchy logo.png';
import Profile from '../../assets/images/profile-user.svg';
import flag from '../../assets/images/de.svg';
import flagin from '../../assets/images/in.svg';
import flagame from '../../assets/images/americ 1.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import Accordion from 'react-bootstrap/Accordion';
import oreocoin from '../../assets/images/oreo.png';

export const MobileHeader = () => {
    const Ref = useRef(null);
    const [showTabs, setShowTabs] = useState(false)
    const [searchActive, setSearchActive] = useState(false)
    const [navigationActive, setNavigationActive] = useState(false)
    const [buttonShow, setButton] = useState(false)

    const onSearchFocusIn = useCallback(() => {
        setSearchActive(!false)
        setShowTabs(!true)
    }, [showTabs])

    const onSearchFocusOut = useCallback(() => {
        Ref.current.blur();
        setShowTabs(false)
        hideSearch()
    }, [showTabs])

    const handleClickOutside = (event) => {
        if (
            Ref.current &&
            !Ref.current.contains(event.target)
        ) {
            onSearchFocusOut()
        }

    }

    const onEscapeClick = (event) => {
        if (Ref.current && Ref.current === event.target && event.key.toUpperCase() === "ESCAPE") {
            onSearchFocusOut();
        }
    }


    useEffect(() => {
        document
            .addEventListener('mousedown', handleClickOutside);

        return () => {
            document
                .removeEventListener('mousedown', handleClickOutside);
        }
    }, [])

    const showSearch = () => {
        setSearchActive(true)
    }

    const hideSearch = () => {
        setSearchActive(false)
    }
    const toggleNav = () => {
        setNavigationActive(!navigationActive);
      };

    return (
        <>
            <Navbar expand="lg" className="cus-nav sticky_header sticky_header2 mob">

                <Container className="">

                    <Navbar.Brand className="text-white header_icon">
                        <a href="/home" className=""><img src={logotech} width="30" /></a>
                        <svg className="search_icon" clip-rule="evenodd" fill-rule="evenodd" height="30" stroke-linejoin="round" onClick={() => showSearch()} stroke-miterlimit="2" viewBox="0 0 24 24" width="30" xmlns="http://www.w3.org/2000/svg"><g id="Icon"><g><path d="m10 3.5c-3.587 0-6.5 2.913-6.5 6.5s2.913 6.5 6.5 6.5 6.5-2.913 6.5-6.5-2.913-6.5-6.5-6.5zm0 1c3.036 0 5.5 2.464 5.5 5.5s-2.464 5.5-5.5 5.5-5.5-2.464-5.5-5.5 2.464-5.5 5.5-5.5z"></path><path d="m20.354 19.646-5.759-5.758c-.195-.195-.512-.195-.707 0s-.195.512 0 .707l5.758 5.759c.196.195.512.195.708 0 .195-.196.195-.512 0-.708z"></path></g></g></svg>
                        <div className={`search_inpt header_search_mob ${!searchActive ? 'hide_search' : ''}`}>
                            <div className="serach_container" ref={Ref} onKeyUp={(e) => onEscapeClick(e)} tabIndex="0">
                                <Search onSearchFocusIn={onSearchFocusIn} showTabs={showTabs} onSearchFocusOut={onSearchFocusOut} searchActive={!searchActive} buttonType={SEARCH_BUTTONS.CLOSE} />
                                && <div className="header_search_result" searchActive={searchActive}>
                            
                  <div className="">
                  <div className="saerch_col"><p className="top_filter">Most relevant</p>
                    <div class="header_list">
                      <div class="header_list_left">
                        <img src={gencoin} />
                        <div><div className="serch_tag"><h6>Damien Hirst - The Currency</h6><img src={utick} /></div>
                          <p>Mutant Ape Yacht Club</p></div>
                      </div><div class="header_list_right">
                        <div class="shw_del">
                          <div><h6>5.10 ETH</h6>
                          </div><p>Floor</p></div></div></div>
                    <div class="header_list">
                      <div class="header_list_left">
                        <img src={gencoin} />
                        <div><div className="serch_tag"><h6>#4732</h6><img src={utick} /></div>
                          <p>Mutant Ape Yacht Club</p></div>
                      </div><div class="header_list_right">
                        <div class="shw_del">
                          <div><h6>5.10 ETH</h6>
                          </div><p>$8,435.0</p></div></div></div>
                    <div class="header_list">
                      <div class="header_list_left">
                        <img src={gencoin} />
                        <div><div className="serch_tag"><h6>#4732</h6><img src={utick} /></div>
                          <p>Mutant Ape Yacht Club</p></div>
                      </div><div class="header_list_right">
                        <div class="shw_del">
                          <div><h6>5.10 ETH</h6>
                          </div><p>$8,435.0</p></div></div></div>
           
                    <div class="header_list">
                      <div class="header_list_left">
                        <img src={gencoin} />
                        <div><div className="serch_tag"><h6>#4732</h6><img src={utick} /></div>
                          <p>Mutant Ape Yacht Club</p></div>
                      </div><div class="header_list_right">
                        <div class="shw_del">
                          <div><h6>5.10 ETH</h6>
                          </div><p>$8,435.0</p></div></div></div></div>


                          <div className="saerch_col"><p className="top_filter">Tips & Tricks</p>
                    <div class="header_list">
                      <div class="header_list_left">
                        <img src={oreocoin} />
                        <div><div className="serch_tag"><h6>Damien Hirst - The Currency</h6><img src={utick} /></div>
                          <p>Mutant Ape Yacht Club</p></div>
                      </div><div class="header_list_right">
                        <div class="shw_del">
                          <div><h6>5.10 ETH</h6>
                          </div><p>Floor</p></div></div></div>
                    <div class="header_list">
                      <div class="header_list_left">
                        <img src={oreocoin} />
                        <div><div className="serch_tag"><h6>#4732</h6><img src={utick} /></div>
                          <p>Mutant Ape Yacht Club</p></div>
                      </div><div class="header_list_right">
                        <div class="shw_del">
                          <div><h6>5.10 ETH</h6>
                          </div><p>$8,435.0</p></div></div></div>
            
           </div>
           <div className="saerch_col"><p className="top_filter">Attractions</p>
                    <div class="header_list">
                      <div class="header_list_left">
                        <img src={oreocoin} />
                        <div><div className="serch_tag"><h6>Damien Hirst - The Currency</h6><img src={utick} /></div>
                          <p>Mutant Ape Yacht Club</p></div>
                      </div><div class="header_list_right">
                        <div class="shw_del">
                          <div><h6>5.10 ETH</h6>
                          </div><p>Floor</p></div></div></div>
                    <div class="header_list">
                      <div class="header_list_left">
                        <img src={oreocoin} />
                        <div><div className="serch_tag"><h6>#4732</h6><img src={utick} /></div>
                          <p>Mutant Ape Yacht Club</p></div>
                      </div><div class="header_list_right">
                        <div class="shw_del">
                          <div><h6>5.10 ETH</h6>
                          </div><p>$8,435.0</p></div></div></div>
            
           </div>
                  </div>
             
                                </div>
                            </div>
                        </div>

                    </Navbar.Brand>

                    <div className="d-flex align-items-center">
                        <div className="lang_swicth menu_mob_none">
                            <Dropdown>
                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                    <img src={flag} className="flag" />    DE
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1"><img src={flagin} className="flag" />    EN
                                    </Dropdown.Item>

                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="pro_img">

                            <img src={Profile} />
                            {/* <Button variant="outline-secondary btn-secondary btn-secondary-rounded" className="ms-4">Add Project</Button> */}
                            <div className="" aria-controls="navbarScroll" onClick={toggleNav}>
                            {navigationActive ? (
                                  <button className="btn tab-button-space close_cross" aria-controls="navbarScroll" >  <a href="javascript:void(0)" className="closebtn" ><svg id="Capa_1" enable-background="new 0 0 320.591 320.591" height="512" viewBox="0 0 320.591 320.591" width="512" xmlns="http://www.w3.org/2000/svg"><g><g id="close_1_"><path d="m30.391 318.583c-7.86.457-15.59-2.156-21.56-7.288-11.774-11.844-11.774-30.973 0-42.817l257.812-257.813c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875l-259.331 259.331c-5.893 5.058-13.499 7.666-21.256 7.288z"/><path d="m287.9 318.583c-7.966-.034-15.601-3.196-21.257-8.806l-257.813-257.814c-10.908-12.738-9.425-31.908 3.313-42.817 11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414-6.35 5.522-14.707 8.161-23.078 7.288z"/></g></g></svg></a></button>
                      
                              ) : (
                              <button className="btn tab-button-space"   aria-controls="navbarScroll" ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                              <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
                            </svg>
                            
                            </button>
                            
                            )}
      </div>
                        </div>
                    </div>
                </Container>

                {navigationActive && (

                <div id="mySidenav"className="sidenav home_nav">
                    <div className="menu_top_sidebar">
                        <button className="menu_button active">Personal</button>
                        <button className="menu_button">Business</button>
                    </div>
                    <div className="top_title">
                    <h6>Discover<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-arrow-right" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
</svg></h6>
                    </div>
                    <div className='acc1_detail cate_select_check '>
                                <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>    
Price: Low to High</Accordion.Header>
                                                <Accordion.Body>
                                                <div className='cat_all'>
                                <div className='blog_tick_cat'>
                                        <div><span>Price: Low to High</span></div>
                                   
                                    </div>
                                    <div className='blog_tick_cat'>
                                        <div><span>Price: High to Low</span></div>
                                 
                                    </div>
                                    <div className='blog_tick_cat'>
                                        <div><span>Rarity: Rare to Common</span></div>
                               
                                    </div>
                                    <div className='blog_tick_cat'>
                                        <div><span>Rarity: Common to Rare</span></div>
                                  
                                    </div>
                                </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            </Accordion>
                                           
                                        
                                </div>
                    <a href="/apartmentdetail">Living</a>
                    <a href="/museum">Attractions</a>
                    <a href="/blog">Tips & Tricks</a>
                    <a href="#">Cryto</a>
                    <a> <h6>Language</h6></a>
                    <div className="lang_swicth">
                       
                        <Dropdown>
                            <Dropdown.Toggle variant="" id="dropdown-basic">
                                <img src={flag} className="flag" />    DE
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1"><img src={flagame} className="flag" />    EN
                                </Dropdown.Item>

                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="sign_login">  <a href="#action1" data-rr-ui-event-key="#action1" class="reward_btn nav-link"><span class="ad_btn"></span><span class="ad_grade_colorold new_ad">Listing</span></a>
                        <a href="#action1" data-rr-ui-event-key="#action1" class="reward_btn nav-link "><span class="ad_btn"></span><span class="ad_grade_colorold new_ad active_btn">Login</span></a></div>
                </div>
                )}
            </Navbar>


        </>
    )
}
