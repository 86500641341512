import { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import HeaderNew2 from "../components/HeaderNew2";
import Footer from "../components/Footer";
import "../assets/css/privacy.scss";
import { Loader } from '../components/Loader';
import { LOADER_TIMER } from '../constants';
import FloatingSearch from '../components/FloatingSearch';

export const Dyor = () => {
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoader(false);
        }, LOADER_TIMER)
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [])
    return (
        <>
            <Loader show={loader} />
            <main className='privacy_page_main'>
                <HeaderNew2 />
                <div className='container'>
                    <div class="coin_section_coin_section_head"><h3 className='new_ltr'>DYOR</h3></div>
                    <div className='privacypage_detail pt-0'>
                        <div>
                            <h3>Do Your Own Research</h3>
                            <p>“Do your own research.” Or, simply, “DYOR”, is one of the most popular terms in the
cryptocurrency community, encouraging individuals to think rationally for themselves and
conduct due diligence before investing in a cryptocurrency. Ultimately, the DYOR reflects the
core ethos of crypto—don’t trust, first verify.</p>
<p>All information provided by Futur3 on the website <a href='https://futur3.app/'>https://futur3.app/</a> is solely for
educational purposes and should not be perceived as financial advice. The information on this
website is an invitation to the general public. It is not and cannot be regarded as investment advice
or a declaration of commitment to any specified or unspecified subject.</p>
<p>The Futur3 team suggests you seek financial advice from your advisor before making any
investment decisions in the field of high-risk and high-volatility activity. By purchasing any of our
products, you agree to the terms that you are not purchasing any security or investment and you
agree not to hold our team liable or responsible for any of the harm in terms of finances, losses, or
taxes that you incur. IT IS STRICTLY ADVISED TO DO YOUR OWN RESEARCH AND
RATIONALLY MAKE INVESTMENT DECISIONS. IN ANY UNFORTUNATE CASE(S), WE
WILL NOT BE HELD RESPONSIBLE.</p>
<p>You should have no expectations in any form from our team. Always comply with your territorial
regulations. The readers of this document should never put more resources into cryptocurrency
buying than they can stand to lose. Before investing in this or any other cryptocurrency project,
the reader should always seek professional investment advice.</p>
<h3>Contact Us</h3>
<p>If you have any queries please contact us through email: <a href='mailto:foundation@futur3.app'>foundation@futur3.app</a></p>
                        </div>
                        </div>
                </div>
                <Footer />
                <FloatingSearch />
            </main>
        </>
    )
}