import React from "react";
import insta from '../../assets/images/instagram.png';
import overlayimg1 from '../../assets/images/overlayimg1.jpg';
import youtb from '../../assets/images/youtube.png';
import tiktk from '../../assets/images/tik-tok.png';

function TabBlog2() {
    return (
        <>
                      <div className='row heep_sec_detail New_blog people_say'>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img className='' src={youtb} />
                                                                    
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Youtuber</div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Youtube</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Build a web app wide Lorem Ipsum has been the industry  web app wide Lorem</h3>                                                        

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img className='' src={tiktk} />
                                                                    
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Tiktok</div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Tiktok</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Build a web app wide Lorem Ipsum has been the industry  web app wide Lorem</h3>                                                        

                                                        </div>
                                                    </div>
                                                </div>
                                              
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img className='' src={insta} />
                                                                    
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Instagram</div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Instagram</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Build a web app wide Lorem Ipsum has been the industry  web app wide Lorem</h3>                                                        

                                                        </div>
                                                    </div>
                                                </div>




                                            </div>

        </>
    );
}

export default TabBlog2;