import React from "react";
import logotech from '../../assets/images/tchy logo.png';
import overlayimg1 from '../../assets/images/overlayimg1.jpg';
import oreocoin from '../../assets/images/oreo.png';
import pandcoin from '../../assets/images/pand.png';

function TabBlog1() {
    return (
        <>
             <div className='row heep_sec_detail New_blog'>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img className='logoimg' src={logotech} />
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Tchy Team </div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Category</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Build a web app wide Lorem Ipsum has been the industry  web app wide Lorem</h3>
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img src={oreocoin} />
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Category</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Modern website Lorem Ipsum has been the industry</h3>
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img src={pandcoin} />
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Category</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Set-up automations Lorem Ipsum has been the industry text of the printing</h3>
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                            </p>

                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img src={pandcoin} />
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Category</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Build a web app wide Lorem Ipsum has been the industry</h3>
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img src={oreocoin} />
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Category</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Modern website Build a web app wide Lorem Ipsum has been the industry</h3>
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                            </p>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-4 col-xl-4">
                                                    <div className="heep_detail2_right_inner">
                                                        <div className="heep_Del_left">
                                                            <div className='d-flex align-items-center'>
                                                                <div className="heep_detail2_logo">
                                                                    <img src={pandcoin} />
                                                                </div>

                                                                <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                            </div>
                                                            <div><span className="cat_tag">Category</span></div>
                                                        </div>
                                                        <div className="heep_detail2_text pt-3">
                                                            <div className="heep_img_new">
                                                                <img src={overlayimg1} /></div>
                                                            <h3 className="heep_top_padding">Set-up automations Build a web app wide Lorem Ipsum has been the industry</h3>
                                                            <p className="mt-1">
                                                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                            </p>

                                                        </div>
                                                    </div>

                                                </div>
                                         
                                          




                                            </div>


        </>
    );
}

export default TabBlog1;