import { useEffect, useState } from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import pandcoin from '../assets/images/pand.png';
import heep1 from '../assets/images/Bubble.svg';
import oreocoin from '../assets/images/oreo.png';
import gencoin from '../assets/images/gen.png';
import "../assets/css/faq.scss";
import { Loader } from '../components/Loader';
import { LOADER_TIMER } from '../constants';
import FloatingSearch from '../components/FloatingSearch';

export const Exchanges = () => {

    const [loader, setLoader] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoader(false);
        }, LOADER_TIMER)
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [])

    return (
        <>
            <Loader show={loader} />
     <main>
     <Header />

<div className='container'>
<section className="built_maker_heep">
                        <div className="row">
                            <div className="col-md-12">
                                <div className=" built_maker_heep_right mb-5">
                                    <h1>
                                       Exchanges
                                    </h1>
                                    <h6>Discover our Exchanges directory </h6>
                                    <div className="built_maker_right table-responsive">
                                    <table className='table exchange_table table-control'>
                                            <thead>
                                                <tr>
                                                    <th width="22%">LAUNCHPAD NAME</th>
                                                    <th width="13%">24H VOLUME<svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                                                        <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                                                        <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                                                    </svg></th>
                                                    <th width="13%">VOL SHARE<svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                                                        <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                                                        <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                                                    </svg></th>
                                                    <th width="13%">MARKETS<svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                                                        <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                                                        <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                                                    </svg></th>
                                                    <th width="13%">LIQUIDITY ±2%<svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                                                        <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                                                        <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                                                    </svg></th>
                                                    <th width="13%">Bids -2%<svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                                                        <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                                                        <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                                                    </svg></th>
                                                    <th width="13%">Asks +2%<svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                                                        <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                                                        <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                                                    </svg> </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr><td> <div className="built_maker_inner_leftdetail">
                                                    <img src={pandcoin} />
                                                    <div>
                                                        <a href="https://futur3.app/coinpagedetail">
                                                            <b>ActiveCampaign</b>
                                                        </a>
                                                    </div>
                                                </div></td>
                                                    <td><b>452.97 M</b></td>
                                                    <td><b>2.07%</b></td>
                                                    <td><b>168</b></td>
                                                    <td><b>352.97 M</b></td>
                                                    <td><b>$752.97 M</b></td>
                                                    <td><b>$952.97 M</b></td>                                                    
                                                </tr>
                                                <tr><td> <div className="built_maker_inner_leftdetail">
                                                    <img src={oreocoin} />
                                                    <div>
                                                        <a href="https://futur3.app/coinpagedetail">
                                                            <b>OreoSwap</b>
                                                        </a>
                                                    </div>
                                                </div></td>
                                                    <td><b>452.97 M</b></td>
                                                    <td><b>2.07%</b></td>
                                                    <td><b>168</b></td>
                                                    <td><b>352.97 M</b></td>
                                                    <td><b>$352.97 M</b></td>
                                                    <td><b>$652.97 M</b></td> 
                                                </tr>
                                                <tr><td> <div className="built_maker_inner_leftdetail">
                                                    <img src={pandcoin} />
                                                    <div>
                                                        <a href="https://futur3.app/coinpagedetail">
                                                            <b>PandaSale</b>
                                                        </a>
                                                    </div>
                                                </div></td>
                                                    <td><b>452.97 M</b></td>
                                                    <td><b>2.07%</b></td>
                                                    <td><b>168</b></td>
                                                    <td><b>352.97 M</b></td>
                                                    <td><b>$452.97 M</b></td>
                                                    <td><b>$452.97 M</b></td> 
                                                </tr>
                                                <tr><td> <div className="built_maker_inner_leftdetail">
                                                    <img src={oreocoin} />
                                                    <div>
                                                    <a href="https://futur3.app/coinpagedetail">
                                                        <b>OreoSwap</b>
                                                    </a>
                                                    </div>
                                                </div></td>
                                                    <td><b>452.97 M</b></td>
                                                    <td><b>2.07%</b></td>
                                                    <td><b>168</b></td>
                                                    <td><b>352.97 M</b></td>
                                                    <td><b>$452.97 M</b></td>
                                                    <td><b>$452.97 M</b></td> 
                                                </tr>
                                                <tr><td> <div className="built_maker_inner_leftdetail">
                                                    <img src={pandcoin} />
                                                    <div>
                                                    <a href="https://futur3.app/coinpagedetail">
                                                        <b>ActiveCampaign</b>
                                                    </a>
                                                    </div>
                                                </div></td>
                                                    <td><b>452.97 M</b></td>
                                                    <td><b>2.07%</b></td>
                                                    <td><b>168</b></td>
                                                    <td><b>352.97 M</b></td>
                                                    <td><b>$452.97 M</b></td>
                                                    <td><b>$452.97 M</b></td> 
                                                </tr>
                                                <tr><td> <div className="built_maker_inner_leftdetail">
                                                    <img src={heep1} />
                                                    <div>
                                                    <a href="https://futur3.app/coinpagedetail">
                                                        <b>PandaSale</b>
                                                    </a>
                                                    </div>
                                                </div></td>
                                                    <td><b>452.97 M</b></td>
                                                    <td><b>2.07%</b></td>
                                                    <td><b>168</b></td>
                                                    <td><b>352.97 M</b></td>
                                                    <td><b>$352.97 M</b></td>
                                                    <td><b>$652.97 M</b></td> 
                                                </tr>
                                                <tr><td> <div className="built_maker_inner_leftdetail">
                                                    <img src={pandcoin} />
                                                    <div>
                                                    <a href="https://futur3.app/coinpagedetail">
                                                        <b>ActiveCampaign</b>
                                                    </a>
                                                    </div>
                                                </div></td>
                                                    <td><b>452.97 M</b></td>
                                                    <td><b>2.07%</b></td>
                                                    <td><b>168</b></td>
                                                    <td><b>352.97 M</b></td>
                                                    <td><b>$352.97 M</b></td>
                                                    <td><b>$852.97 M</b></td> 
                                                </tr>
                                                <tr><td> <div className="built_maker_inner_leftdetail">
                                                    <img src={oreocoin} />
                                                    <div>
                                                    <a href="https://futur3.app/coinpagedetail">
                                                        <b>OreoSwap</b>
                                                    </a>
                                                    </div>
                                                </div></td>
                                                    <td><b>452.97 M</b></td>
                                                    <td><b>2.07%</b></td>
                                                    <td><b>168</b></td>
                                                    <td><b>352.97 M</b></td>
                                                    <td><b>$152.97 M</b></td>
                                                    <td><b>$852.97 M</b></td> 
                                                </tr>
                                                <tr><td> <div className="built_maker_inner_leftdetail">
                                                    <img src={pandcoin} />
                                                    <div>
                                                    <a href="https://futur3.app/coinpagedetail">
                                                        <b>PandaSale</b>
                                                    </a>
                                                    </div>
                                                </div></td>
                                                    <td><b>452.97 M</b></td>
                                                    <td><b>2.07%</b></td>
                                                    <td><b>168</b></td>
                                                    <td><b>352.97 M</b></td>
                                                    <td><b>$652.97 M</b></td>
                                                    <td><b>$352.97 M</b></td> 
                                                </tr>
                                                <tr><td> <div className="built_maker_inner_leftdetail">
                                                    <img src={oreocoin} />
                                                    <div>
                                                    <a href="https://futur3.app/coinpagedetail">
                                                        <b>OreoSwap</b>
                                                    </a>
                                                    </div>
                                                </div></td>
                                                    <td><b>452.97 M</b></td>
                                                    <td><b>2.07%</b></td>
                                                    <td><b>168</b></td>
                                                    <td><b>352.97 M</b></td>
                                                    <td><b>$452.97 M</b></td>
                                                    <td><b>$752.97 M</b></td> 
                                                </tr>  <tr><td> <div className="built_maker_inner_leftdetail">
                                                    <img src={oreocoin} />
                                                    <div>
                                                    <a href="https://futur3.app/coinpagedetail">
                                                        <b>OreoSwap</b>
                                                    </a>
                                                    </div>
                                                </div></td>
                                                    <td><b>452.97 M</b></td>
                                                    <td><b>2.07%</b></td>
                                                    <td><b>168</b></td>
                                                    <td><b>352.97 M</b></td>
                                                    <td><b>$152.97 M</b></td>
                                                    <td><b>$852.97 M</b></td> 
                                                </tr>
                                                <tr><td> <div className="built_maker_inner_leftdetail">
                                                    <img src={pandcoin} />
                                                    <div>
                                                    <a href="https://futur3.app/coinpagedetail">
                                                        <b>PandaSale</b>
                                                    </a>
                                                    </div>
                                                </div></td>
                                                    <td><b>452.97 M</b></td>
                                                    <td><b>2.07%</b></td>
                                                    <td><b>168</b></td>
                                                    <td><b>352.97 M</b></td>
                                                    <td><b>$652.97 M</b></td>
                                                    <td><b>$352.97 M</b></td> 
                                                </tr>
                                                <tr><td> <div className="built_maker_inner_leftdetail">
                                                    <img src={oreocoin} />
                                                    <div>
                                                    <a href="https://futur3.app/coinpagedetail">
                                                        <b>OreoSwap</b>
                                                    </a>
                                                    </div>
                                                </div></td>
                                                    <td><b>452.97 M</b></td>
                                                    <td><b>2.07%</b></td>
                                                    <td><b>168</b></td>
                                                    <td><b>352.97 M</b></td>
                                                    <td><b>$452.97 M</b></td>
                                                    <td><b>$752.97 M</b></td> 
                                                </tr>
                                                <tr><td> <div className="built_maker_inner_leftdetail">
                                                    <img src={pandcoin} />
                                                    <div>
                                                    <a href="https://futur3.app/coinpagedetail">
                                                        <b>ActiveCampaign</b>
                                                    </a>
                                                    </div>
                                                </div></td>
                                                    <td><b>452.97 M</b></td>
                                                    <td><b>2.07%</b></td>
                                                    <td><b>168</b></td>
                                                    <td><b>352.97 M</b></td>
                                                    <td><b>$452.97 M</b></td>
                                                    <td><b>$452.97 M</b></td> 
                                                </tr>
                                                <tr><td> <div className="built_maker_inner_leftdetail">
                                                    <img src={heep1} />
                                                    <div>
                                                    <a href="https://futur3.app/coinpagedetail">
                                                        <b>PandaSale</b>
                                                    </a>
                                                    </div>
                                                </div></td>
                                                    <td><b>452.97 M</b></td>
                                                    <td><b>2.07%</b></td>
                                                    <td><b>168</b></td>
                                                    <td><b>352.97 M</b></td>
                                                    <td><b>$352.97 M</b></td>
                                                    <td><b>$652.97 M</b></td> 
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                    
                             
                                    <div class="more_proj dis_btn pad-20px">
                                        {/* <a href=""><button class="btn btn-outline-secondary btn-secondary blog-but">Show More</button></a> */}
                                        <div class="f-right">
                                                <ul class="pagination just-center">
                                                    <li class="page-item">
                                                        <a class="page-link" href="#" aria-label="Previous">
                                                            <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                                            </svg></span>
                                                            <span class="sr-only"></span>
                                                        </a>
                                                    </li>
                                                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                    <li class="page-item">
                                                        <a class="page-link" href="#" aria-label="Next">
                                                            <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                                            </svg></span>
                                                            <span class="sr-only"></span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                    </div>
                                
                                </div>
                             
                            </div>

                          

                          

                        </div>
                    </section>
</div>
     <Footer />
     <FloatingSearch />
        </main>
        </>
    )
}