import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../assets/css/tabsCommon.scss';

const Common2 = () => {
  return (
    <Tabs
      defaultActiveKey="Overview"
      id="justify-tab-example"
      className="mb-3 tabs-bg-light tab-common-2"
      justify
    >
      <Tab eventKey="Overview" title="Overview">
     
      </Tab>
      <Tab eventKey="Markets" title="Markets">

    </Tab>
      <Tab eventKey="Historical Data" title="Historical Data">

      </Tab>
      <Tab eventKey="News" title="News">
      </Tab>
    </Tabs>
  );
}

export default Common2;