import React from "react";
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';


export const Banner = () => {
    return (
        <Navbar expand="lg" className="cus-nav">
            <Container>
            <div className="h_top h_top_desk">       <div className="topheader">
                <div>    <p>Cryptos : <span href="#" className="cmc_link">22,347</span></p></div>
                    <div><p>Exchanges : <span href="#" className="cmc_link">347</span></p></div>
                   <div> <p>Market Cap : <span href="#" className="cmc_link">$1,059,308,564,682</span></p></div>
                    <div><p>24h Vol : <span href="#" className="cmc_link">$56,511,717,790</span></p></div>
                  <div>  <p>Dominance : <span href="#" className="cmc_link"><span>BTC: <span>42.1%</span></span><span className="ps-2">ETH: <span>18.1%</span></span></span></p></div>
                   <div> <p className="eth_gas"><svg id="Layer" height="13" viewBox="0 0 24 24" width="13" xmlns="http://www.w3.org/2000/svg"><path id="ethereum-circle" d="m12 2a10 10 0 1 0 10 10 10 10 0 0 0 -10-10zm2.936 12.2-2.486 3.57a.552.552 0 0 1 -.9 0l-2.486-3.57a.2.2 0 0 1 .228-.3l2.468.82a.74.74 0 0 0 .48 0l2.469-.82a.2.2 0 0 1 .227.3zm.354-2.09-3.29 1.11-3.289-1.106a.3.3 0 0 1 -.168-.429l2.977-5.4a.554.554 0 0 1 .96 0l2.977 5.405a.3.3 0 0 1 -.167.424z" fill="rgb(0,0,0)" /></svg>ETH Gas : <span href="#" className="cmc_link">15Gwei</span></p></div>

                   
                </div>
                
         </div>
         <div className="h_top h_top_mob">       <marquee className="topheader">
                <div>    <p>Cryptos : <span href="#" className="cmc_link">22,347</span></p></div>
                    <div><p>Exchanges : <span href="#" className="cmc_link">347</span></p></div>
                   <div> <p>Market Cap : <span href="#" className="cmc_link">$1,059,308,564,682</span></p></div>
                    <div><p>24h Vol : <span href="#" className="cmc_link">$56,511,717,790</span></p></div>
                  <div>  <p>Dominance : <span href="#" className="cmc_link"><span>BTC: <span>42.1%</span></span><span className="ps-2">ETH: <span>18.1%</span></span></span></p></div>
                   <div> <p className="eth_gas"><svg id="Layer" height="13" viewBox="0 0 24 24" width="13" xmlns="http://www.w3.org/2000/svg"><path id="ethereum-circle" d="m12 2a10 10 0 1 0 10 10 10 10 0 0 0 -10-10zm2.936 12.2-2.486 3.57a.552.552 0 0 1 -.9 0l-2.486-3.57a.2.2 0 0 1 .228-.3l2.468.82a.74.74 0 0 0 .48 0l2.469-.82a.2.2 0 0 1 .227.3zm.354-2.09-3.29 1.11-3.289-1.106a.3.3 0 0 1 -.168-.429l2.977-5.4a.554.554 0 0 1 .96 0l2.977 5.405a.3.3 0 0 1 -.167.424z" fill="rgb(0,0,0)" /></svg>ETH Gas : <span href="#" className="cmc_link">15Gwei</span></p></div>

                   
                </marquee>
                
         </div>
            </Container>
        </Navbar>)
}