import React, { useCallback, useEffect, useState, useRef } from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import gencoin from '../assets/images/gen.png';
import Tabs from "./Tabs";
import Search from "./Search";
import logoicon from '../assets/images/icon.png';
import { Banner } from "./HeaderComponents/Bannner";
import { MobileHeader } from "./HeaderComponents/MobileHeader";
import { SEARCH_BUTTONS } from "../constants";


function FloatingSearch() {
  const Ref = useRef(null);
  const [showTabs, setShowTabs] = useState(false)
  const [showTabsMob, setShowTabsMob] = useState(false)


  const onSearchFocusIn = useCallback(() => {
    setShowTabs(true)
  }, [showTabs])

  const onSearchFocusOut = useCallback(() => {
    Ref.current.blur();
    setShowTabs(false)
  }, [showTabs])

  const handleClickOutside = (event) => {
    if (
      Ref.current &&
      !Ref.current.contains(event.target)
    ) {
      onSearchFocusOut()
    }

  }


  const onEscapeClick = (event) => {
    console.log(event.key, Ref.current, event, Ref.current === event.target);
    if (Ref.current && Ref.current === event.target && event.key.toUpperCase() === "ESCAPE") {
      onSearchFocusOut();
    }
  }

  const showMobTab = (value) => {
    setShowTabsMob(value)
}

  useEffect(() => {
    document
      .addEventListener('mousedown', handleClickOutside);

    return () => {
      document
        .removeEventListener('mousedown', handleClickOutside);
    }
  }, [])

  return (
    <>

{/* className={`search_inpt header_search_mob ${!searchActive ? 'hide_search' : ''}`} */}


     <div className={`search_inpt floating_search ${showTabsMob ? 'mob_active' : ''}`} >

<div tabIndex="0" className="serach_container floating_search_list">

    <div className='search_res_detail'>
        <div className='row make-area radius-t-l-r-0'>
            <div className='col-12'>
                <div className="res_listing">
                    <div className='res_head'>Cryptocurrencies</div>
                    <ul>
                        <li className="prom_project"><div class="prom_list_data"><div class="list_logo"><img src="https://s2.coinmarketcap.com/static/img/coins/64x64/1.png" /></div><span class="ps-2">BTC</span></div></li>
                        <li className="prom_project"><div class="prom_list_data"><div class="list_logo"><img src="https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/32/eth.webp" /></div><span class="ps-2">ETH</span></div></li>
                        <li className="prom_project"><div class="prom_list_data"><div class="list_logo"><img src="https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/32/usdt.webp" /></div><span class="ps-2">USDT</span></div></li>
                        <li className="prom_project"><div class="prom_list_data"><div class="list_logo"><img src="https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/32/bnb.webp" /></div><span class="ps-2">BNB</span></div></li>
                    </ul>
                </div>
            </div>
            <div className="col-12">
                <div className="res_listing">
                    <div className='res_head'>Launchpads</div>

                    <ul>
                        <li className="prom_project"><div class="prom_list_data"><div class="list_logo"><img src="	https://www.pinksale.finance/static/media/pinkswap.a95de4f3.png" /></div><span class="ps-2">Pink Sale</span></div></li>
                        <li className="prom_project"><div class="prom_list_data"><div class="list_logo"><img src="	https://www.pinksale.finance/static/media/pinkswap.a95de4f3.png" /></div><span class="ps-2">Pink Sale</span></div></li>
                        <li className="prom_project"><div class="prom_list_data"><div class="list_logo"><img src="	https://www.pinksale.finance/static/media/pinkswap.a95de4f3.png" /></div><span class="ps-2">Pink Sale</span></div></li>
                        <li className="prom_project"><div class="prom_list_data"><div class="list_logo"><img src="	https://www.pinksale.finance/static/media/pinkswap.a95de4f3.png" /></div><span class="ps-2">Pink Sale</span></div></li>
                    </ul>
                </div>
            </div>
            <div className="col-12">

                <div className="res_listing">
                    <div className='res_head'>Exchanges</div>
                    <ul>
                        <li className="prom_project"><div class="prom_list_data"><div class="list_logo"><img src="https://lcw.nyc3.cdn.digitaloceanspaces.com/production/exchanges/changelly.png" /></div><span class="ps-2">Changelly PRO</span></div></li>
                        <li className="prom_project"><div class="prom_list_data"><div class="list_logo"><img src="https://lcw.nyc3.cdn.digitaloceanspaces.com/production/exchanges/bingx.png" /></div><span class="ps-2">BingX</span></div></li>
                        <li className="prom_project"><div class="prom_list_data"><div class="list_logo"><img src="https://lcw.nyc3.cdn.digitaloceanspaces.com/production/exchanges/coinbasepro.png" /></div><span class="ps-2">Coinbase Pro</span></div></li>
                        <li className="prom_project"><div class="prom_list_data"><div class="list_logo"><img src="https://lcw.nyc3.cdn.digitaloceanspaces.com/production/exchanges/mxc.png" /></div><span class="ps-2">MEXC Global</span></div></li>
                    </ul>
                </div>
            </div>
            <div className="col-12">

                <div className="res_listing">
                    <div className='res_head'>Directory</div>
                    <ul>
                        <li><a href='#'>Cryptocurrencies</a></li>
                        <li><a href='#'>Launchpads</a></li>
                        <li><a href='#'>Exchanges</a></li>
                        <li><a href='#'>Blog</a></li>
                    </ul>
                </div>
            </div>
        </div>

    </div>


</div>
<div className="searchlist_bottom" >
    <button className="btn menu-lines" onClick={() => showMobTab(true)}><span>Show tabs</span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
</svg></button><span className="hide-default" onClick={() => showMobTab(false)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg></span>
</div>
</div>

    </>

  );
}

export default FloatingSearch;