import React from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import appay from '../.././assets/images/apple-pay.svg';
import gpay from '../../assets/images/google-pay.svg';
import Ccard from '../../assets/images/tfbank_mc_gold.png';
import infocoin from '../../assets/images/infocoin.svg';
import checktick from '../../assets/images/checktick.svg';

function TabcommData1c() {
    return (
        <>
            <div className='table-responsive '>
                <table className="table table-control coin_tab_table">
                    <thead>
                        <tr>
                            <th colspan="6" width="25%">Credit Card Name</th>
                            <th scope="col" width="21%">Features </th>
                            <th scope="col" width="15%">Highlights <svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                                <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                                <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                            </svg></th>
                            <th scope="col" width="15%">Payment <svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                                <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                                <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                            </svg></th>


                            <th scope="col" width="10%">Gebühr p.a <svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                                <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                                <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                            </svg></th>
                            <th scope="col" width="8%" className="text-center">Company</th>
                        </tr>
                    </thead>
                    <tbody>

                        <tr className="big_td">
                            <td colspan="6">
                                <div className="listing_coin_detail new_detail">
                                    <div className="list_detail">
                                        <div class="info">
                                            <div><a href="#"><strong></strong></a><strong>TF Mastercard Gold</strong>
                                                <div class="hidden-sm">Archive</div></div>
                                        </div>
                                    </div>
                                    <div className="coin_logo new_card"><img src={Ccard} /></div>
                                </div>
                            </td>
                            <td><div className=''><div className="coinimg_new">
                                <ul className="td_coin_data">
                                    <li><img src={checktick} /><span>Kostenlos weltweit</span><img src={infocoin} className="c_white" />
                                    </li>
                                    <li><img src={checktick} /><span>jhdZins: 24,79%</span><img src={infocoin} className="c_white" />
                                    </li>
                                    <li><img src={checktick} /><span>Kein Video-/Post-Ident nötig</span><img src={infocoin} className="c_white" />

                                    </li>
                                    <li><img src={infocoin} className="c_white" /><span>Ausgleich per Überweisung</span><img src={infocoin} className="c_white" />
                                    </li>
                                    <div className="tran_pay">
                                        <div className="pay_tag"><img src={appay} className="" /></div>
                                        <div className="pay_tag"><img src={gpay} className="" /></div>
                                    </div>
                                </ul>
                            </div></div></td>
                            <td><div className="card_c mb-1"><label>#18,495</label></div>
                                <div className="card_c mb-1"><label>#18,495</label></div>
                                <div className="card_c mb-1"><label>#18,495</label></div></td>
                            <td>
                                <div className="indent_info">
                                    <div><span>Bezahlung</span><img src={infocoin} className="c_white" /></div>
                                    <div><p>
                                        Kostenlos weltweit</p></div>
                                </div>
                                <div className="indent_info mt-4">
                                    <div><span>Abhebung</span><img src={infocoin} className="c_white" /></div>
                                    <div><p>
                                        Kostenpflichtig</p></div>
                                </div>
                            </td>
                            <td className="bg_green">0 €</td>
                            <td className="text-center vote_drpdwn_btn">
                                <button className="new-bg"><span>Zum Antrag</span></button>
                                <div className="new_gray_btn">    <button className="new-bg"><span>Save</span></button></div>
                            </td>
                        </tr>
                        <tr className="big_td">
                            <td colspan="6">
                                <div className="listing_coin_detail new_detail">
                                    <div className="list_detail">
                                        <div class="info">
                                            <div><a href="#"><strong></strong></a><strong>TF Mastercard Gold</strong>
                                                <div class="hidden-sm">Archive</div></div>
                                        </div>
                                    </div>
                                    <div className="coin_logo new_card"><img src={Ccard} /></div>
                                </div>
                            </td>
                            <td><div className=''><div className="coinimg_new">
                                <ul className="td_coin_data">
                                    <li><img src={checktick} /><span>Kostenlos weltweit</span><img src={infocoin} className="c_white" />
                                    </li>
                                    <li><img src={checktick} /><span>jhdZins: 24,79%</span><img src={infocoin} className="c_white" />
                                    </li>
                                    <li><img src={checktick} /><span>Kein Video-/Post-Ident nötig</span><img src={infocoin} className="c_white" />

                                    </li>
                                    <li><img src={infocoin} className="c_white" /><span>Ausgleich per Überweisung</span><img src={infocoin} className="c_white" />
                                    </li>
                                    <div className="tran_pay">
                                        <div className="pay_tag"><img src={appay} className="" /></div>
                                        <div className="pay_tag"><img src={gpay} className="" /></div>
                                    </div>
                                </ul>
                            </div></div></td>
                            <td><div className="card_c mb-1"><label>#18,495</label></div>
                                <div className="card_c mb-1"><label>#18,495</label></div>
                                <div className="card_c mb-1"><label>#18,495</label></div></td>
                            <td>
                                <div className="indent_info">
                                    <div><span>Bezahlung</span><img src={infocoin} className="c_white" /></div>
                                    <div><p>
                                        Kostenlos weltweit</p></div>
                                </div>
                                <div className="indent_info mt-4">
                                    <div><span>Abhebung</span><img src={infocoin} className="c_white" /></div>
                                    <div><p>
                                        Kostenpflichtig</p></div>
                                </div>
                            </td>
                            <td className="bg_green">0 €</td>
                            <td className="text-center vote_drpdwn_btn">
                                <button className="new-bg"><span>Zum Antrag</span></button>
                                <div className="new_gray_btn">    <button className="new-bg"><span>Save</span></button></div>
                            </td>
                        </tr>
                        <tr className="big_td">
                            <td colspan="6">
                                <div className="listing_coin_detail new_detail">
                                    <div className="list_detail">
                                        <div class="info">
                                            <div><a href="#"><strong></strong></a><strong>TF Mastercard Gold</strong>
                                                <div class="hidden-sm">Archive</div></div>
                                        </div>
                                    </div>
                                    <div className="coin_logo new_card"><img src={Ccard} /></div>
                                </div>
                            </td>
                            <td><div className=''><div className="coinimg_new">
                                <ul className="td_coin_data">
                                    <li><img src={checktick} /><span>Kostenlos weltweit</span><img src={infocoin} className="c_white" />
                                    </li>
                                    <li><img src={checktick} /><span>jhdZins: 24,79%</span><img src={infocoin} className="c_white" />
                                    </li>
                                    <li><img src={checktick} /><span>Kein Video-/Post-Ident nötig</span><img src={infocoin} className="c_white" />

                                    </li>
                                    <li><img src={infocoin} className="c_white" /><span>Ausgleich per Überweisung</span><img src={infocoin} className="c_white" />
                                    </li>
                                    <div className="tran_pay">
                                        <div className="pay_tag"><img src={appay} className="" /></div>
                                        <div className="pay_tag"><img src={gpay} className="" /></div>
                                    </div>
                                </ul>
                            </div></div></td>
                            <td><div className="card_c mb-1"><label>#18,495</label></div>
                                <div className="card_c mb-1"><label>#18,495</label></div>
                                <div className="card_c mb-1"><label>#18,495</label></div></td>
                            <td>
                                <div className="indent_info">
                                    <div><span>Bezahlung</span><img src={infocoin} className="c_white" /></div>
                                    <div><p>
                                        Kostenlos weltweit</p></div>
                                </div>
                                <div className="indent_info mt-4">
                                    <div><span>Abhebung</span><img src={infocoin} className="c_white" /></div>
                                    <div><p>
                                        Kostenpflichtig</p></div>
                                </div>
                            </td>
                            <td className="bg_green">0 €</td>
                            <td className="text-center vote_drpdwn_btn">
                                <button className="new-bg"><span>Zum Antrag</span></button>
                                <div className="new_gray_btn">    <button className="new-bg"><span>Save</span></button></div>
                            </td>
                        </tr>
                        <tr className="big_td">
                            <td colspan="6">
                                <div className="listing_coin_detail new_detail">
                                    <div className="list_detail">
                                        <div class="info">
                                            <div><a href="#"><strong></strong></a><strong>TF Mastercard Gold</strong>
                                                <div class="hidden-sm">Archive</div></div>
                                        </div>
                                    </div>
                                    <div className="coin_logo new_card"><img src={Ccard} /></div>
                                </div>
                            </td>
                            <td><div className=''><div className="coinimg_new">
                                <ul className="td_coin_data">
                                    <li><img src={checktick} /><span>Kostenlos weltweit</span><img src={infocoin} className="c_white" />
                                    </li>
                                    <li><img src={checktick} /><span>jhdZins: 24,79%</span><img src={infocoin} className="c_white" />
                                    </li>
                                    <li><img src={checktick} /><span>Kein Video-/Post-Ident nötig</span><img src={infocoin} className="c_white" />

                                    </li>
                                    <li><img src={infocoin} className="c_white" /><span>Ausgleich per Überweisung</span><img src={infocoin} className="c_white" />
                                    </li>
                                    <div className="tran_pay">
                                        <div className="pay_tag"><img src={appay} className="" /></div>
                                        <div className="pay_tag"><img src={gpay} className="" /></div>
                                    </div>
                                </ul>
                            </div></div></td>
                            <td><div className="card_c mb-1"><label>#18,495</label></div>
                                <div className="card_c mb-1"><label>#18,495</label></div>
                                <div className="card_c mb-1"><label>#18,495</label></div></td>
                            <td>
                                <div className="indent_info">
                                    <div><span>Bezahlung</span><img src={infocoin} className="c_white" /></div>
                                    <div><p>
                                        Kostenlos weltweit</p></div>
                                </div>
                                <div className="indent_info mt-4">
                                    <div><span>Abhebung</span><img src={infocoin} className="c_white" /></div>
                                    <div><p>
                                        Kostenpflichtig</p></div>
                                </div>
                            </td>
                            <td className="bg_green">0 €</td>
                            <td className="text-center vote_drpdwn_btn">
                                <button className="new-bg"><span>Zum Antrag</span></button>
                                <div className="new_gray_btn">    <button className="new-bg"><span>Save</span></button></div>
                            </td>
                        </tr>
                        <tr className="big_td">
                            <td colspan="6">
                                <div className="listing_coin_detail new_detail">
                                    <div className="list_detail">
                                        <div class="info">
                                            <div><a href="#"><strong></strong></a><strong>TF Mastercard Gold</strong>
                                                <div class="hidden-sm">Archive</div></div>
                                        </div>
                                    </div>
                                    <div className="coin_logo new_card"><img src={Ccard} /></div>
                                </div>
                            </td>
                            <td><div className=''><div className="coinimg_new">
                                <ul className="td_coin_data">
                                    <li><img src={checktick} /><span>Kostenlos weltweit</span><img src={infocoin} className="c_white" />
                                    </li>
                                    <li><img src={checktick} /><span>jhdZins: 24,79%</span><img src={infocoin} className="c_white" />
                                    </li>
                                    <li><img src={checktick} /><span>Kein Video-/Post-Ident nötig</span><img src={infocoin} className="c_white" />

                                    </li>
                                    <li><img src={infocoin} className="c_white" /><span>Ausgleich per Überweisung</span><img src={infocoin} className="c_white" />
                                    </li>
                                    <div className="tran_pay">
                                        <div className="pay_tag"><img src={appay} className="" /></div>
                                        <div className="pay_tag"><img src={gpay} className="" /></div>
                                    </div>
                                </ul>
                            </div></div></td>
                            <td><div className="card_c mb-1"><label>#18,495</label></div>
                                <div className="card_c mb-1"><label>#18,495</label></div>
                                <div className="card_c mb-1"><label>#18,495</label></div></td>
                            <td>
                                <div className="indent_info">
                                    <div><span>Bezahlung</span><img src={infocoin} className="c_white" /></div>
                                    <div><p>
                                        Kostenlos weltweit</p></div>
                                </div>
                                <div className="indent_info mt-4">
                                    <div><span>Abhebung</span><img src={infocoin} className="c_white" /></div>
                                    <div><p>
                                        Kostenpflichtig</p></div>
                                </div>
                            </td>
                            <td className="bg_green">0 €</td>
                            <td className="text-center vote_drpdwn_btn">
                                <button className="new-bg"><span>Zum Antrag</span></button>
                                <div className="new_gray_btn">    <button className="new-bg"><span>Save</span></button></div>
                            </td>
                        </tr>

                    </tbody>
                </table>
                <div className="coin_mob_table">
                    <div className="mob_card_detail">
                        <div className="card_pc">          <div className="listing_coin_detail new_detail">
                            <div className="list_detail">
                                <div class="info">
                                    <div><a href="#"><strong></strong></a><strong>TF Mastercard Gold</strong>
                                        <div class="hidden-sm">Archive</div></div>
                                </div>
                            </div>
                            <div className="coin_logo new_card"><img src={Ccard} /></div>
                        </div>
                            <div className=""><p className="bg_green text-end">0 €</p><p className="f-12">Gebühr p.a </p></div></div>
                        <div className="indent_mob">  <div className="indent_info">
                            <div><span>Bezahlung</span><img src={infocoin} className="c_white" /></div>
                            <div><p>
                                Kostenlos weltweit</p></div>
                        </div>
                            <div className="indent_info">
                                <div><span>Abhebung</span><img src={infocoin} className="c_white" /></div>
                                <div><p>
                                    Kostenpflichtig</p></div>
                            </div></div>
                        <div className="card_m_del">
                            <div className=''><div className="coinimg_new">
                                <ul className="td_coin_data">
                                    <li><img src={checktick} /><span>Kostenlos weltweit</span><img src={infocoin} className="c_white" />
                                    </li>
                                    <li><img src={checktick} /><span>jhdZins: 24,79%</span><img src={infocoin} className="c_white" />
                                    </li>
                                    <li><img src={checktick} /><span>Kein Video-/Post-Ident nötig</span><img src={infocoin} className="c_white" />

                                    </li>
                                    <li><img src={infocoin} className="c_white" /><span>Ausgleich per Überweisung</span><img src={infocoin} className="c_white" />
                                    </li>
                                    <div className="tran_pay">
                                        <div className="pay_tag"><img src={appay} className="" /></div>
                                        <div className="pay_tag"><img src={gpay} className="" /></div>
                                    </div>
                                </ul>
                            </div></div>
                            <div><div className="card_c mb-1"><label>#18,495</label></div>
                                <div className="card_c mb-1"><label>#18,495</label></div>
                                <div className="card_c mb-1"><label>#18,495</label></div></div>
                        </div>


                        <div className="save_mob_btn">
                            <div className="vote_drpdwn_btn">           <button className="new-bg"><span>Zum Antrag</span></button>
                                <div className="new_gray_btn">    <button className="new-bg"><span>Save</span></button></div></div>
                        </div>
                    </div>
                    <div className="mob_card_detail">
                        <div className="card_pc">          <div className="listing_coin_detail new_detail">
                            <div className="list_detail">
                                <div class="info">
                                    <div><a href="#"><strong></strong></a><strong>TF Mastercard Gold</strong>
                                        <div class="hidden-sm">Archive</div></div>
                                </div>
                            </div>
                            <div className="coin_logo new_card"><img src={Ccard} /></div>
                        </div>
                            <div className=""><p className="bg_green text-end">0 €</p><p className="f-12">Gebühr p.a </p></div></div>
                        <div className="indent_mob">  <div className="indent_info">
                            <div><span>Bezahlung</span><img src={infocoin} className="c_white" /></div>
                            <div><p>
                                Kostenlos weltweit</p></div>
                        </div>
                            <div className="indent_info">
                                <div><span>Abhebung</span><img src={infocoin} className="c_white" /></div>
                                <div><p>
                                    Kostenpflichtig</p></div>
                            </div></div>
                        <div className="card_m_del">
                            <div className=''><div className="coinimg_new">
                                <ul className="td_coin_data">
                                    <li><img src={checktick} /><span>Kostenlos weltweit</span><img src={infocoin} className="c_white" />
                                    </li>
                                    <li><img src={checktick} /><span>jhdZins: 24,79%</span><img src={infocoin} className="c_white" />
                                    </li>
                                    <li><img src={checktick} /><span>Kein Video-/Post-Ident nötig</span><img src={infocoin} className="c_white" />

                                    </li>
                                    <li><img src={infocoin} className="c_white" /><span>Ausgleich per Überweisung</span><img src={infocoin} className="c_white" />
                                    </li>
                                    <div className="tran_pay">
                                        <div className="pay_tag"><img src={appay} className="" /></div>
                                        <div className="pay_tag"><img src={gpay} className="" /></div>
                                    </div>
                                </ul>
                            </div></div>
                            <div><div className="card_c mb-1"><label>#18,495</label></div>
                                <div className="card_c mb-1"><label>#18,495</label></div>
                                <div className="card_c mb-1"><label>#18,495</label></div></div>
                        </div>


                        <div className="save_mob_btn">
                            <div className="vote_drpdwn_btn">           <button className="new-bg"><span>Zum Antrag</span></button>
                                <div className="new_gray_btn">    <button className="new-bg"><span>Save</span></button></div></div>
                        </div>
                    </div>
                    <div className="mob_card_detail">
                        <div className="card_pc">          <div className="listing_coin_detail new_detail">
                            <div className="list_detail">
                                <div class="info">
                                    <div><a href="#"><strong></strong></a><strong>TF Mastercard Gold</strong>
                                        <div class="hidden-sm">Archive</div></div>
                                </div>
                            </div>
                            <div className="coin_logo new_card"><img src={Ccard} /></div>
                        </div>
                            <div className=""><p className="bg_green text-end">0 €</p><p className="f-12">Gebühr p.a </p></div></div>
                        <div className="indent_mob">  <div className="indent_info">
                            <div><span>Bezahlung</span><img src={infocoin} className="c_white" /></div>
                            <div><p>
                                Kostenlos weltweit</p></div>
                        </div>
                            <div className="indent_info">
                                <div><span>Abhebung</span><img src={infocoin} className="c_white" /></div>
                                <div><p>
                                    Kostenpflichtig</p></div>
                            </div></div>
                        <div className="card_m_del">
                            <div className=''><div className="coinimg_new">
                                <ul className="td_coin_data">
                                    <li><img src={checktick} /><span>Kostenlos weltweit</span><img src={infocoin} className="c_white" />
                                    </li>
                                    <li><img src={checktick} /><span>jhdZins: 24,79%</span><img src={infocoin} className="c_white" />
                                    </li>
                                    <li><img src={checktick} /><span>Kein Video-/Post-Ident nötig</span><img src={infocoin} className="c_white" />

                                    </li>
                                    <li><img src={infocoin} className="c_white" /><span>Ausgleich per Überweisung</span><img src={infocoin} className="c_white" />
                                    </li>
                                    <div className="tran_pay">
                                        <div className="pay_tag"><img src={appay} className="" /></div>
                                        <div className="pay_tag"><img src={gpay} className="" /></div>
                                    </div>
                                </ul>
                            </div></div>
                            <div><div className="card_c mb-1"><label>#18,495</label></div>
                                <div className="card_c mb-1"><label>#18,495</label></div>
                                <div className="card_c mb-1"><label>#18,495</label></div></div>
                        </div>


                        <div className="save_mob_btn">
                            <div className="vote_drpdwn_btn">           <button className="new-bg"><span>Zum Antrag</span></button>
                                <div className="new_gray_btn">    <button className="new-bg"><span>Save</span></button></div></div>
                        </div>
                    </div>
                    <div className="mob_card_detail">
                        <div className="card_pc">          <div className="listing_coin_detail new_detail">
                            <div className="list_detail">
                                <div class="info">
                                    <div><a href="#"><strong></strong></a><strong>TF Mastercard Gold</strong>
                                        <div class="hidden-sm">Archive</div></div>
                                </div>
                            </div>
                            <div className="coin_logo new_card"><img src={Ccard} /></div>
                        </div>
                            <div className=""><p className="bg_green text-end">0 €</p><p className="f-12">Gebühr p.a </p></div></div>
                        <div className="indent_mob">  <div className="indent_info">
                            <div><span>Bezahlung</span><img src={infocoin} className="c_white" /></div>
                            <div><p>
                                Kostenlos weltweit</p></div>
                        </div>
                            <div className="indent_info">
                                <div><span>Abhebung</span><img src={infocoin} className="c_white" /></div>
                                <div><p>
                                    Kostenpflichtig</p></div>
                            </div></div>
                        <div className="card_m_del">
                            <div className=''><div className="coinimg_new">
                                <ul className="td_coin_data">
                                    <li><img src={checktick} /><span>Kostenlos weltweit</span><img src={infocoin} className="c_white" />
                                    </li>
                                    <li><img src={checktick} /><span>jhdZins: 24,79%</span><img src={infocoin} className="c_white" />
                                    </li>
                                    <li><img src={checktick} /><span>Kein Video-/Post-Ident nötig</span><img src={infocoin} className="c_white" />

                                    </li>
                                    <li><img src={infocoin} className="c_white" /><span>Ausgleich per Überweisung</span><img src={infocoin} className="c_white" />
                                    </li>
                                    <div className="tran_pay">
                                        <div className="pay_tag"><img src={appay} className="" /></div>
                                        <div className="pay_tag"><img src={gpay} className="" /></div>
                                    </div>
                                </ul>
                            </div></div>
                            <div><div className="card_c mb-1"><label>#18,495</label></div>
                                <div className="card_c mb-1"><label>#18,495</label></div>
                                <div className="card_c mb-1"><label>#18,495</label></div></div>
                        </div>


                        <div className="save_mob_btn">
                            <div className="vote_drpdwn_btn">           <button className="new-bg"><span>Zum Antrag</span></button>
                                <div className="new_gray_btn">    <button className="new-bg"><span>Save</span></button></div></div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    );
}

export default TabcommData1c;