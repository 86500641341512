import { useEffect, useState } from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import oreocoin from '../assets/images/oreo.png';
import "../assets/css/faq.scss";
import { Loader } from '../components/Loader';
import { LOADER_TIMER } from '../constants';
import "../assets/css/coinpagedetail.scss";
import Common2 from "../components/TabsCommon2";
import FloatingSearch from '../components/FloatingSearch';
export const CoinPageDetail = () => {

    const [loader, setLoader] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoader(false);
        }, LOADER_TIMER)
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [])

    return (
        <>
            <Loader show={loader} />
            <main className='coinpage_main'>
                <Header />

                <div className='container'>
                    <section className='coinpage_detail'>
                        <div className='coindetail_logo'>
                            <img src={oreocoin} />
                        </div>
                        <div className='row'>
                            <div className='col-md-7 col-lg-8'>
                                <div className='coin_details'>
                                <div className='coin_name d-flex align-item-end'><p>OreoSwap</p> <h3 class="ms-3">$ 0.0203715</h3></div>
                                <div className='coin_name'>
                                <button className='cat_coin me-2'>Coin</button>
                                <button className='cat_coin space-left'>Explorers</button>
                                <button className='cat_coin space-left'>Community</button>
                                <button className='cat_coin space-left'>Source code</button>
                                <button className='cat_coin space-left'>Whitepaper</button>
                                </div>
                                <div className='coin_create'><span>created by</span><a href='#' className='add_font'>0xda7a4...2a24</a></div>
                                <div className='row'>
                                    <div className='col-lg-7'>
                                <div className="price-card my-4">
                                    <div className="price-inner">
                                        <div className="d-flex">
                                            <div className='price-eth'>
                                            <div>
                                                <h5>Price</h5>
                                            </div>
                                            <div>
                                                <h3>0.013 ETH</h3>
                                            </div>
                                            <div >
                                                <h5>$22</h5>
                                            </div>
                                            </div>
                                            <div className="higest-tatr ms-4">
                                            <div >
                                                <h5>Highest</h5>
                                            </div>
                                            <div>
                                                <h3>5.22 TATR</h3>
                                            </div>
                                            <div >
                                                <h5>by <span className="text-white">0x60f4c8645...7beb</span></h5>
                                            </div>
                                            </div>
                                        </div>
                                        <div>
                                        <div className='d-flex my-3'>
                                        <button type="button" className="eth-btn">0.013 ETH</button>
                                        <button type="button"  className="add-more"><svg viewBox="0 0 25 24" fill="none" width="24" height="24" xlmns="http://www.w3.org/2000/svg" className="sc-bdvvtL sc-iCfMLu gDGMpz"><g><path d="M12.5 5V19" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M5.5 12H19.5" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></g></svg></button>
                                        </div>
                                        <button type="button"className='pick-bid'>Place a bid</button>
                                        </div>
                                        <div className='mt-3 time-ends d-flex align-items-center justify-content-center'>
                                        <span className='me-2 color-grey'>Sale ends in</span>
                                        <span className='color-grey'><p>6d&nbsp;4h&nbsp;58m&nbsp;56s</p></span>
                                        </div>
                                    </div>
                                </div>
                                </div>
                                <div className='col-md-5'></div>
                                </div>
                           
                            </div>
                            </div>
                            <div className='col-md-5 col-lg-4'>
                                <div className='coinhunt_del'>
                                    <div className='coin_hunt_upper'>
                                        <div className='coin_hunt_upper_del'>
                                            <div><span className='color_gry'>Price</span></div>
                                            <div><span>$ 0.0203715</span></div>
                                        </div>
                                        <div className='coin_hunt_upper_del'>
                                            <div><span className='color_gry'>Market Cap</span></div>
                                            <div><span>$ 2,53715</span></div>
                                        </div>
                                        <div className='coin_hunt_upper_del'>
                                            <div><span className='color_gry'>Launch Date</span></div>
                                            <div><span>8 days ago</span></div>
                                        </div>
                                        <div className='coin_hunt_upper_del'>
                                            <div><span className='color_gry'>Blokchain</span></div>
                                            <div><span>Ethereum</span></div>
                                        </div>
                                    </div>
                                    <div className='coin_hunt_bottom'>
                                        <div className='coin_hunt_upper_del'>
                                            <div><span className='color_gry'>By</span></div>
                                            <div><span>OreoSwap</span></div>
                                        </div>
                                        <div className='coin_hunt_upper_del mb-0'>
                                            <div><span className='color_gry'>Address</span></div>
                                            <div className="p-rel"><span className='add_font' id="copied_icon">0x764...2daa</span><span onClick={() => { document.getElementById("copied_icon").classList.add("active") ;setTimeout(()=>{document.getElementById("copied_icon").classList.remove("active")},2000)}}>
                                                {/* <span class="copied">Copied!</span> */}
                                                <svg className="copy-show" height="13" viewBox="-21 -21 682.66669 682.66669" width="13" xmlns="http://www.w3.org/2000/svg"><path d="m565 640h-340c-41.359375 0-75-33.640625-75-75v-340c0-41.359375 33.640625-75 75-75h340c41.359375 0 75 33.640625 75 75v340c0 41.359375-33.640625 75-75 75zm-340-440c-13.785156 0-25 11.214844-25 25v340c0 13.785156 11.214844 25 25 25h340c13.785156 0 25-11.214844 25-25v-340c0-13.785156-11.214844-25-25-25zm-125 240h-25c-13.785156 0-25-11.214844-25-25v-340c0-13.785156 11.214844-25 25-25h340c13.785156 0 25 11.214844 25 25v23.75h50v-23.75c0-41.359375-33.640625-75-75-75h-340c-41.359375 0-75 33.640625-75 75v340c0 41.359375 33.640625 75 75 75h25zm0 0" /></svg>
                                            </span></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='coins_sociallinks'>
                                    <div className='social_links_del'>
                                        <p>Share link to this page</p>
                                        <div className='social_links_icons_detail'>
                                        <div className='social_links_icons ps-0'>
                                            <button><a href='https://twitter.com/futur3_cc'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16">
                                                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                                            </svg></a></button><p>Twitter</p>
                                        </div>
                                        <div className='social_links_icons'>
                                            <button><a href='https://t.me/futur3_cc'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telegram" viewBox="0 0 16 16">
  <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
</svg></a></button><p>Telegram</p>
                                        </div>
                                        <div className='social_links_icons'>
                                            <button><a href='https://discord.gg/KmG39Mww'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-discord" viewBox="0 0 16 16">
  <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"/>
</svg></a></button><p>Discord</p>
                                        </div>
                                        <div className='social_links_icons'>
                                            <button><a href='https://futur3.app/'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16">
  <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z"/>
  <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z"/>
</svg></a></button><p>Website</p>
                                        </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-8'>
                            <div className='coinpagedel_tabs coin_details'>
                             <div class="mt-3"><Common2 /></div>
                                <div className='coin_p'><p>A Sewer Pass grants access to Yuga Labs’ Dookey Dash interactive skill-based mint,
                                    available on <a href='#'>mdvmm.xyz</a> (<a href='#'>https://mdvmm.xyz</a>) . from January 19, 2023 through February 8, 2023. Sewer Passes are tiered according to the A Sewer Pass grants access to Yuga Labs’ Dookey Dash interactive skill-based mint,
                                    available on <a href='#'>mdvmm.xyz</a> (<a href='#'>https://mdvmm.xyz</a>) . from January 19, 2023 through February 8, 2023. Sewer Passes are tiered according to  . from January 19, 2023 through February 8, 2023. Sewer Passes are tiered according to the A Sewer Pass grants access to Yuga Labs’ Dookey Dash interactive skill-based mint,  the BAYC/MAYC...<button>Show more</button></p></div>
<div className='coin_createbtns'><button type="button" class="btn btn-outline-secondary btn-secondary btn-secondary-rounded">Create marketplace</button></div>
                             </div>
                             </div>
                        </div>
                    </section>
                </div>
                <Footer />
                <FloatingSearch />
            </main>
        </>
    )
}