export const ROUTES =  {
    LANDING_PAGE: "/",
    HOME: "/home",
    HOMENEW: "/homenew",
    HOMENEW2: "/homenew2",
    FAQ: "/faq",
    SUBMIT: "/submit",
    PRIVACY: "/privacypolicy",
    STEP: "/step-form",
     CONTACT: "/contact",
    BLOGPOST: "/blogpost",
    // LAUNCHPADS: "/Launchpads",
    COINPAGE: "/CoinPage",
    EXCHANGES: "/Exchanges",
    BLOGPAGE: "/Blog",
    DISCLAIMER: "/Disclaimer",
    DYOR: "/Dyor",
    TERMSUSE: "/Termsofuse",
    COINPAGEDETAIL: "/CoinPageDetail",
    ABOUTUS: "/AboutUs",
    WISE: "/Wise",
    UNISWAP: "/Uniswap",
    UNISWAP2: "/Uniswap2",
    UNISWAP3: "/Uniswap3",
    APARTMENTDETAIL: "/ApartmentDetail",
    IMPRINT:"/Imprint",
    LOGIN:"/Login",
    SIGNUP:"/Signup",
    PASSWORD:"/Password",
    RECOVERPASS: "/RecoverPass",
    COINPAGE2: "/CoinPage2",
    NOPAGE: "/404",
    UNISWAP4: "/Uniswap4",
    SEARCH: "/Search", 
    BLOGPOST2: "/Blogpost2", 
    COINPAGE3: "/CoinPage3",
    UNIVERSITY: "/University",
    PROFILE: "/Profile",
    MUSEUM: "/Museum",
    MUSEUM2: "/Museum2",
}