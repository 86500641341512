import { useEffect, useState } from 'react';
import Headerfaq from "../components/Headerfaq";
import Dropdown from 'react-bootstrap/Dropdown';
import "../assets/css/stepform.scss";
import { Loader } from '../components/Loader';
import { LOADER_TIMER ,listingplatformItems,categoryselectItems} from '../constants';

export const Step = () => {
    const [loader, setLoader] = useState(true);
    const [listingplatform, setlistingplatform] = useState(null);
    const [categoryselect, setcategoryselect] = useState(null);
    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoader(false);
        }, LOADER_TIMER)
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [])
    return (
        <>
            <Loader show={loader} />
            <main className="stepform_page">
                <Headerfaq />

                <section className="sub_page1 pt-4">
                    <div className="container">

                        <div className="page1_detail custom-padding">

                            <div className="intro_page1">
                                <div>
                                    <p className="intro_custom">Let’s customize your experience.</p>
                                    <p className="f-20">This step is one-time setup, but you can reset it on your dashboard.</p>
                                </div>
                                <div className="intro_top_right">
                                </div>
                            </div>
                            <div className="page1_custm_detail">
                                <div className="row mt-0">
                                    <div className="col-md-2">
                                        <div className="cus_item">
                                            <div className="cus_subheader">
                                                <h6 className="">Logo <span>*</span></h6>
                                            </div>
                                            <div className="cus_subdetail">
                                                <div className="sub_input">
                                                    <div className="crl-btn">
                                                        <svg width="43" height="42" viewBox="0 0 43 42" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1.5 9C1.5 6.87827 2.34285 4.84344 3.84315 3.34315C5.34344 1.84285 7.37827 1 9.5 1H33.5C35.6217 1 37.6566 1.84285 39.1569 3.34315C40.6571 4.84344 41.5 6.87827 41.5 9V33C41.5 35.1217 40.6571 37.1566 39.1569 38.6569C37.6566 40.1571 35.6217 41 33.5 41H9.5C7.37827 41 5.34344 40.1571 3.84315 38.6569C2.34285 37.1566 1.5 35.1217 1.5 33V9Z" stroke="#56486B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M14.5 19C17.2614 19 19.5 16.7614 19.5 14C19.5 11.2386 17.2614 9 14.5 9C11.7386 9 9.5 11.2386 9.5 14C9.5 16.7614 11.7386 19 14.5 19Z" stroke="#56486B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M26.552 22.2426L9.5 41.0006H33.766C35.8172 41.0006 37.7844 40.1858 39.2348 38.7354C40.6852 37.2849 41.5 35.3178 41.5 33.2666V33.0006C41.5 32.0686 41.15 31.7106 40.52 31.0206L32.46 22.2306C32.0844 21.8208 31.6274 21.4939 31.1183 21.2705C30.6093 21.0472 30.0592 20.9325 29.5034 20.9336C28.9475 20.9347 28.3979 21.0517 27.8897 21.2771C27.3816 21.5025 26.926 21.8313 26.552 22.2426V22.2426Z" stroke="#56486B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                                                        <p>
                                                            Upload Image <br />
                                                            (400x400)
                                                        </p>
                                                        <input type="file" className="mk-vsb form-control" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 ps-0">
                                        <div className="cus_item">
                                            <div className="cus_subheader">
                                                <h6>Coin Name <span>*</span></h6>
                                            </div>
                                            <div className="cus_subdetail">
                                                <div className="sub_input"><input type="text" placeholder="Bitcoin" class="form-control" /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5 ps-0">
                                        <div className="cus_item">
                                            <div className="cus_subheader">
                                                <h6>Coin Symbol <span>*</span></h6>
                                            </div>
                                            <div className="cus_subdetail">
                                                <div className="sub_input"><input type="text" placeholder="BTC" class="form-control" /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-5-5">
                                    <div className="col-md-12">
                                        <div className="cus_item">
                                            <div className="cus_subheader">
                                                <h6>Network/Chain <span>*</span></h6>
                                            </div>
                                            <div className="cus_subdetail">
                                                <div className="sub_input">
                                                    <ul className="coin-block mb-0">
                                                        <li className="area-btn">
                                                            <span>Binance Smart Chain (BSC)</span>
                                                            <img src="https://s2.coinmarketcap.com/static/img/coins/64x64/1.png" className="coin-size" />
                                                        </li>

                                                        <li className="area-btn">
                                                            <span>Ethereum Chain (ETH)</span>
                                                            <img src="https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png" className="coin-size" />
                                                        </li>

                                                        <li className="area-btn">
                                                            <span>Cronos Chain (CRO)</span>
                                                            <img src="	https://s2.coinmarketcap.com/static/img/coins/64x64/3635.png" className="coin-size" />
                                                        </li>

                                                        <li className="area-btn">
                                                            <span>Polygon (Matic)</span>
                                                            <img src="https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png" className="coin-size" />
                                                        </li>

                                                        <li className="area-btn">
                                                            <span>Solana (SOL)</span>
                                                            <img src="https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png" className="coin-size" />
                                                        </li>

                                                    </ul>
                                                    <ul className="coin-block mb-0 mt-3">
                                                        <li className="area-btn">
                                                            <span>Binance Smart Chain (BSC)</span>
                                                            <img src="https://s2.coinmarketcap.com/static/img/coins/64x64/1.png" className="coin-size" />
                                                        </li>

                                                        <li className="area-btn">
                                                            <span>Ethereum Chain (ETH)</span>
                                                            <img src="https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png" className="coin-size" />
                                                        </li>

                                                        <li className="area-btn">
                                                            <span>Cronos Chain (CRO)</span>
                                                            <img src="	https://s2.coinmarketcap.com/static/img/coins/64x64/3635.png" className="coin-size" />
                                                        </li>

                                                        <li className="area-btn">
                                                            <span>Polygon (Matic)</span>
                                                            <img src="https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png" className="coin-size" />
                                                        </li>

                                                        <li className="area-btn">
                                                            <span>Solana (SOL)</span>
                                                            <img src="https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png" className="coin-size" />
                                                        </li>

                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-5-5">
                                    <div className="col-md-4">
                                        <div className="cus_item">
                                            <div className="cus_subheader">
                                                <h6>Project in presale phase? <span>*</span></h6>
                                            </div>
                                            <div className="cus_subdetail">
                                                <div className="sub_input d-flex align-items-center pl-4 prephase">

                                                    <div className="contc_check my-2">
                                                        <label class="radio_cus">Yes
                                                            <input type="radio" name="radio" checked/>
                                                            <span class="checkmark_radio"></span>
                                                        </label>
                                                        <label class="radio_cus ms-4">No
                                                            <input type="radio" name="radio" />
                                                            <span class="checkmark_radio"></span>
                                                        </label>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 ps-0">
                                        <div className="cus_item">
                                            <div className="cus_subheader">
                                                <h6>Listing Platform </h6>
                                            </div>
                                            <div className="cus_subdetail cus_subdetail_dropdown new_textcolor">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="btn" id="dropdown-basic" className="form-control"><span>{listingplatform ? listingplatform.label: "Please Select"}</span></Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                    {
                                                        listingplatformItems.map(({value, label}) =>(
                                                            <Dropdown.Item key={value} onClick={(e) => {
                                                                setlistingplatform({ value, label})
                                                            }}>{ label }</Dropdown.Item>
                                                        ))
                                                    }
                                                   

                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 ps-0">
                                        <div className="cus_item">
                                            <div className="cus_subheader">
                                                <h6>Listing Link <span>*</span></h6>
                                            </div>
                                            <div className="cus_subdetail">
                                                <div className="sub_input"><input type="text" placeholder="Your listing link" class="form-control" /></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-5-5">
                                    <div className="col-md-4">
                                        <div className="cus_item">
                                            <div className="cus_subheader">
                                                <h6>Launch Date</h6>
                                            </div>
                                            <div className="cus_subdetail">
                                                <div className="sub_input"><input type="date" placeholder="dd-mm-yyyy" class="form-control dd date_color" /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 ps-0">
                                        <div className="cus_item">
                                            <div className="cus_subheader">
                                                <h6>Contract Address <span>*</span></h6>
                                            </div>
                                            <div className="cus_subdetail">
                                                <div className="sub_input"><input type="text" placeholder="0x7851452d2ad..." class="form-control" /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 ps-0">
                                        <div className="cus_item">
                                            <div className="cus_subheader">
                                                <h6>Category <span>*</span></h6>
                                            </div>
                                            <div className="cus_subdetail cus_subdetail_dropdown new_textcolor">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="btn" id="dropdown-basic" className="form-control"><span>{categoryselect ? categoryselect.label: "Please Select"}</span></Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                    
                                                    {
                                                        categoryselectItems.map(({value, label}) =>(
                                                            <Dropdown.Item key={value} onClick={(e) => {
                                                                setcategoryselect({ value, label})
                                                            }}>{ label }</Dropdown.Item>
                                                        ))
                                                    }
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className="row mt-5-5">
                                    <div className="col-md-4">
                                        <div className="cus_item">
                                            <div className="cus_subheader">
                                                <h6>Website Link <span>*</span></h6>
                                            </div>
                                            <div className="cus_subdetail">
                                                <div className="sub_input"><input type="text" placeholder="https://www.example.com" class="form-control dd" /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 ps-0">
                                        <div className="cus_item">
                                            <div className="cus_subheader">
                                                <h6>Twitter Link <span>*</span></h6>
                                            </div>
                                            <div className="cus_subdetail">
                                                <div className="sub_input"><input type="text" placeholder="https://www.twitter.com/example" class="form-control" /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 ps-0">
                                        <div className="cus_item">
                                            <div className="cus_subheader">
                                                <h6>Telegram Link <span>*</span></h6>
                                            </div>
                                            <div className="cus_subdetail">
                                                <div className="sub_input"><input type="text" placeholder="https://t.me/example" class="form-control" /></div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className="row mt-5-5">
                                    <div className="col-md-4">
                                        <div className="cus_item">
                                            <div className="cus_subheader">
                                                <h6>Discord Link <span>*</span></h6>
                                            </div>
                                            <div className="cus_subdetail">
                                                <div className="sub_input"><input type="text" placeholder="https://www.discord.gg/example" class="form-control dd" /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 ps-0">
                                        <div className="cus_item">
                                            <div className="cus_subheader">
                                                <h6>Reddit Link</h6>
                                            </div>
                                            <div className="cus_subdetail">
                                                <div className="sub_input"><input type="text" placeholder="https://www.reddit.com/r/example" class="form-control" /></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 ps-0">
                                        <div className="cus_item">
                                            <div className="cus_subheader">
                                                <h6>Telegram Contact</h6>
                                            </div>
                                            <div className="cus_subdetail">
                                                <div className="sub_input"><input type="text" placeholder="https://t.me/Username" class="form-control" /></div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-4 ps-0">

                                    </div>
                                </div>
                                <div className="row mt-5-5">
                                    <div className="col-md-12">
                                        <div className="cus_item">
                                            <div className="cus_subheader">
                                                <h6>Coin Description <span>*</span></h6>
                                            </div>
                                            <div className="cus_subdetail">
                                                <div className="sub_input">
                                                    <textarea placeholder="Describe your coin. What makes the coin different from others? What is the goal/purpose of the coin?" class="form-control" ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="contc_sub mb-0 pb-0"><button class="btn btn-outline-secondary btn-secondary">Submit</button></div>
                        </div>

                    </div>

                </section>

            </main>
        </>
    )
}