import "../../assets/css/loading.scss";
import loadingimg from '../../assets/images/Loadingwhite.webm';
import loadingnew from '../../assets/images/ezgif.com-gif-to-webm.webm';
export const Loader = ({ show = true }) => {
    return (
        <>
            {show &&
                <div class="loading">
                    <video width="800" height="800" autoplay="true" muted loop>
                        <source src={loadingnew} type="video/mp4" />
                    </video>
                </div>
            }

        </>
    )
};