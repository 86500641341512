import { useEffect, useState } from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../assets/css/faq.scss";
import pinksale from '../assets/images/pinksale.webp';
import { Loader } from '../components/Loader';
import { LOADER_TIMER } from '../constants';

export const Launchpads = () => {

    const [loader, setLoader] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoader(false);
        }, LOADER_TIMER)
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [])

    return (
        <>
            <Loader show={loader} />
     <main>
     <Header />
<div className='container'>
<section className="coin_section">

<div className="prom_head">
    <h3 className='pt-4'>Launchpads</h3>
</div>
<div className='prom_grids mb-5'>
    <div className="row">
        <div className="col-md-6 pe-0">
            <ul className="grid-sites-last mb-0">
                <li>
                    <a href="https://www.pinksale.finance/launchpads">
                    <div className="card-site-last">
                        <figure className="card-site-last__figure  mb-0">
                            <img src={pinksale} />
                        </figure>
                        <div className="card-site-last__info">
                                <span className="sale_live">Sale Live</span>
                            <div className="row mt-5">
                                <div className="col-2"></div>
                                <div className="col-10 d-flex justify-content-end">
                                    <span className="cat_tag bg-green ms-2">KYC</span>
                                    <span className="cat_tag bg-pink">Audit</span>
                                </div>
                            </div>
                            <div className="card-site-last__top d-none">
                                <div>Dec 25, 2022</div>
                            </div>
                            <div className="card-site-last__bottom  pull-top-bottom">
                                <h3 className="card-site-last__title d-none">Plastic</h3>
                                <div className="card-site-last__by">
                                    <small className='d-none'>by</small>
                                    <strong className="text-medium">
                                        <div className="users-credits ">
                                            <div className="banner_lower_text mt-1 mb-4">
                                                <div className="banner_lower_img"><a><img src='https://www.shibainu.gold/logotype.png' /></a></div>
                                                <div className="banner_lower_imgtext"><a className="link_underline no-before">Shiba Inu Gold</a></div>
                                            </div>

                                        </div>

                                    </strong>
                                </div>
                                <div className="c-boxes-score">
                                    <div className="box-score box-score--small">
                                        <div className="box-score__top">
                                            <strong>CHAIN</strong>
                                        </div>
                                        <div className="box-score__bottom">
                                            <strong>BNB</strong>
                                        </div>
                                    </div>
                                    <div className="box-score box-score--small">
                                        <div className="box-score__top">
                                            <strong>SOFTGAP</strong>
                                        </div>
                                        <div className="box-score__bottom">
                                            <strong>675</strong>
                                        </div>
                                    </div>
                                    <div className="box-score box-score--small">
                                        <div className="box-score__top">
                                            <strong>HARDGAP</strong>
                                        </div>
                                        <div className="box-score__bottom">
                                            <strong>1,350</strong>
                                        </div>
                                    </div>
                                    <div className="box-score box-score--small">
                                        <div className="box-score__top">
                                            <strong>LOCKUP TIME</strong>
                                        </div>
                                        <div className="box-score__bottom">
                                            <strong>365 days</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </a>
                </li>
            </ul>
        </div>
        <div className="col-md-6 ps-0">
            <ul className="grid-sites-last mb-0">
                <li>
                <a href="https://www.pinksale.finance/launchpads">
                    <div className="card-site-last">
                        <figure className="card-site-last__figure  mb-0">
                            <img src={pinksale} />
                        </figure>
                        <div className="card-site-last__info">
                                <span className="sale_live">Sale Live</span>
                            <div className="row mt-5">
                                <div className="col-2"></div>
                                <div className="col-10 d-flex justify-content-end">
                                    <span className="cat_tag bg-green ms-2">KYC</span>
                                    <span className="cat_tag bg-pink">Audit</span>
                                </div>
                            </div>
                            <div className="card-site-last__top d-none">
                                <div>Dec 25, 2022</div>
                            </div>
                            <div className="card-site-last__bottom  pull-top-bottom">
                                <h3 className="card-site-last__title d-none">Plastic</h3>
                                <div className="card-site-last__by">
                                    <small className='d-none'>by</small>
                                    <strong className="text-medium">
                                        <div className="users-credits ">
                                            <div className="banner_lower_text mt-1 mb-4">
                                                <div className="banner_lower_img"><a><img src='https://www.shibainu.gold/logotype.png' /></a></div>
                                                <div className="banner_lower_imgtext"><a className="link_underline no-before">Shiba Inu Gold</a></div>
                                            </div>

                                        </div>

                                    </strong>
                                </div>
                                <div className="c-boxes-score">
                                    <div className="box-score box-score--small">
                                        <div className="box-score__top">
                                            <strong>CHAIN</strong>
                                        </div>
                                        <div className="box-score__bottom">
                                            <strong>BNB</strong>
                                        </div>
                                    </div>
                                    <div className="box-score box-score--small">
                                        <div className="box-score__top">
                                            <strong>SOFTGAP</strong>
                                        </div>
                                        <div className="box-score__bottom">
                                            <strong>675</strong>
                                        </div>
                                    </div>
                                    <div className="box-score box-score--small">
                                        <div className="box-score__top">
                                            <strong>HARDGAP</strong>
                                        </div>
                                        <div className="box-score__bottom">
                                            <strong>1,350</strong>
                                        </div>
                                    </div>
                                    <div className="box-score box-score--small">
                                        <div className="box-score__top">
                                            <strong>LOCKUP TIME</strong>
                                        </div>
                                        <div className="box-score__bottom">
                                            <strong>365 days</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </a>
                </li>
            </ul>
        </div>
        <div className="col-md-6 pe-0">
            <ul className="grid-sites-last mb-0">
                <li>
                <a href="https://www.pinksale.finance/launchpads">
                    <div className="card-site-last">
                        <figure className="card-site-last__figure  mb-0">
                            <img src={pinksale} />
                        </figure>
                        <div className="card-site-last__info">
                                <span className="sale_live">Sale Live</span>
                            <div className="row mt-5">
                                <div className="col-2"></div>
                                <div className="col-10 d-flex justify-content-end">
                                    <span className="cat_tag bg-green ms-2">KYC</span>
                                    <span className="cat_tag bg-pink">Audit</span>
                                </div>
                            </div>
                            <div className="card-site-last__top d-none">
                                <div>Dec 25, 2022</div>
                            </div>
                            <div className="card-site-last__bottom  pull-top-bottom">
                                <h3 className="card-site-last__title d-none">Plastic</h3>
                                <div className="card-site-last__by">
                                    <small className='d-none'>by</small>
                                    <strong className="text-medium">
                                        <div className="users-credits ">
                                            <div className="banner_lower_text mt-1 mb-4">
                                                <div className="banner_lower_img"><a><img src='https://www.shibainu.gold/logotype.png' /></a></div>
                                                <div className="banner_lower_imgtext"><a className="link_underline no-before">Shiba Inu Gold</a></div>
                                            </div>

                                        </div>

                                    </strong>
                                </div>
                                <div className="c-boxes-score">
                                    <div className="box-score box-score--small">
                                        <div className="box-score__top">
                                            <strong>CHAIN</strong>
                                        </div>
                                        <div className="box-score__bottom">
                                            <strong>BNB</strong>
                                        </div>
                                    </div>
                                    <div className="box-score box-score--small">
                                        <div className="box-score__top">
                                            <strong>SOFTGAP</strong>
                                        </div>
                                        <div className="box-score__bottom">
                                            <strong>675</strong>
                                        </div>
                                    </div>
                                    <div className="box-score box-score--small">
                                        <div className="box-score__top">
                                            <strong>HARDGAP</strong>
                                        </div>
                                        <div className="box-score__bottom">
                                            <strong>1,350</strong>
                                        </div>
                                    </div>
                                    <div className="box-score box-score--small">
                                        <div className="box-score__top">
                                            <strong>LOCKUP TIME</strong>
                                        </div>
                                        <div className="box-score__bottom">
                                            <strong>365 days</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </a>
                </li>
            </ul>
        </div>
        <div className="col-md-6 ps-0">
            <ul className="grid-sites-last mb-0">
                <li>
                <a href="https://www.pinksale.finance/launchpads">
                    <div className="card-site-last">
                        <figure className="card-site-last__figure  mb-0">
                            <img src={pinksale} />
                        </figure>
                        <div className="card-site-last__info">
                                <span className="sale_live">Sale Live</span>
                            <div className="row mt-5">
                                <div className="col-2"></div>
                                <div className="col-10 d-flex justify-content-end">
                                    <span className="cat_tag bg-green ms-2">KYC</span>
                                    <span className="cat_tag bg-pink">Audit</span>
                                </div>
                            </div>
                            <div className="card-site-last__top d-none">
                                <div>Dec 25, 2022</div>
                            </div>
                            <div className="card-site-last__bottom  pull-top-bottom">
                                <h3 className="card-site-last__title d-none">Plastic</h3>
                                <div className="card-site-last__by">
                                    <small className='d-none'>by</small>
                                    <strong className="text-medium">
                                        <div className="users-credits ">
                                            <div className="banner_lower_text mt-1 mb-4">
                                                <div className="banner_lower_img"><a><img src='https://www.shibainu.gold/logotype.png' /></a></div>
                                                <div className="banner_lower_imgtext"><a className="link_underline no-before">Shiba Inu Gold</a></div>
                                            </div>

                                        </div>

                                    </strong>
                                </div>
                                <div className="c-boxes-score">
                                    <div className="box-score box-score--small">
                                        <div className="box-score__top">
                                            <strong>CHAIN</strong>
                                        </div>
                                        <div className="box-score__bottom">
                                            <strong>BNB</strong>
                                        </div>
                                    </div>
                                    <div className="box-score box-score--small">
                                        <div className="box-score__top">
                                            <strong>SOFTGAP</strong>
                                        </div>
                                        <div className="box-score__bottom">
                                            <strong>675</strong>
                                        </div>
                                    </div>
                                    <div className="box-score box-score--small">
                                        <div className="box-score__top">
                                            <strong>HARDGAP</strong>
                                        </div>
                                        <div className="box-score__bottom">
                                            <strong>1,350</strong>
                                        </div>
                                    </div>
                                    <div className="box-score box-score--small">
                                        <div className="box-score__top">
                                            <strong>LOCKUP TIME</strong>
                                        </div>
                                        <div className="box-score__bottom">
                                            <strong>365 days</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</div>
</section>
</div>

     <Footer />
        </main>
        </>
    )
}