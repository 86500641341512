import { useEffect, useState } from 'react';
import HeaderNew2 from "../components/HeaderNew2";
import Footer from "../components/Footer";
import "../assets/css/faq.scss";
import { Loader } from '../components/Loader';
import { LOADER_TIMER } from '../constants';
import "../assets/css/aboutus.scss";
import futur3about from '../assets/images/Futur3about.webm';
import FloatingSearch from '../components/FloatingSearch';
import logotech from '../assets/images/tchy logo.png';

export const AboutUs = () => {

    const [loader, setLoader] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoader(false);
        }, LOADER_TIMER)
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [])

    return (
        <>
            <Loader show={loader} />
     <main className='coinpage_main'>
     <HeaderNew2 />

<div className='container'>
    <section className='aboutus_page'>
        <div className='aboutus_del'>
        <div className='row'>
            <div className='col-md-6'>
                <div className='about_left_del'>
                <div className='about_head'>
                    <h1>Get Incentives<br /> for Listing</h1>
                    <p>Get $RARI for trading NFTs from collections YOU choose</p>
                </div>
                <div className='aboutus_btn'><button type="button" class="btn btn-outline-secondary btn-secondary ">Lock $RARI</button>
                <button type="button" class="ms-4 btn btn-outline-secondary btn-secondary dark_btn">Join $RARI Community</button></div>
            </div>
            </div>
            <div className='col-md-6'>
                <div className='about_us_right'>
                {/* <video  autoplay="true" muted loop>
                        <source src={futur3about} type="video/mp4" />
                    </video> */}
                    <img src={logotech}/>
                </div>
            </div>
        </div>
        </div>
        <div className='about_lock aboutus_del pt-0'>
            <div className='about_lock_heading'>
                <a href='javascript:void(0)'>Lock</a>
                <p>Lock $RARI to receive veRARI, voting power and other perks. Your total balance:<span className='digits'>0.00</span> $RARI
                We are not responsible or liable in any manner for any content posted on Our Website or in connection with Our Website by third party. Although We provide rules for user conduct and postings, We do not control and are not responsible for what the third-party post, transmit, or share in connection to our website, and are not responsible for any offensive, inappropriate, obscene, unlawful or otherwise objectionable content You may encounter. We are not responsible for the conduct, whether online or offline, of any user of Our Website.
                The Website may contain (or You may be sent through the Website links to other sites or content belonging to or originating from third parties or links to sites and features in banners or other advertising. We do not investigate, monitor, or check such external links for accuracy, adequacy, validity, reliability, availability, or completeness.

We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of any information offered by third-party websites linked through the Website or any site or feature linked in any banner or other advertising. We will not be a party to or in any way be responsible for monitoring any transaction between You and third-party providers of products or services.

</p>
            </div>
        </div>
    </section>
</div>
     <Footer />
     <FloatingSearch />
        </main>
        </>
    )
}