import React from "react";
import { useEffect, useState } from 'react';
import { Loader } from '../components/Loader';
import { LOADER_TIMER } from '../constants';
import '../assets/css/uniswap2.scss';
import utick from '../assets/images/u_tick.svg';
import HeaderNew2 from "../components/HeaderNew2";
import Footer from "../components/Footer";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from "swiper/modules";
import slidernew from '../assets/images/1_Bedroom_1.webp';
import slidernew1 from '../assets/images/sw-g1.webp';
import slidernew2 from '../assets/images/sw-g2.webp';
import slidernew3 from '../assets/images/sw-g3.webp';
import slidernew4 from '../assets/images/sw-g4.webp';
import slidernew5 from '../assets/images/sw-g5.webp';
import slidernew6 from '../assets/images/sw-g6.webp';
import slidernew7 from '../assets/images/sw-g7.webp';
import slidernew8 from '../assets/images/sw-g8.webp';
import slidernew9 from '../assets/images/sw-g9.webp';
import slidernew10 from '../assets/images/sw-g10.webp';
import map from '../assets/images/mapapaprtment.PNG';
import '../assets/css/apartmentdetail.scss';
import Accordion from 'react-bootstrap/Accordion';


export const ApartmentDetail = () => {
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        console.log(loader, "Loader");
        const timeout = setTimeout(() => {

            setLoader(false);
        }, LOADER_TIMER)
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [])
    return (
        <>
            <Loader show={loader} />
            <main>
                <HeaderNew2 />
                <div className="sticky_check">
    <div className="">
    <div className='bag_head'>
                                    <div className="section_head head_small"> <h5>Apartment Highlight</h5>  <img src={utick} /></div>
                                   <div className="chek_btn_section"> <button className="chek_btn">Visit Website</button></div>
                                </div>
    </div>
</div>
                <div className='container more_appart'>
                    <div className='row'>
                        <div className='col-md-7 col-lg-9'>
                            <div className='section_left'>
                                <div className='section_head ap_head'>
                                    <h5>1 room apartment with terrace in Wilmersdorf (Berlin)</h5>  <img src={utick} />
                                </div>
                                <p className="mt-2">Zechliner Straße 1a Berlin, Wedding </p>
                                <div className="app_swiper">

                                    <Swiper
                                        slidesPerView={3}
                                        spaceBetween={30}
                                        pagination={{
                                            clickable: true
                                        }}
                                        navigation={true}
                                        modules={[Pagination]}
                                        className="mySwiper"

                                    >
                                        <SwiperSlide>
                                            <div className="app_gal">
                                                <div className="gal_img">
                                                    <img src={slidernew1} />
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="app_gal">
                                                <div className="gal_img">
                                                    <img src={slidernew2} />
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="app_gal">
                                                <div className="gal_img">
                                                    <img src={slidernew3} />
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="app_gal">
                                                <div className="gal_img">
                                                    <img src={slidernew4} />
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="app_gal">
                                                <div className="gal_img">
                                                    <img src={slidernew5} />
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="app_gal">
                                                <div className="gal_img">
                                                    <img src={slidernew6} />
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="app_gal">
                                                <div className="gal_img">
                                                    <img src={slidernew7} />
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="app_gal">
                                                <div className="gal_img">
                                                    <img src={slidernew8} />
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="app_gal">
                                                <div className="gal_img">
                                                    <img src={slidernew9} />
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="app_gal">
                                                <div className="gal_img">
                                                    <img src={slidernew10} />
                                                </div>
                                            </div>
                                        </SwiperSlide>



                                    </Swiper>
                                </div>
                                <div className="app_price_detail">
                                    <div className="acc1_detail">
                                        <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Traits</Accordion.Header>
                                                <Accordion.Body>
                                                    <div className="price_small_cards">
                                                        <div className="s_cards">
                                                            <p>Kaltmiete</p>
                                                            <h6>1.500 €</h6>
                                                        </div>
                                                        <div className="s_cards">
                                                            <p>Preis/m²</p>
                                                            <h6>34,09 €/m² </h6>
                                                        </div>
                                                        <div className="s_cards">
                                                            <p>Nebenkosten</p>
                                                            <h6>+330 €</h6>
                                                        </div>
                                                        <div className="s_cards">
                                                            <p>Heizkosten</p>
                                                            <h6>in Nebenkosten </h6>
                                                        </div>
                                                        <div className="s_cards">
                                                            <p>Gesamtmiete</p>
                                                            <h6>1.830 €</h6>
                                                        </div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            </Accordion>
                                            <Accordion defaultActiveKey="1">
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>Description</Accordion.Header>
                                                <Accordion.Body>
                                                    Escalo's design combines wooden accents with rough raw materials, influenced by the serenity of the mountain. Located in the upscale Wilmersdorf neighborhood, this apartment is abundant in international restaurants, shops, and theaters right outside your front door. When the day of exploring is finished, come home to  Escalo for absolute indulgence.<br /><br />

                                                    Feel right at home in this studio with 1 bathroom paradise and 1 queen-sized bed. The main living area combines bedroom with living space. An abstract wooden shelving unit helps to separate the heavenly mattress from the living room sitting area. A dedicated workstation sits aside the foot of the bed along with a television monitor in order to enjoy the full entertainment system. The cozy carpet brings the room together and invokes a feeling of mountain peacefulness.
                                                    <br /><br />
                                                    The bathroom is a mark above the rest with its slick and functional design. The kitchen has been stylishly decorated and boasts ample space to prepare food in. After cooking, guests can enjoy their meals at the dining table in the kitchen, or outside on the marvelous terrace, which offers the chance to take in the spectacular views of Berlin. Fully furnished, this home comes with everything you need, including bedding and linens.
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            </Accordion>

                                            <Accordion defaultActiveKey="2">
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>Ausstattung</Accordion.Header>
                                                <Accordion.Body>
                                             <ul>
                                                <li>Oven</li>
                                                <li>Washing Machine</li>
                                                <li>Toaster</li>
                                                <li>Coffee Maker</li>
                                                <li>Electric Stove</li>
                                                <li>High Speed Wifi</li>
                                             </ul>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            </Accordion>

                                            <Accordion defaultActiveKey="3">
                                            <Accordion.Item eventKey="3">
                                                <Accordion.Header>Lage</Accordion.Header>
                                                <Accordion.Body>
                                                One of Berlin’s more upscale neighborhoods, Wilmersdorf is a residential neighborhood known for its green space, upscale shops, and restored pre-war buildings. This neighborhood provides a family feel to the center of Berlin. Take a walk in a park, peruse through luxury brand store fronts, and have lunch at one of many Asian eateries that are scattered throughout the area.                                                </Accordion.Body>
                                            </Accordion.Item>
                                            </Accordion>
                                            <Accordion defaultActiveKey="4">
                                            <Accordion.Item eventKey="4">
                                                <Accordion.Header>Sonstiges</Accordion.Header>
                                                <Accordion.Body>
                                                Our bookings are from 3 to 11 months, with the option to extend.<br /><br />
                                                Our apartments can be sold through our website, there is no selection process.  <br /><br />                                   
                                                *We welcome pets, fees are subject to apply. Guests will be informed of any additional charges in the final contract.                                                </Accordion.Body>
                                            </Accordion.Item>
                                            </Accordion>
                                    </div>
                                    <div className="map_apartment">
                                        <h5>Location</h5>
                                   <div className="map_img"> <img src={map} /></div>
                                </div>
<div className="sticky_show_mob">
<div className='sticky_bagnew'>
                                <div className='bag_head'>
                                    <div className="section_head head_small"> <h5>Apartment Highlight</h5>  <img src={utick} /></div>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 4.99092C13 4.09592 14.094 3.66096 14.709 4.31196L20.637 10.582C21.121 11.094 21.121 11.894 20.637 12.406L14.709 18.676C14.094 19.326 13 18.891 13 17.997V14.4919C5.437 14.4919 4.93602 16.962 4.96802 19.529C4.97402 20.019 4.32501 20.1811 4.08301 19.7561C3.46701 18.6751 3 17.1999 3 15.4909C3 8.99592 10 8.49702 13 8.49702V4.99092Z" fill="#9B9B9B"></path></svg>
                                </div>
                                <div className="stiky_title">Very alive area in Berlin</div>
                                <div className="best_prc">
                                    <div className='bag_head'>
                                        <div className="section_head head_small"> <h5>Final Price</h5> </div>

                                    </div>
                                    <div className="best_P"><h6>1.500 €<span>1.830 €</span></h6>
                                        <p>Many people are viewing this apartment</p>
                                        <button>Visit Website</button></div>
                                </div>
                                <div className="best_P text-center">
                                       
                                        <button className="save_btn">Save</button></div>
                            </div>
</div>



                             <div className="app_list">
                            <h1>You might also like</h1>
                           
                             </div>
                                </div>
                                <div className='row '>
                                            <div className='col-lg-6'>
                                                <div className='filter_card'>
                                                    <div className='f_-card_img'>
                                                        <img src={slidernew1} />
                                                        {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                    </div>
                                                    <div className='f_card_detail'>
                                                        <div className='card_count'>
                                                            <div className='loc_app'> <h6>1 room apartment with terrace in Wilmersdorf (Berlin)</h6>
                                                                <p><span className=''>Zechliner Straße 1a Berlin, Wedding </span></p></div>
                                                            <div>   <label>#18,495</label></div>

                                                        </div>
                                                        <div className='dimen_app'>
                                                            <div>
                                                                <h6>2244 €</h6>
                                                                <p>Kaltmiete</p>
                                                            </div>
                                                            <div>
                                                                <h6>88,99 m<sup>2</sup></h6>
                                                                <p>Wohnfinance</p>
                                                            </div>
                                                            <div>
                                                                <h6>4</h6>
                                                                <p>Zi</p>
                                                            </div>
                                                        </div>
                                                        <div className='add_btn'>
                                                            <button className=''>Save to list</button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='filter_card'>
                                                    <div className='f_-card_img'>
                                                        <img src={slidernew2} />
                                                        {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                    </div>
                                                    <div className='f_card_detail'>
                                                        <div className='card_count'>
                                                            <div className='loc_app'> <h6>1 room apartment with terrace in Wilmersdorf (Berlin)</h6>
                                                                <p><span className=''>Zechliner Straße 1a Berlin, Wedding </span></p></div>
                                                            <div>   <label>#18,495</label></div>

                                                        </div>
                                                        <div className='dimen_app'>
                                                            <div>
                                                                <h6>2244 €</h6>
                                                                <p>Kaltmiete</p>
                                                            </div>
                                                            <div>
                                                                <h6>88,99 m<sup>2</sup></h6>
                                                                <p>Wohnfinance</p>
                                                            </div>
                                                            <div>
                                                                <h6>4</h6>
                                                                <p>Zi</p>
                                                            </div>
                                                        </div>
                                                        <div className='add_btn'>
                                                            <button className=''>Save to list</button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            
                                        
                                        </div>
                            </div>
                        </div>
                        <div className='col-md-5 col-lg-3'>
                            <div className='sticky_bagnew mob_sti_none'>
                                <div className='bag_head'>
                                    <div className="section_head head_small"> <h5>Apartment Highlight</h5>  <img src={utick} /></div>
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 4.99092C13 4.09592 14.094 3.66096 14.709 4.31196L20.637 10.582C21.121 11.094 21.121 11.894 20.637 12.406L14.709 18.676C14.094 19.326 13 18.891 13 17.997V14.4919C5.437 14.4919 4.93602 16.962 4.96802 19.529C4.97402 20.019 4.32501 20.1811 4.08301 19.7561C3.46701 18.6751 3 17.1999 3 15.4909C3 8.99592 10 8.49702 13 8.49702V4.99092Z" fill="#9B9B9B"></path></svg>
                                </div>
                                <div className="stiky_title">Very alive area in Berlin</div>
                                <div className="best_prc">
                                    <div className='bag_head'>
                                        <div className="section_head head_small"> <h5>Final Price</h5> </div>

                                    </div>
                                    <div className="best_P"><h6>1.830 €<span>1.500 €</span></h6>
                                        <p>Many people are viewing this apartment</p>
                                        <button>Visit Website</button></div>
                                </div>
                                <div className="best_P text-center">
                                       
                                        <button className="save_btn">Save</button></div>
                            </div>
                        </div>
                    </div>
                </div>


<Footer/>
            </main>

        </>
    )
}