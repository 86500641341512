import { useEffect, useState } from 'react';
import Headerfaq from "../components/Headerfaq";
import logoicon from '../assets/images/icon.png';
import { Loader } from '../components/Loader';
import { LOADER_TIMER } from '../constants';
import "../assets/css/submit.scss";

export const Submit = () => {
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoader(false);
        }, LOADER_TIMER)
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [])
    return (
        <>
            <Loader show={loader} />
            <main className="sub_page">
                <Headerfaq />
                <section className="submt_page">
                    <div className="container">
                        <div className="sub_page_del">
                            <div className="sub_page_ti">
                                <h1>Hey there... <br />What will we build today?</h1>
                            </div>
                            <div className="sub_detail">
                                <div className="sub_item">
                                    <div className="sub_item_list">
                                        <span className="index">1</span><h1>Cryptoasset</h1>
                                    </div>
                                    <div className="sub_item_del">
                                        <p>     Lorem ipsum dolor sit amet. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi utaliquip ex ea commodo consequat. Duis aute irure dolor in
                                        </p>
                                    </div>
                                    <div className="sub_item_btn">
                                        <button type="button" className="btn btn-outline-secondary btn-secondary">Add Cryptoasset</button>
                                    </div>
                                </div>
                                 {/* -----------------------------------------------------------------------------hidden sections----------------------------------------------------- */}
                                {/* <div className="sub_item">
                                    <div className="sub_item_list">
                                        <span className="index">2</span><h1>Launchpad</h1>
                                    </div>
                                    <div className="sub_item_del">
                                        <p>     Lorem ipsum dolor sit amet. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi utaliquip ex ea commodo consequat. Duis aute irure dolor in
                                        </p>
                                    </div>
                                    <div className="sub_item_btn">
                                        <button type="button" className="btn btn-outline-secondary btn-secondary">Add Launchpad</button>
                                    </div>
                                </div> */}
                                <div className="sub_item">
                                    <div className="sub_item_list">
                                        <span className="index">2</span><h1>Exchange </h1>
                                    </div>
                                    <div className="sub_item_del">
                                        <p>     Lorem ipsum dolor sit amet. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi utaliquip ex ea commodo consequat. Duis aute irure dolor in
                                        </p>
                                    </div>
                                    <div className="sub_item_btn">
                                        <button type="button" className="btn btn-outline-secondary btn-secondary">Add Exchange</button>
                                    </div>
                                </div>

                                 {/* -----------------------------------------------------------------------------hidden sections----------------------------------------------------- */}
                                {/* <div className="sub_item">
                                    <div className="sub_item_list">
                                        <span className="index">4</span><h1>NFT Platform</h1>
                                    </div>
                                    <div className="sub_item_del">
                                        <p>     Lorem ipsum dolor sit amet. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi utaliquip ex ea commodo consequat. Duis aute irure dolor in
                                        </p>
                                    </div>
                                    <div className="sub_item_btn">
                                        <button type="button" className="btn btn-outline-secondary btn-secondary">Coming Soon</button>
                                    </div>
                                </div> */}
                                <div className="sub_item">
                                    <div className="sub_item_list">
                                        <span className="index">3</span><h1>Advertise</h1>
                                    </div>
                                    <div className="sub_item_del">
                                        <p>     Lorem ipsum dolor sit amet. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi utaliquip ex ea commodo consequat. Duis aute irure dolor in
                                        </p>
                                    </div>
                                    <div className="sub_item_btn sub_ad_btn">
                                        <button type="button" className="btn btn-outline-secondary btn-secondary ad_grade_color"><span class="">Advertise</span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </main>
        </>
    )
}