import Header from "../components/Header";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import pandcoin from '../assets/images/pand.png';
import slideimg from '../assets/images/img1.jpg';
import heep1 from '../assets/images/Bubble.svg';
import overlayimg1 from '../assets/images/overlayimg1.jpg';
import img2 from '../assets/images/img2.jpg';
import key from '../assets/images/key.png';
import oreocoin from '../assets/images/oreo.png';

import { useEffect, useState } from 'react';
import { Loader } from '../components/Loader';
import { LOADER_TIMER } from '../constants';
import FloatingSearch from '../components/FloatingSearch';
import JustifiedExample from "../components/TabsCommon";

export const Home = () => {
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        console.log(loader, "Loader");
        const timeout = setTimeout(() => {

            setLoader(false);
        }, LOADER_TIMER)
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [])
    return (
        <>
            <Loader show={loader} />
            <main>

                <Header />
                <Banner />
                <div className="container">
                     {/* -----------------------------------------------------------------------------hidden sections----------------------------------------------------- */}
                    {/* <section className="coin_section">


                        <div className='prom_grids'>
                            <div className="built_maker_heep_right coinpage_head  mt-0">
                                <h3 className='pt-0'>Promoted Launchpads</h3>
                                <h6>Discover All Launchpads</h6>
                            </div>
                            <div className="row">
                                <div className="col-md-6 pe-0">
                                    <ul className="grid-sites-last mb-0">
                                        <li>
                                            <a href="https://www.pinksale.finance/launchpads">
                                                <div className="card-site-last">
                                                    <figure className="card-site-last__figure  mb-0">
                                                        <img src={pinksale} />
                                                    </figure>
                                                    <div className="card-site-last__info">
                                                        <span className="sale_live">Sale Live</span>
                                                        <div className="row mt-5">
                                                            <div className="col-2"></div>
                                                            <div className="col-10 d-flex justify-content-end">
                                                                <span className="cat_tag bg-green ms-2">KYC</span>
                                                                <span className="cat_tag bg-pink">Audit</span>
                                                            </div>
                                                        </div>
                                                        <div className="card-site-last__top d-none">
                                                            <div>Dec 25, 2022</div>
                                                        </div>
                                                        <div className="card-site-last__bottom  pull-top-bottom">
                                                            <h3 className="card-site-last__title d-none">Plastic</h3>
                                                            <div className="card-site-last__by">
                                                                <small className='d-none'>by</small>
                                                                <strong className="text-medium">
                                                                    <div className="users-credits ">
                                                                        <div className="banner_lower_text mt-1 mb-4">
                                                                            <div className="banner_lower_img"><a><img src='https://www.shibainu.gold/logotype.png' /></a></div>
                                                                            <div className="banner_lower_imgtext"><a className="link_underline no-before">Shiba Inu Gold</a></div>
                                                                        </div>

                                                                    </div>

                                                                </strong>
                                                            </div>
                                                            <div className="c-boxes-score">
                                                                <div className="box-score box-score--small">
                                                                    <div className="box-score__top">
                                                                        <strong>CHAIN</strong>
                                                                    </div>
                                                                    <div className="box-score__bottom">
                                                                        <strong>BNB</strong>
                                                                    </div>
                                                                </div>
                                                                <div className="box-score box-score--small">
                                                                    <div className="box-score__top">
                                                                        <strong>SOFTGAP</strong>
                                                                    </div>
                                                                    <div className="box-score__bottom">
                                                                        <strong>675</strong>
                                                                    </div>
                                                                </div>
                                                                <div className="box-score box-score--small">
                                                                    <div className="box-score__top">
                                                                        <strong>HARDGAP</strong>
                                                                    </div>
                                                                    <div className="box-score__bottom">
                                                                        <strong>1,350</strong>
                                                                    </div>
                                                                </div>
                                                                <div className="box-score box-score--small">
                                                                    <div className="box-score__top">
                                                                        <strong>LOCKUP TIME</strong>
                                                                    </div>
                                                                    <div className="box-score__bottom">
                                                                        <strong>365 days</strong>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6 ps-0">
                                    <ul className="grid-sites-last mb-0">
                                        <li>
                                            <a href="https://www.pinksale.finance/launchpads">
                                                <div className="card-site-last">
                                                    <figure className="card-site-last__figure  mb-0">
                                                        <img src={pinksale} />
                                                    </figure>
                                                    <div className="card-site-last__info">
                                                        <span className="sale_live">Sale Live</span>
                                                        <div className="row mt-5">
                                                            <div className="col-2"></div>
                                                            <div className="col-10 d-flex justify-content-end">
                                                                <span className="cat_tag bg-green ms-2">KYC</span>
                                                                <span className="cat_tag bg-pink">Audit</span>
                                                            </div>
                                                        </div>
                                                        <div className="card-site-last__top d-none">
                                                            <div>Dec 25, 2022</div>
                                                        </div>
                                                        <div className="card-site-last__bottom  pull-top-bottom">
                                                            <h3 className="card-site-last__title d-none">Plastic</h3>
                                                            <div className="card-site-last__by">
                                                                <small className='d-none'>by</small>
                                                                <strong className="text-medium">
                                                                    <div className="users-credits ">
                                                                        <div className="banner_lower_text mt-1 mb-4">
                                                                            <div className="banner_lower_img"><a><img src='https://www.shibainu.gold/logotype.png' /></a></div>
                                                                            <div className="banner_lower_imgtext"><a className="link_underline no-before">Shiba Inu Gold</a></div>
                                                                        </div>

                                                                    </div>

                                                                </strong>
                                                            </div>
                                                            <div className="c-boxes-score">
                                                                <div className="box-score box-score--small">
                                                                    <div className="box-score__top">
                                                                        <strong>CHAIN</strong>
                                                                    </div>
                                                                    <div className="box-score__bottom">
                                                                        <strong>BNB</strong>
                                                                    </div>
                                                                </div>
                                                                <div className="box-score box-score--small">
                                                                    <div className="box-score__top">
                                                                        <strong>SOFTGAP</strong>
                                                                    </div>
                                                                    <div className="box-score__bottom">
                                                                        <strong>675</strong>
                                                                    </div>
                                                                </div>
                                                                <div className="box-score box-score--small">
                                                                    <div className="box-score__top">
                                                                        <strong>HARDGAP</strong>
                                                                    </div>
                                                                    <div className="box-score__bottom">
                                                                        <strong>1,350</strong>
                                                                    </div>
                                                                </div>
                                                                <div className="box-score box-score--small">
                                                                    <div className="box-score__top">
                                                                        <strong>LOCKUP TIME</strong>
                                                                    </div>
                                                                    <div className="box-score__bottom">
                                                                        <strong>365 days</strong>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6 pe-0">
                                    <ul className="grid-sites-last mb-0">
                                        <li>
                                            <a href="https://www.pinksale.finance/launchpads">
                                                <div className="card-site-last">
                                                    <figure className="card-site-last__figure  mb-0">
                                                        <img src={pinksale} />
                                                    </figure>
                                                    <div className="card-site-last__info">
                                                        <span className="sale_live">Sale Live</span>
                                                        <div className="row mt-5">
                                                            <div className="col-2"></div>
                                                            <div className="col-10 d-flex justify-content-end">
                                                                <span className="cat_tag bg-green ms-2">KYC</span>
                                                                <span className="cat_tag bg-pink">Audit</span>
                                                            </div>
                                                        </div>
                                                        <div className="card-site-last__top d-none">
                                                            <div>Dec 25, 2022</div>
                                                        </div>
                                                        <div className="card-site-last__bottom  pull-top-bottom">
                                                            <h3 className="card-site-last__title d-none">Plastic</h3>
                                                            <div className="card-site-last__by">
                                                                <small className='d-none'>by</small>
                                                                <strong className="text-medium">
                                                                    <div className="users-credits ">
                                                                        <div className="banner_lower_text mt-1 mb-4">
                                                                            <div className="banner_lower_img"><a><img src='https://www.shibainu.gold/logotype.png' /></a></div>
                                                                            <div className="banner_lower_imgtext"><a className="link_underline no-before">Shiba Inu Gold</a></div>
                                                                        </div>

                                                                    </div>

                                                                </strong>
                                                            </div>
                                                            <div className="c-boxes-score">
                                                                <div className="box-score box-score--small">
                                                                    <div className="box-score__top">
                                                                        <strong>CHAIN</strong>
                                                                    </div>
                                                                    <div className="box-score__bottom">
                                                                        <strong>BNB</strong>
                                                                    </div>
                                                                </div>
                                                                <div className="box-score box-score--small">
                                                                    <div className="box-score__top">
                                                                        <strong>SOFTGAP</strong>
                                                                    </div>
                                                                    <div className="box-score__bottom">
                                                                        <strong>675</strong>
                                                                    </div>
                                                                </div>
                                                                <div className="box-score box-score--small">
                                                                    <div className="box-score__top">
                                                                        <strong>HARDGAP</strong>
                                                                    </div>
                                                                    <div className="box-score__bottom">
                                                                        <strong>1,350</strong>
                                                                    </div>
                                                                </div>
                                                                <div className="box-score box-score--small">
                                                                    <div className="box-score__top">
                                                                        <strong>LOCKUP TIME</strong>
                                                                    </div>
                                                                    <div className="box-score__bottom">
                                                                        <strong>365 days</strong>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="col-md-6 ps-0">
                                    <ul className="grid-sites-last mb-0">
                                        <li>
                                            <a href="https://www.pinksale.finance/launchpads">
                                                <div className="card-site-last">
                                                    <figure className="card-site-last__figure  mb-0">
                                                        <img src={pinksale} />
                                                    </figure>
                                                    <div className="card-site-last__info">
                                                        <span className="sale_live">Sale Live</span>
                                                        <div className="row mt-5">
                                                            <div className="col-2"></div>
                                                            <div className="col-10 d-flex justify-content-end">
                                                                <span className="cat_tag bg-green ms-2">KYC</span>
                                                                <span className="cat_tag bg-pink">Audit</span>
                                                            </div>
                                                        </div>
                                                        <div className="card-site-last__top d-none">
                                                            <div>Dec 25, 2022</div>
                                                        </div>
                                                        <div className="card-site-last__bottom  pull-top-bottom">
                                                            <h3 className="card-site-last__title d-none">Plastic</h3>
                                                            <div className="card-site-last__by">
                                                                <small className='d-none'>by</small>
                                                                <strong className="text-medium">
                                                                    <div className="users-credits ">
                                                                        <div className="banner_lower_text mt-1 mb-4">
                                                                            <div className="banner_lower_img"><a><img src='https://www.shibainu.gold/logotype.png' /></a></div>
                                                                            <div className="banner_lower_imgtext"><a className="link_underline no-before">Shiba Inu Gold</a></div>
                                                                        </div>

                                                                    </div>

                                                                </strong>
                                                            </div>
                                                            <div className="c-boxes-score">
                                                                <div className="box-score box-score--small">
                                                                    <div className="box-score__top">
                                                                        <strong>CHAIN</strong>
                                                                    </div>
                                                                    <div className="box-score__bottom">
                                                                        <strong>BNB</strong>
                                                                    </div>
                                                                </div>
                                                                <div className="box-score box-score--small">
                                                                    <div className="box-score__top">
                                                                        <strong>SOFTGAP</strong>
                                                                    </div>
                                                                    <div className="box-score__bottom">
                                                                        <strong>675</strong>
                                                                    </div>
                                                                </div>
                                                                <div className="box-score box-score--small">
                                                                    <div className="box-score__top">
                                                                        <strong>HARDGAP</strong>
                                                                    </div>
                                                                    <div className="box-score__bottom">
                                                                        <strong>1,350</strong>
                                                                    </div>
                                                                </div>
                                                                <div className="box-score box-score--small">
                                                                    <div className="box-score__top">
                                                                        <strong>LOCKUP TIME</strong>
                                                                    </div>
                                                                    <div className="box-score__bottom">
                                                                        <strong>365 days</strong>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section> */}

                    <section className="built_maker_heep new_bc">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="built_maker_heep_right">

                                    <div className="content-tabs p-20">
                                        <div class="coinpage_head"><h3>Coins</h3><h6>Discover our cryptocurrencies directory </h6></div>
                                        <div class="mb-3">
                                        <JustifiedExample/>
                                        </div>
                                        {/* <div className='Cointabsdata'></div>
                                        <div className='table-responsive'>
                                            <table className="table table-control">
                                                <thead>
                                                    <tr>
                                                        <th colspan="6" width="25%">Coin Name</th>
                                                        <th scope="col" width="8%">Chain <svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                                                            <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                                                            <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                                                        </svg></th>
                                                                                                                <th scope="col" width="15%">Price <svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                                                            <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                                                            <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                                                        </svg></th>
                                                        <th scope="col" width="15%">24H <svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                                                            <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                                                            <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                                                        </svg></th>
                                                        <th scope="col" width="15%">Market Cap <svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                                                            <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                                                            <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                                                        </svg></th>

                                                        <th scope="col" width="15%">Age <svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                                                            <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                                                            <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                                                        </svg></th>
                                                        <th scope="col" width="8%" className="text-center">Vote <svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                                                            <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                                                            <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                                                        </svg></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colspan="6">
                                                            <div className="listing_coin_detail">
                                                                <div className="coin_logo"><img src={pandcoin} /></div>
                                                                <div className="list_detail">
                                                                    <div class="info">
                                                                        <div><a href="#"><strong></strong></a><strong>PandaSale</strong></div>
                                                                        <div class="hidden-sm">Archive</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><div className='coin_box'><div className="coinimg"><div className="coinlogo"><img src={bsccoin} /></div><span className="ps-2">BSC</span></div></div></td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>4 months ago </td>
                                                        <td className="text-center vote_drpdwn_btn">   <Dropdown>
                                                            <Dropdown.Toggle variant="btn" id="dropdown-basic" className="new-bg">
                                                                <svg width="47" height="137" viewBox="0 0 47 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M20.2367 13.0401L4.77261 29.5754L0 25.1268L23.5 0C31.332 8.37562 39.168 16.7512 47 25.1268L42.2274 29.5754L26.7633 13.0401V123.96L42.2274 107.425L47 111.873L23.5 137L0 111.873L4.77261 107.425L20.2367 123.96V13.0401Z" fill="white" />
                                                                </svg>
                                                                <span>50958</span></Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#/action-1">Up</Dropdown.Item>
                                                                <Dropdown.Item href="#/action-2"> Down</Dropdown.Item>

                                                            </Dropdown.Menu>
                                                        </Dropdown></td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="6">
                                                            <div className="listing_coin_detail">
                                                                <div className="coin_logo"><img src={oreocoin} /></div>
                                                                <div className="list_detail">
                                                                    <div class="info">
                                                                        <div><a href="#"><strong></strong></a><strong>OreoSwap</strong></div>
                                                                        <div class="hidden-sm">DeFi</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><div className='coin_box'><div className="coinimg"><div className="coinlogo"><img src={bsccoin} /></div><span className="ps-2">AETH</span></div></div></td>

                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>7 days ago</td>
                                                        <td className="text-center vote_drpdwn_btn">   <Dropdown>
                                                            <Dropdown.Toggle variant="btn" id="dropdown-basic" className="new-bg">
                                                                <svg width="47" height="137" viewBox="0 0 47 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M20.2367 13.0401L4.77261 29.5754L0 25.1268L23.5 0C31.332 8.37562 39.168 16.7512 47 25.1268L42.2274 29.5754L26.7633 13.0401V123.96L42.2274 107.425L47 111.873L23.5 137L0 111.873L4.77261 107.425L20.2367 123.96V13.0401Z" fill="white" />
                                                                </svg>
                                                                <span>50958</span></Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#/action-1">Up</Dropdown.Item>
                                                                <Dropdown.Item href="#/action-2"> Down</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown></td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="6">
                                                            <div className="listing_coin_detail">
                                                                <div className="coin_logo"><img src={gencoin} /></div>
                                                                <div className="list_detail">
                                                                    <div class="info">
                                                                        <div><a href="#"><strong></strong></a><strong>GenTools</strong></div>
                                                                        <div class="hidden-sm">Metaverse </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><div className='coin_box'><div className="coinimg"><div className="coinlogo"><img src={bsccoin} /></div><span className="ps-2">BSC</span></div></div></td>
                                                        <td className="green">$ 0.000099</td>
                                                        <td><span className="red-text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="red" viewBox="0 0 16 16"><path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"></path></svg>0.89%</span></td>
                                                        <td>$ 9,902</td>
                                                        <td>13 days ago</td>
                                                        <td className="text-center vote_drpdwn_btn">   <Dropdown>
                                                            <Dropdown.Toggle variant="btn" id="dropdown-basic" className="new-bg"><svg width="47" height="137" viewBox="0 0 47 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M20.2367 13.0401L4.77261 29.5754L0 25.1268L23.5 0C31.332 8.37562 39.168 16.7512 47 25.1268L42.2274 29.5754L26.7633 13.0401V123.96L42.2274 107.425L47 111.873L23.5 137L0 111.873L4.77261 107.425L20.2367 123.96V13.0401Z" fill="white" />
                                                            </svg><span>50958</span></Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#/action-1">Up</Dropdown.Item>
                                                                <Dropdown.Item href="#/action-2"> Down</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown></td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="6">
                                                            <div className="listing_coin_detail">
                                                                <div className="coin_logo"><img src={pandcoin} /></div>
                                                                <div className="list_detail">
                                                                    <div class="info">
                                                                        <div><a href="#"><strong></strong></a><strong>Infinity Rise</strong></div>
                                                                        <div class="hidden-sm">DeFi</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><div className='coin_box'><div className="coinimg"><div className="coinlogo"><img src={bsccoin} /></div><span className="ps-2">BSC</span></div></div></td>
                                                        <td className="green">$ 0.0</td>
                                                        <td>-</td>
                                                        <td>$ 121</td>
                                                        <td>8 days ago</td>
                                                        <td className="text-center vote_drpdwn_btn">   <Dropdown>
                                                            <Dropdown.Toggle variant="btn" id="dropdown-basic" className="new-bg"><svg width="47" height="137" viewBox="0 0 47 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M20.2367 13.0401L4.77261 29.5754L0 25.1268L23.5 0C31.332 8.37562 39.168 16.7512 47 25.1268L42.2274 29.5754L26.7633 13.0401V123.96L42.2274 107.425L47 111.873L23.5 137L0 111.873L4.77261 107.425L20.2367 123.96V13.0401Z" fill="white" />
                                                            </svg><span>50958</span></Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#/action-1">Up</Dropdown.Item>
                                                                <Dropdown.Item href="#/action-2"> Down</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown></td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="6">
                                                            <div className="listing_coin_detail">
                                                                <div className="coin_logo"><img src={pandcoin} /></div>
                                                                <div className="list_detail">
                                                                    <div class="info">
                                                                        <div><a href="#"><strong></strong></a><strong>Santa Gate</strong></div>
                                                                        <div class="hidden-sm">Meme</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><div className='coin_box'><div className="coinimg"><div className="coinlogo"><img src={bsccoin} /></div><span className="ps-2">BSC</span></div></div></td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>6 days ago</td>
                                                        <td className="text-center vote_drpdwn_btn">   <Dropdown>
                                                            <Dropdown.Toggle variant="btn" id="dropdown-basic" className="new-bg"><svg width="47" height="137" viewBox="0 0 47 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M20.2367 13.0401L4.77261 29.5754L0 25.1268L23.5 0C31.332 8.37562 39.168 16.7512 47 25.1268L42.2274 29.5754L26.7633 13.0401V123.96L42.2274 107.425L47 111.873L23.5 137L0 111.873L4.77261 107.425L20.2367 123.96V13.0401Z" fill="white" />
                                                            </svg><span>50958</span></Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#/action-1">Up</Dropdown.Item>
                                                                <Dropdown.Item href="#/action-2"> Down</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown></td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="6">
                                                            <div className="listing_coin_detail">
                                                                <div className="coin_logo"><img src={pandcoin} /></div>
                                                                <div className="list_detail">
                                                                    <div class="info">
                                                                        <div><a href="#"><strong></strong></a><strong>Vanilla DAO</strong></div>
                                                                        <div class="hidden-sm">Other</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><div className='coin_box'><div className="coinimg"><div className="coinlogo"><img src={bsccoin} /></div><span className="ps-2">BSC</span></div></div></td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>in 5 days</td>
                                                        <td className="text-center vote_drpdwn_btn">   <Dropdown>
                                                            <Dropdown.Toggle variant="btn" id="dropdown-basic" className="new-bg"><svg width="47" height="137" viewBox="0 0 47 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M20.2367 13.0401L4.77261 29.5754L0 25.1268L23.5 0C31.332 8.37562 39.168 16.7512 47 25.1268L42.2274 29.5754L26.7633 13.0401V123.96L42.2274 107.425L47 111.873L23.5 137L0 111.873L4.77261 107.425L20.2367 123.96V13.0401Z" fill="white" />
                                                            </svg><span>850958</span></Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#/action-1">Up</Dropdown.Item>
                                                                <Dropdown.Item href="#/action-2"> Down</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown></td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="6">
                                                            <div className="listing_coin_detail">
                                                                <div className="coin_logo"><img src={pandcoin} /></div>
                                                                <div className="list_detail">
                                                                    <div class="info">
                                                                        <div><a href="#"><strong></strong></a><strong>Paycoin</strong></div>
                                                                        <div class="hidden-sm">DeFi</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><div className='coin_box'><div className="coinimg"><div className="coinlogo"><img src={bsccoin} /></div><span className="ps-2">BSC</span></div></div></td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>Presale</td>
                                                        <td>in 5 months</td>
                                                        <td className="text-center vote_drpdwn_btn">   <Dropdown>
                                                            <Dropdown.Toggle variant="btn" id="dropdown-basic" className="new-bg"><svg width="47" height="137" viewBox="0 0 47 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M20.2367 13.0401L4.77261 29.5754L0 25.1268L23.5 0C31.332 8.37562 39.168 16.7512 47 25.1268L42.2274 29.5754L26.7633 13.0401V123.96L42.2274 107.425L47 111.873L23.5 137L0 111.873L4.77261 107.425L20.2367 123.96V13.0401Z" fill="white" />
                                                            </svg><span>10258</span></Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#/action-1">Up</Dropdown.Item>
                                                                <Dropdown.Item href="#/action-2"> Down</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown></td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="6">
                                                            <div className="listing_coin_detail">
                                                                <div className="coin_logo"><img src={oreocoin} /></div>
                                                                <div className="list_detail">
                                                                    <div class="info">
                                                                        <div><a href="#"><strong></strong></a><strong>OreoSwap</strong></div>
                                                                        <div class="hidden-sm">DeFi</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><div className='coin_box'><div className="coinimg"><div className="coinlogo"><img src={bsccoin} /></div><span className="ps-2">AETH</span></div></div></td>

                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>-</td>
                                                        <td>7 days ago</td>
                                                        <td className="text-center vote_drpdwn_btn">   <Dropdown>
                                                            <Dropdown.Toggle variant="btn" id="dropdown-basic" className="new-bg">
                                                                <svg width="47" height="137" viewBox="0 0 47 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M20.2367 13.0401L4.77261 29.5754L0 25.1268L23.5 0C31.332 8.37562 39.168 16.7512 47 25.1268L42.2274 29.5754L26.7633 13.0401V123.96L42.2274 107.425L47 111.873L23.5 137L0 111.873L4.77261 107.425L20.2367 123.96V13.0401Z" fill="white" />
                                                                </svg>
                                                                <span>50958</span></Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#/action-1">Up</Dropdown.Item>
                                                                <Dropdown.Item href="#/action-2"> Down</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown></td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="6">
                                                            <div className="listing_coin_detail">
                                                                <div className="coin_logo"><img src={gencoin} /></div>
                                                                <div className="list_detail">
                                                                    <div class="info">
                                                                        <div><a href="#"><strong></strong></a><strong>GenTools</strong></div>
                                                                        <div class="hidden-sm">Metaverse </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><div className='coin_box'><div className="coinimg"><div className="coinlogo"><img src={bsccoin} /></div><span className="ps-2">BSC</span></div></div></td>
                                                        <td className="green">$ 0.000099</td>
                                                        <td><span className="red-text"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="red" viewBox="0 0 16 16"><path d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"></path></svg>0.89%</span></td>
                                                        <td>$ 9,902</td>
                                                        <td>13 days ago</td>
                                                        <td className="text-center vote_drpdwn_btn">   <Dropdown>
                                                            <Dropdown.Toggle variant="btn" id="dropdown-basic" className="new-bg"><svg width="47" height="137" viewBox="0 0 47 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M20.2367 13.0401L4.77261 29.5754L0 25.1268L23.5 0C31.332 8.37562 39.168 16.7512 47 25.1268L42.2274 29.5754L26.7633 13.0401V123.96L42.2274 107.425L47 111.873L23.5 137L0 111.873L4.77261 107.425L20.2367 123.96V13.0401Z" fill="white" />
                                                            </svg><span>50958</span></Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#/action-1">Up</Dropdown.Item>
                                                                <Dropdown.Item href="#/action-2"> Down</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown></td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="6">
                                                            <div className="listing_coin_detail">
                                                                <div className="coin_logo"><img src={pandcoin} /></div>
                                                                <div className="list_detail">
                                                                    <div class="info">
                                                                        <div><a href="#"><strong></strong></a><strong>Infinity Rise</strong></div>
                                                                        <div class="hidden-sm">DeFi</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><div className='coin_box'><div className="coinimg"><div className="coinlogo"><img src={bsccoin} /></div><span className="ps-2">BSC</span></div></div></td>
                                                        <td className="green">$ 0.0</td>
                                                        <td>-</td>
                                                        <td>$ 121</td>
                                                        <td>8 days ago</td>
                                                        <td className="text-center vote_drpdwn_btn">   <Dropdown>
                                                            <Dropdown.Toggle variant="btn" id="dropdown-basic" className="new-bg"><svg width="47" height="137" viewBox="0 0 47 137" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M20.2367 13.0401L4.77261 29.5754L0 25.1268L23.5 0C31.332 8.37562 39.168 16.7512 47 25.1268L42.2274 29.5754L26.7633 13.0401V123.96L42.2274 107.425L47 111.873L23.5 137L0 111.873L4.77261 107.425L20.2367 123.96V13.0401Z" fill="white" />
                                                            </svg><span>50958</span></Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#/action-1">Up</Dropdown.Item>
                                                                <Dropdown.Item href="#/action-2"> Down</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div> */}
                                        {/* <div className="table_pagination">
                                            <div aria-label="Page navigation example">
                                                <ul class="pagination">
                                                    <li class="page-item">
                                                        <a class="page-link" href="#" aria-label="Previous">
                                                            <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                                            </svg></span>
                                                            <span class="sr-only"></span>
                                                        </a>
                                                    </li>
                                                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                    <li class="page-item">
                                                        <a class="page-link" href="#" aria-label="Next">
                                                            <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                                            </svg></span>
                                                            <span class="sr-only"></span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div> */}
                                        <div class="more_proj dis_btn"><a href="https://futur3.app/coinpage"><button class="btn btn-outline-secondary btn-secondary">Show More</button></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="built_maker_heep ">
                        <div className="row">
                            <div className="col-md-12">
                                <div className=" built_maker_heep_right">
                                    <h1>
                                        Exchanges
                                    </h1>
                                    <h6>Discover our Exchanges directory </h6>
                                    <div className="built_maker_right table-responsive">
                                        <table className='table exchange_table table-control'>
                                            <thead>
                                                <tr>
                                                    <th width="22%">LAUNCHPAD NAME</th>
                                                    <th width="13%">24H VOLUME<svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                                                        <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                                                        <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                                                    </svg></th>
                                                    <th width="13%">VOL SHARE<svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                                                        <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                                                        <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                                                    </svg></th>
                                                    <th width="13%">MARKETS<svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                                                        <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                                                        <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                                                    </svg></th>
                                                    <th width="13%">LIQUIDITY ±2%<svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                                                        <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                                                        <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                                                    </svg></th>
                                                    <th width="13%">Bids -2%<svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                                                        <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                                                        <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                                                    </svg></th>
                                                    <th width="13%">Asks +2%<svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                                                        <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                                                        <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                                                    </svg> </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr><td> <div className="built_maker_inner_leftdetail">
                                                    <img src={pandcoin} />
                                                    <div>
                                                        <b>ActiveCampaign</b>
                                                    </div>
                                                </div></td>
                                                    <td><b>452.97 M</b></td>
                                                    <td><b>2.07%</b></td>
                                                    <td><b>168</b></td>
                                                    <td><b>352.97 M</b></td>
                                                    <td><b>$752.97 M</b></td>
                                                    <td><b>$952.97 M</b></td>
                                                </tr>
                                                <tr><td> <div className="built_maker_inner_leftdetail">
                                                    <img src={oreocoin} />
                                                    <div>
                                                        <b>OreoSwap</b>
                                                    </div>
                                                </div></td>
                                                    <td><b>452.97 M</b></td>
                                                    <td><b>2.07%</b></td>
                                                    <td><b>168</b></td>
                                                    <td><b>352.97 M</b></td>
                                                    <td><b>$352.97 M</b></td>
                                                    <td><b>$652.97 M</b></td>
                                                </tr>
                                                <tr><td> <div className="built_maker_inner_leftdetail">
                                                    <img src={pandcoin} />
                                                    <div>
                                                        <b>PandaSale</b>
                                                    </div>
                                                </div></td>
                                                    <td><b>452.97 M</b></td>
                                                    <td><b>2.07%</b></td>
                                                    <td><b>168</b></td>
                                                    <td><b>352.97 M</b></td>
                                                    <td><b>$452.97 M</b></td>
                                                    <td><b>$452.97 M</b></td>
                                                </tr>
                                                <tr><td> <div className="built_maker_inner_leftdetail">
                                                    <img src={oreocoin} />
                                                    <div>
                                                        <b>OreoSwap</b>
                                                    </div>
                                                </div></td>
                                                    <td><b>452.97 M</b></td>
                                                    <td><b>2.07%</b></td>
                                                    <td><b>168</b></td>
                                                    <td><b>352.97 M</b></td>
                                                    <td><b>$452.97 M</b></td>
                                                    <td><b>$452.97 M</b></td>
                                                </tr>
                                                <tr><td> <div className="built_maker_inner_leftdetail">
                                                    <img src={pandcoin} />
                                                    <div>
                                                        <b>ActiveCampaign</b>
                                                    </div>
                                                </div></td>
                                                    <td><b>452.97 M</b></td>
                                                    <td><b>2.07%</b></td>
                                                    <td><b>168</b></td>
                                                    <td><b>352.97 M</b></td>
                                                    <td><b>$452.97 M</b></td>
                                                    <td><b>$452.97 M</b></td>
                                                </tr>
                                                <tr><td> <div className="built_maker_inner_leftdetail">
                                                    <img src={heep1} />
                                                    <div>
                                                        <b>PandaSale</b>
                                                    </div>
                                                </div></td>
                                                    <td><b>452.97 M</b></td>
                                                    <td><b>2.07%</b></td>
                                                    <td><b>168</b></td>
                                                    <td><b>352.97 M</b></td>
                                                    <td><b>$352.97 M</b></td>
                                                    <td><b>$652.97 M</b></td>
                                                </tr>
                                                <tr><td> <div className="built_maker_inner_leftdetail">
                                                    <img src={pandcoin} />
                                                    <div>
                                                        <b>ActiveCampaign</b>
                                                    </div>
                                                </div></td>
                                                    <td><b>452.97 M</b></td>
                                                    <td><b>2.07%</b></td>
                                                    <td><b>168</b></td>
                                                    <td><b>352.97 M</b></td>
                                                    <td><b>$352.97 M</b></td>
                                                    <td><b>$852.97 M</b></td>
                                                </tr>
                                                <tr><td> <div className="built_maker_inner_leftdetail">
                                                    <img src={oreocoin} />
                                                    <div>
                                                        <b>OreoSwap</b>
                                                    </div>
                                                </div></td>
                                                    <td><b>452.97 M</b></td>
                                                    <td><b>2.07%</b></td>
                                                    <td><b>168</b></td>
                                                    <td><b>352.97 M</b></td>
                                                    <td><b>$152.97 M</b></td>
                                                    <td><b>$852.97 M</b></td>
                                                </tr>
                                                <tr><td> <div className="built_maker_inner_leftdetail">
                                                    <img src={pandcoin} />
                                                    <div>
                                                        <b>PandaSale</b>
                                                    </div>
                                                </div></td>
                                                    <td><b>452.97 M</b></td>
                                                    <td><b>2.07%</b></td>
                                                    <td><b>168</b></td>
                                                    <td><b>352.97 M</b></td>
                                                    <td><b>$652.97 M</b></td>
                                                    <td><b>$352.97 M</b></td>
                                                </tr>
                                                <tr><td> <div className="built_maker_inner_leftdetail">
                                                    <img src={oreocoin} />
                                                    <div>
                                                        <b>OreoSwap</b>
                                                    </div>
                                                </div></td>
                                                    <td><b>452.97 M</b></td>
                                                    <td><b>2.07%</b></td>
                                                    <td><b>168</b></td>
                                                    <td><b>352.97 M</b></td>
                                                    <td><b>$452.97 M</b></td>
                                                    <td><b>$752.97 M</b></td>
                                                </tr>

                                            </tbody>
                                        </table>

                                    </div>
                                    <div class="more_proj dis_btn"><a href="https://futur3.app/exchanges"><button class="btn btn-outline-secondary btn-secondary">Discover More</button></a></div>
                                </div>
                            </div>



                        </div>
                    </section>

                    <div className="heep_sec_detail heep_sec_detail2">
                        <div className="row">
                            <div className="col-xl-3">
                                <div class="heep_sec_detail2_left"><h3>Blog <br /><span> Crypto News</span></h3><p>Browse the best premium and lastest crypto news</p></div>

                            </div>
                            <div className="col-xl-9">
                                <div className="heep_sec_detail2_right">
                                    <div className="row">
                                        <div className="col-md-6 col-lg-4 col-xl-4">
                                            <div className="heep_detail2_right_inner">
                                                <div className="heep_Del_left">
                                                    <div className='d-flex align-items-center'>
                                                        <div className="heep_detail2_logo">
                                                            <img src={pandcoin} />
                                                        </div>

                                                        <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                    </div>
                                                    <div><span className="cat_tag">Category</span></div>
                                                </div>
                                                <div className="heep_detail2_text pt-3">
                                                    <div className="heep_img_new">
                                                        <img src={overlayimg1} /></div>
                                                    <h3 className="heep_top_padding">Build a web app wide Lorem Ipsum has been the industry</h3>
                                                    <p className="mt-1">
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-4 col-xl-4">
                                            <div className="heep_detail2_right_inner">
                                                <div className="heep_Del_left">
                                                    <div className='d-flex align-items-center'>
                                                        <div className="heep_detail2_logo">
                                                            <img src={oreocoin} />
                                                        </div>

                                                        <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                    </div>
                                                    <div><span className="cat_tag">Category</span></div>
                                                </div>
                                                <div className="heep_detail2_text pt-3">
                                                    <div className="heep_img_new">
                                                        <img src={overlayimg1} /></div>
                                                    <h3 className="heep_top_padding">Modern website Lorem Ipsum has been the industry</h3>
                                                    <p className="mt-1">
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-4 col-xl-4">
                                            <div className="heep_detail2_right_inner">
                                                <div className="heep_Del_left">
                                                    <div className='d-flex align-items-center'>
                                                        <div className="heep_detail2_logo">
                                                            <img src={pandcoin} />
                                                        </div>

                                                        <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                    </div>
                                                    <div><span className="cat_tag">Category</span></div>
                                                </div>
                                                <div className="heep_detail2_text pt-3">
                                                    <div className="heep_img_new">
                                                        <img src={overlayimg1} /></div>
                                                    <h3 className="heep_top_padding">Set-up automations Lorem Ipsum has been the industry text of the printing</h3>
                                                    <p className="mt-1">
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                    </p>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div class="more_proj dis_btn"><a href='https://futur3.app/blog'><button class="btn btn-outline-secondary btn-secondary">Discover More</button></a></div>
                    </div>
                    


                    {/* -----------------------------------------------------------------------------hidden sections----------------------------------------------------- */}
                    <div className="col-md-12 d-none">
                        <div className="built_maker_heep_detail built_maker_heep_left row">
                            <div className='col-sm-6'>
                                <div className="built_maker_heep_header">
                                    <h6>Build By Makers on Heep</h6>
                                    <h3>Our experts can design and build<br /> apps, automate workflows, <br />integrate any software or create <br />stunning websites </h3>
                                </div></div>
                            <div className='col-sm-6'>
                                <div className="built_maker_heep_inner">
                                    <div className="built_maker_heep_overlyimg">
                                        <img src={overlayimg1} />
                                    </div>
                                    <div className="built_maker_heep_overlyimg">
                                        <img src={slideimg} />
                                    </div>
                                    <div className="built_maker_heep_overlyimg">
                                        <img src={img2} />
                                    </div>
                                </div></div>
                            <div className="more_proj">
                                <h5><a>More Projects</a></h5>
                            </div>
                        </div>

                    </div>

                    <div className="heep_sec_detail mt-3 d-none">
                        <div className="row">
                            <div className="col-sm-7">
                                <div className="heep_inner_left">
                                    <div className="heep_left_top">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="12" cy="12" r="12" fill="white" fill-opacity="0.4" />
                                            <circle cx="12" cy="12" r="10.875" fill="white" fill-opacity="0.4" />
                                            <ellipse cx="12" cy="12" rx="8.625" ry="8.625" fill="white" fill-opacity="0.6" />
                                            <ellipse cx="12" cy="12" rx="5.625" ry="5.625" fill="white" />
                                        </svg>
                                        <h3>Heep</h3></div>
                                    <div className="heep_left_bottom">
                                        <p>1000+ vetted experts across 42 tools</p>
                                        <h1> Hire a no-code<br /> expert today</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-5">
                                <div className="heep_inner_right pe-4">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="heep_right_imgs">
                                                <img src={slideimg} />
                                                <img src={slideimg} />
                                                <img src={slideimg} />
                                                <img src={slideimg} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="heep_right_imgs">
                                                <img src={slideimg} />
                                                <img src={slideimg} />
                                                <img src={slideimg} />
                                                <img src={slideimg} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* -----------------------------------------------------------------------------hidden sections end- ---------------------------------------------------- */}





                    <section className="heep_nocode">
                        <div className="heep_nocode_header">
                            <h1 className='text-white'>Why Heep and no-code?</h1>
                        </div>
                        <div className="row">
                            <div className="col-sm-none col-xl-2"></div>
                            <div className="col-md-6 col-xl-4">
                                <div className="heep_nocode_inner heep_nocode_inner_left">
                                    <div className="heep_nocode_inner_left_img">
                                        <img src={key} />
                                    </div>
                                    <div className="heep_nocode_inner_left_heading">
                                        <h4>Freelancer</h4>
                                        <h3>Receive projects you want<br />
                                            Make $3000+ per month <br />
                                            Work on your terms</h3>
                                    </div>
                                    <div className="postpro_btn"><button className="btn btn-outline-secondary btn-secondary">Add a Project</button></div>
                                </div>
                            </div>
                            <div className="col-md-6 col-xl-4">
                                <div className="heep_nocode_inner heep_nocode_inner_left">

                                    <div className='heep_nocode_inner_left_img'><svg id="Layer_1" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m7.6 13h1.6l-.8-2.1z" /><path d="m15.9 12c-.5.1-.8.4-.9.9s.3 1 .9 1.1h.1 1v-2h-1z" /><path d="m21 3h-18c-.6 0-1 .4-1 1v16c0 .6.4 1 1 1h18c.6 0 1-.4 1-1v-16c0-.6-.4-1-1-1zm-10.6 13-.4-1h-3.2l-.4 1h-2.2l3.2-8h2l3.2 8zm8.6 0h-3-.2c-1.6-.1-2.8-1.4-2.8-3 0-1.7 1.3-3 3-3h1v-2h2z" /></g></svg></div>

                                    <div className="heep_nocode_inner_left_heading">
                                        <h4>Business</h4>
                                        <h3>Get 10X ROI on spend<br />
                                            Solve problems faster <br />
                                            Lower budget</h3>
                                    </div>
                                    <div className="postpro_btn"><button className="btn btn-outline-secondary btn-secondary"><span class="ad_grade_color">Advertise</span></button></div>
                                </div>
                            </div>

                            <div className="col-sm-2"></div>
                        </div>
                    </section>

                </div>
               
                <Footer />
                <FloatingSearch />
            </main>

        </>
    )
}