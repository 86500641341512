import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../assets/css/tabsCommon.scss';
import TabcommData1 from '../components/TabsCommonData.js/TabCommonData1';
import TabcommData2 from './TabsCommonData.js/TabCommonData2';
import TabcommData3 from './TabsCommonData.js/TabCommonData3';
import TabcommData4 from '../components/TabsCommonData.js/TabCommonData4';
import TabcommData5 from '../components/TabsCommonData.js/TabCommonData5';
import TabcommData1c from '../components/TabsCommonData.js/TabCommonData5';
import leftarrow from '../assets/images/warrow.svg';

const TabsCommoncopy = () => {
  return (
    <>
    <Tabs
      defaultActiveKey="Famous"
      id="justify-tab-example"
      className="mb-3 tabs-bg-light"
      justify
    >
       
      <Tab eventKey="Famous" title="Credit Cards">
         <div className='mob_arrows'>
    <div className='arr_left'>
        <img src={leftarrow} />
    </div>
    </div>
    <div className='mob_arrows'>
    <div className='arr_right'>
        <img src={leftarrow} />
    </div>
    </div>
    
        <TabcommData1c />
      </Tab>
      <Tab eventKey="Recently Added" title="Assurance">
        <TabcommData2 />
      </Tab>
      <Tab eventKey="Gainers & Losers" title="Bank Accounts">
        <TabcommData3 />
      </Tab>
      <Tab eventKey="Trending" title="Trending">
        <TabcommData4 />
      </Tab>
      <Tab eventKey="Coming Soon" title="Coming Soon">
        <TabcommData1 />
      </Tab>
    </Tabs>
    
    </>
  );
}

export default TabsCommoncopy;