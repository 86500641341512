import { useRef } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { SEARCH_BUTTONS } from '../../constants';
export const Search = ({ onSearchFocusIn, onSearchFocusOut, showTabs, buttonType }) => {
  const Ref = useRef(null);
  const onEscapeClick = (event) => {
    if (event.key.toUpperCase() === "ESCAPE" && onSearchFocusOut) {
      onSearchFocusOut()
      if (Ref) {
        Ref.current.blur();
      }
    }
  }

  return (<div class="input-group header_search">

    <span class="input-group-text" id="basic-addon1">
    { buttonType === SEARCH_BUTTONS.CLOSE &&<button onClick={() => onSearchFocusOut && onSearchFocusOut()} type="submit" className="btn icon-1"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
</svg></button>}</span>

    <input ref={Ref} type="text" class="form-control" onKeyUp={(e) => onEscapeClick(e)} onFocus={() => onSearchFocusIn()} /* onBlur={() => onSearchFocusOut && onSearchFocusOut()} */ placeholder="Search Tchy.." aria-label="Search" />
    <span class="input-group-text" id="basic-addon1">
    { buttonType === SEARCH_BUTTONS.CLOSE &&<button onClick={() => onSearchFocusOut && onSearchFocusOut()} type="submit" className="btn icon-1"><svg id="Capa_1" enable-background="new 0 0 320.591 320.591" height="512" viewBox="0 0 320.591 320.591" width="512" xmlns="http://www.w3.org/2000/svg"><g><g id="close_1_"><path d="m30.391 318.583c-7.86.457-15.59-2.156-21.56-7.288-11.774-11.844-11.774-30.973 0-42.817l257.812-257.813c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875l-259.331 259.331c-5.893 5.058-13.499 7.666-21.256 7.288z"></path><path d="m287.9 318.583c-7.966-.034-15.601-3.196-21.257-8.806l-257.813-257.814c-10.908-12.738-9.425-31.908 3.313-42.817 11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414-6.35 5.522-14.707 8.161-23.078 7.288z"></path></g></g></svg></button>}</span>
    <span className="search_divide">

      {!showTabs && buttonType === SEARCH_BUTTONS.ESCAPE && <svg className="" height="512" viewBox="0 0 400 400" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><g id="Special_Characters" data-name="Special Characters"><g id="_2" data-name="2"><path d="m305 0h-210a95 95 0 0 0 -95 95v210a95 95 0 0 0 95 95h210a95 95 0 0 0 95-95v-210a95 95 0 0 0 -95-95zm-46.19 95.38-92.25 209.24c-1.25 2.76-3.25 4.26-5.75 4.26h-17.5c-2.75 0-3.5-1.5-2-4.26l92.75-209.24c1.25-2.76 3.25-4.26 5.5-4.26h17.25c2.75 0 3.25 1.5 2 4.26z" /></g></g></g></g></svg>}
      {showTabs && buttonType === SEARCH_BUTTONS.ESCAPE && <button className="btn esc_btn" onClick={() => onSearchFocusOut && onSearchFocusOut()}>esc</button>}
      { buttonType === SEARCH_BUTTONS.CLOSE && <button className="btn cross_btn " onClick={() => onSearchFocusOut && onSearchFocusOut()}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-x" viewBox="0 0 16 16">
  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
</svg></button>}

    </span>
    <div class="dropdown_search d-none">
      <Dropdown>
        <Dropdown.Toggle variant="btn" id="dropdown-basic">Upcoming Sales</Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item href="#/action-1">Upcoming Sales</Dropdown.Item>
          <Dropdown.Item href="#/action-2">New Cryptocurrency</Dropdown.Item>
          <Dropdown.Item href="#/action-3">New NFTs</Dropdown.Item>
          <Dropdown.Item href="#/action-3">New Exchanges</Dropdown.Item>
          <Dropdown.Item href="#/action-3">Directory</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>

  </div>

  )
}

export default Search;