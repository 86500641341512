import React from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import gencoin from '../.././assets/images/gen.png';
import pandcoin from '../../assets/images/pand.png';
import Ccard from '../../assets/images/tfbank_mc_gold.png';
import bsccoin from '../../assets/images/bsc.png';
import oreocoin from '../../assets/images/oreo.png';

function TabcommData1() {
    return (
        <>
            <div className='table-responsive'>
                <table className="table table-control">
                    <thead>
                        <tr>
                            <th colspan="6" width="25%">Coin Name</th>
                            <th scope="col" width="8%">Bezahlung <svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                                <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                                <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                            </svg></th>
                            <th scope="col" width="15%">Zins <svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                                <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                                <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                            </svg></th>
                            <th scope="col" width="15%">24H <svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                                <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                                <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                            </svg></th>


                            <th scope="col" width="15%">Gebühr p.a <svg width="12" height="29" viewBox="0 0 43 59" fill="none" xmlns="http://www.w3.org/2000/svg" className="pe-1">
                                <path d="M2.10669 23.1786H40.0352C40.4519 23.1785 40.8592 23.0549 41.2057 22.8234C41.5521 22.5918 41.8221 22.2628 41.9815 21.8778C42.141 21.4928 42.1827 21.0692 42.1015 20.6605C42.0202 20.2518 41.8196 19.8764 41.525 19.5817L22.5607 0.617466C22.3651 0.421717 22.1329 0.26643 21.8773 0.160481C21.6217 0.0545329 21.3477 0 21.071 0C20.7943 0 20.5203 0.0545329 20.2646 0.160481C20.009 0.26643 19.7768 0.421717 19.5812 0.617466L0.616944 19.5817C0.322346 19.8764 0.121732 20.2518 0.0404643 20.6605C-0.040803 21.0692 0.000925293 21.4928 0.160373 21.8778C0.31982 22.2628 0.589828 22.5918 0.936263 22.8234C1.2827 23.0549 1.69 23.1785 2.10669 23.1786V23.1786Z" fill="white" />
                                <path d="M40.0352 35.8215H2.10669C1.69 35.8216 1.2827 35.9452 0.936263 36.1767C0.589828 36.4083 0.31982 36.7373 0.160373 37.1223C0.000925293 37.5073 -0.040803 37.9309 0.0404643 38.3396C0.121732 38.7483 0.322346 39.1237 0.616944 39.4184L19.5812 58.3826C19.7768 58.5784 20.009 58.7336 20.2647 58.8396C20.5203 58.9455 20.7943 59 21.071 59C21.3477 59 21.6216 58.9455 21.8773 58.8396C22.1329 58.7336 22.3651 58.5784 22.5607 58.3826L41.525 39.4184C41.8196 39.1237 42.0202 38.7483 42.1015 38.3396C42.1827 37.9309 42.141 37.5073 41.9815 37.1223C41.8221 36.7373 41.5521 36.4083 41.2057 36.1767C40.8592 35.9452 40.4519 35.8216 40.0352 35.8215Z" fill="white" />
                            </svg></th>
                            <th scope="col" width="8%" className="text-center">Company </th>
                        </tr>
                    </thead>
                    <tbody>
                    
                        <tr>
                            <td colspan="6">
                                <div className="listing_coin_detail">
                                    <div className="coin_logo"><img src={Ccard} /></div>
                                    <div className="list_detail">
                                        <div class="info">
                                            <div><a href="#"><strong></strong></a><strong>TF Mastercard Gold</strong></div>
                                            <div class="hidden-sm">Archive</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td><div className='coin_box'><div className="coinimg_new"><span className="ps-2">Kostenlos weltweit</span></div></div></td>
                            <td>24,79%</td>
                            <td>Kein Video-/Post-Ident nötig</td>
                            <td>0 €</td>
                            <td className="text-center vote_drpdwn_btn">
                                <button className="new-bg"><span>zum Antrag</span></button>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                                <div className="listing_coin_detail">
                                    <div className="coin_logo"><img src={Ccard} /></div>
                                    <div className="list_detail">
                                        <div class="info">
                                            <div><a href="#"><strong></strong></a><strong>TF Mastercard Gold</strong></div>
                                            <div class="hidden-sm">Archive</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td><div className='coin_box'><div className="coinimg_new"><span className="ps-2">Kostenlos weltweit</span></div></div></td>
                            <td>24,79%</td>
                            <td>Kein Video-/Post-Ident nötig</td>
                            <td>0 €</td>
                            <td className="text-center vote_drpdwn_btn">
                                <button className="new-bg"><span>zum Antrag</span></button>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                                <div className="listing_coin_detail">
                                    <div className="coin_logo"><img src={Ccard} /></div>
                                    <div className="list_detail">
                                        <div class="info">
                                            <div><a href="#"><strong></strong></a><strong>TF Mastercard Gold</strong></div>
                                            <div class="hidden-sm">Archive</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className='coin_box'><div className="coinimg_new"><span className="ps-2">Kostenlos weltweit</span>

                                </div></div></td>
                            <td>24,79%</td>
                            <td>Kein Video-/Post-Ident nötig</td>
                            <td>0 €</td>
                            <td className="text-center vote_drpdwn_btn">
                                <button className="new-bg"><span>zum Antrag</span></button>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                                <div className="listing_coin_detail">
                                    <div className="coin_logo"><img src={Ccard} /></div>
                                    <div className="list_detail">
                                        <div class="info">
                                            <div><a href="#"><strong></strong></a><strong>TF Mastercard Gold</strong></div>
                                            <div class="hidden-sm">Archive</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td><div className='coin_box'><div className="coinimg_new"><span className="ps-2">Kostenlos weltweit</span></div></div></td>
                            <td>24,79%</td>
                            <td>Kein Video-/Post-Ident nötig</td>
                            <td>0 €</td>
                            <td className="text-center vote_drpdwn_btn">
                                <button className="new-bg"><span>zum Antrag</span></button>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                                <div className="listing_coin_detail">
                                    <div className="coin_logo"><img src={Ccard} /></div>
                                    <div className="list_detail">
                                        <div class="info">
                                            <div><a href="#"><strong></strong></a><strong>TF Mastercard Gold</strong></div>
                                            <div class="hidden-sm">Archive</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td><div className='coin_box'><div className="coinimg_new"><span className="ps-2">Kostenlos weltweit</span></div></div></td>
                            <td>24,79%</td>
                            <td>Kein Video-/Post-Ident nötig</td>
                            <td>0 €</td>
                            <td className="text-center vote_drpdwn_btn">
                                <button className="new-bg"><span>zum Antrag</span></button>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                                <div className="listing_coin_detail">
                                    <div className="coin_logo"><img src={Ccard} /></div>
                                    <div className="list_detail">
                                        <div class="info">
                                            <div><a href="#"><strong></strong></a><strong>TF Mastercard Gold</strong></div>
                                            <div class="hidden-sm">Archive</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td><div className='coin_box'><div className="coinimg_new"><span className="ps-2">Kostenlos weltweit</span></div></div></td>
                            <td>24,79%</td>
                            <td>Kein Video-/Post-Ident nötig</td>
                            <td>0 €</td>
                            <td className="text-center vote_drpdwn_btn">
                                <button className="new-bg"><span>zum Antrag</span></button>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                                <div className="listing_coin_detail">
                                    <div className="coin_logo"><img src={Ccard} /></div>
                                    <div className="list_detail">
                                        <div class="info">
                                            <div><a href="#"><strong></strong></a><strong>TF Mastercard Gold</strong></div>
                                            <div class="hidden-sm">Archive</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td><div className='coin_box'><div className="coinimg_new"><span className="ps-2">Kostenlos weltweit</span></div></div></td>
                            <td>24,79%</td>
                            <td>Kein Video-/Post-Ident nötig</td>
                            <td>0 €</td>
                            <td className="text-center vote_drpdwn_btn">
                                <button className="new-bg"><span>zum Antrag</span></button>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="6">
                                <div className="listing_coin_detail">
                                    <div className="coin_logo"><img src={Ccard} /></div>
                                    <div className="list_detail">
                                        <div class="info">
                                            <div><a href="#"><strong></strong></a><strong>TF Mastercard Gold</strong></div>
                                            <div class="hidden-sm">Archive</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td><div className='coin_box'><div className="coinimg_new"><span className="ps-2">Kostenlos weltweit</span></div></div></td>
                            <td>24,79%</td>
                            <td>Kein Video-/Post-Ident nötig</td>
                            <td>0 €</td>
                            <td className="text-center vote_drpdwn_btn">
                                <button className="new-bg"><span>zum Antrag</span></button>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>


        </>
    );
}

export default TabcommData1;