import { useEffect, useState } from 'react';
import { Loader } from '../components/Loader';
import { LOADER_TIMER } from '../constants';
import '../assets/css/uniswap.scss';
import '../assets/css/profile.scss';
import Footer from "../components/Footer";
import HeaderNew2 from "../components/HeaderNew2";
import React from "react";
import utick from '../assets/images/u_tick.svg';
import filcard from '../assets/images/u_filter.png';
import ulogo from '../assets/images/ulogo.svg';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Ccard from '../assets/images/tfbank_mc_gold.png';
import Dropdown from 'react-bootstrap/Dropdown';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import slidernew1 from '../assets/images/sw-g1.webp';
import slidernew2 from '../assets/images/sw-g2.webp';
import slidernew3 from '../assets/images/sw-g3.webp';

export const Profile = () => {
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        console.log(loader, "Loader");
        const timeout = setTimeout(() => {

            setLoader(false);
        }, LOADER_TIMER)
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [])
    return (
        <>
            <Loader show={loader} />
            <main>
                <HeaderNew2 />
                <div className='uniswap_body Pr_body'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='pro_section'>
                                    <div className='p_img'><img src={filcard} /></div>
                                    <div className='abs_profile'>
                                    <img src={filcard} />
                                    </div>
                                </div>
                                <div className='section_left'>
                                    <div className='section_head'>
                                        <h5>Mutant Ape Yacht Club</h5>  
                                    </div>
                                   <div className='profile_info'>
                                    <div className='email_p'>
                                        <label>Email</label>
                                        <p>abc@gmail.com</p>
                                    </div>
                                    <div className='email_p'>
                                        <label>City</label>
                                        <p>Berlin</p>
                                    </div>
                                    <div className='email_p'>
                                   
                                       <a> <label>Edit Profile</label></a>
                                    </div>
                                   </div>
                                    <div className='count_head'>
                                        <div className='count_del'> <label>4.87 ETH</label>
                                            <p>Global floor</p>  </div>
                                        <div className='count_del'> <label className='text_red'><svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="down" class="Delta__StyledDownArrow-sc-358d6432-1 eNRaUT"><path d="M10.6979 16.2453L6.31787 9.75247C5.58184 8.66118 6.2058 7 7.35185 7L16.6482 7C17.7942 7 18.4182 8.66243 17.6821 9.75247L13.3021 16.2453C12.623 17.2516 11.377 17.2516 10.6979 16.2453Z" fill="currentColor"></path></svg>2%</label>
                                            <p>Floor 24H</p>  </div>
                                        <div className='count_del'> <label>1M ETH</label>
                                            <p>Global floor</p>  </div>
                                        <div className='count_del'> <label>19.5k</label>
                                            <p>Global floor</p>  </div>
                                        <div className='count_del'> <label>59%</label>
                                            <p>Global floor</p>  </div>
                                        <div className='count_del'> <label>3%</label>
                                            <p>Global floor</p>  </div>
                                    </div>
                                    <div className='filter_head'>
                      <div className='tabs_section'>
                      <Tabs
      defaultActiveKey="profile"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="home" title="Appartments">
      <div className='row'>
                                            <div className='col-lg-6'>
                                                <div className='filter_card'>
                                                    <div className='f_-card_img'>
                                                        <img src={slidernew1} />
                                                        {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                    </div>
                                                    <div className='f_card_detail'>
                                                        <div className='card_count'>
                                                            <div className='loc_app'> <h6>1 room apartment with terrace in Wilmersdorf (Berlin)</h6>
                                                                <p><span className=''>Zechliner Straße 1a Berlin, Wedding </span></p></div>
                                                            <div>   <label>#18,495</label></div>

                                                        </div>
                                                        <div className='dimen_app'>
                                                            <div>
                                                                <h6>2244 €</h6>
                                                                <p>Kaltmiete</p>
                                                            </div>
                                                            <div>
                                                                <h6>88,99 m<sup>2</sup></h6>
                                                                <p>Wohnfinance</p>
                                                            </div>
                                                            <div>
                                                                <h6>4</h6>
                                                                <p>Zi</p>
                                                            </div>
                                                        </div>
                                                        <div className='add_btn'>
                                                            <button className=''>Remove from List</button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='filter_card'>
                                                    <div className='f_-card_img'>
                                                        <img src={slidernew2} />
                                                        {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                    </div>
                                                    <div className='f_card_detail'>
                                                        <div className='card_count'>
                                                            <div className='loc_app'> <h6>1 room apartment with terrace in Wilmersdorf (Berlin)</h6>
                                                                <p><span className=''>Zechliner Straße 1a Berlin, Wedding </span></p></div>
                                                            <div>   <label>#18,495</label></div>

                                                        </div>
                                                        <div className='dimen_app'>
                                                            <div>
                                                                <h6>2244 €</h6>
                                                                <p>Kaltmiete</p>
                                                            </div>
                                                            <div>
                                                                <h6>88,99 m<sup>2</sup></h6>
                                                                <p>Wohnfinance</p>
                                                            </div>
                                                            <div>
                                                                <h6>4</h6>
                                                                <p>Zi</p>
                                                            </div>
                                                        </div>
                                                        <div className='add_btn'>
                                                            <button className=''>Remove from List</button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='filter_card'>
                                                    <div className='f_-card_img'>
                                                        <img src={slidernew3} />
                                                        {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                    </div>
                                                    <div className='f_card_detail'>
                                                        <div className='card_count'>
                                                            <div className='loc_app'> <h6>1 room apartment with terrace in Wilmersdorf (Berlin)</h6>
                                                                <p><span className=''>Zechliner Straße 1a Berlin, Wedding </span></p></div>
                                                            <div>   <label>#18,495</label></div>

                                                        </div>
                                                        <div className='dimen_app'>
                                                            <div>
                                                                <h6>2244 €</h6>
                                                                <p>Kaltmiete</p>
                                                            </div>
                                                            <div>
                                                                <h6>88,99 m<sup>2</sup></h6>
                                                                <p>Wohnfinance</p>
                                                            </div>
                                                            <div>
                                                                <h6>4</h6>
                                                                <p>Zi</p>
                                                            </div>
                                                        </div>
                                                        <div className='add_btn'>
                                                            <button className=''>Remove from List</button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='filter_card'>
                                                    <div className='f_-card_img'>
                                                        <img src={slidernew1} />
                                                        {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                    </div>
                                                    <div className='f_card_detail'>
                                                        <div className='card_count'>
                                                            <div className='loc_app'> <h6>1 room apartment with terrace in Wilmersdorf (Berlin)</h6>
                                                                <p><span className=''>Zechliner Straße 1a Berlin, Wedding </span></p></div>
                                                            <div>   <label>#18,495</label></div>

                                                        </div>
                                                        <div className='dimen_app'>
                                                            <div>
                                                                <h6>2244 €</h6>
                                                                <p>Kaltmiete</p>
                                                            </div>
                                                            <div>
                                                                <h6>88,99 m<sup>2</sup></h6>
                                                                <p>Wohnfinance</p>
                                                            </div>
                                                            <div>
                                                                <h6>4</h6>
                                                                <p>Zi</p>
                                                            </div>
                                                        </div>
                                                        <div className='add_btn'>
                                                            <button className=''>Remove from List</button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='filter_card'>
                                                    <div className='f_-card_img'>
                                                        <img src={slidernew2} />
                                                        {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                    </div>
                                                    <div className='f_card_detail'>
                                                        <div className='card_count'>
                                                            <div className='loc_app'> <h6>1 room apartment with terrace in Wilmersdorf (Berlin)</h6>
                                                                <p><span className=''>Zechliner Straße 1a Berlin, Wedding </span></p></div>
                                                            <div>   <label>#18,495</label></div>

                                                        </div>
                                                        <div className='dimen_app'>
                                                            <div>
                                                                <h6>2244 €</h6>
                                                                <p>Kaltmiete</p>
                                                            </div>
                                                            <div>
                                                                <h6>88,99 m<sup>2</sup></h6>
                                                                <p>Wohnfinance</p>
                                                            </div>
                                                            <div>
                                                                <h6>4</h6>
                                                                <p>Zi</p>
                                                            </div>
                                                        </div>
                                                        <div className='add_btn'>
                                                            <button className=''>Remove from List</button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='filter_card'>
                                                    <div className='f_-card_img'>
                                                        <img src={slidernew3} />
                                                        {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                    </div>
                                                    <div className='f_card_detail'>
                                                        <div className='card_count'>
                                                            <div className='loc_app'> <h6>1 room apartment with terrace in Wilmersdorf (Berlin)</h6>
                                                                <p><span className=''>Zechliner Straße 1a Berlin, Wedding </span></p></div>
                                                            <div>   <label>#18,495</label></div>

                                                        </div>
                                                        <div className='dimen_app'>
                                                            <div>
                                                                <h6>2244 €</h6>
                                                                <p>Kaltmiete</p>
                                                            </div>
                                                            <div>
                                                                <h6>88,99 m<sup>2</sup></h6>
                                                                <p>Wohnfinance</p>
                                                            </div>
                                                            <div>
                                                                <h6>4</h6>
                                                                <p>Zi</p>
                                                            </div>
                                                        </div>
                                                        <div className='add_btn'>
                                                            <button className=''>Remove from List</button>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
      </Tab>
      <Tab eventKey="profile" title="University">
      <div className='table-responsive'>
                                            <table className="table table-control act_table university_table">
                                                <thead>
                                                    <tr>
                                                        <th colspan="6" width="25%">University</th>
                                                        <th scope="col" width="8%">Study </th>
                                                        <th scope="col" width="15%">City </th>
                                                        <th scope="col" width="15%">Bachelor </th>


                                                        <th scope="col" width="15%">Masters </th>
                                                        <th scope="col" width="8%" className="text-center"> </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                        <td colspan="6">
                                                            <div className="act_detail_main">
                                                                <div className="act_logo"><img src={Ccard} /></div>
                                                                <div className="act_detail">
                                                                    <div class="act_info">
                                                                        <div><a href="#"><strong></strong></a><strong>TF Mastercard Gold</strong></div>
                                                                        <div class="hidden-sm">Archive</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><div className='act_box'><div className="actimg">
                                                            <div className='bach_deg'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send me-2" viewBox="0 0 16 16">
                                                                <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                                                            </svg><span>Bachelor </span></div>
                                                            <div className='mt-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tag" viewBox="0 0 16 16">
                                                                <path d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z" />
                                                                <path d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586 7 7L13.586 9l-7-7H2v4.586z" />
                                                            </svg>
                                                                <span className="ps-2">Masters</span></div>
                                                        </div></div></td>
                                                        <td>5.075 ETH</td>
                                                        <td>            <div className=' stay_fil city_fill'>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                                    Offers
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>


                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div></td>
                                                        <td> <div className=' stay_fil city_fill'>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                                    Offers
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>


                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div></td>
                                                        <td className="text-center ">
                                                            <button class="new-bg-trans"><span>Edit</span></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="6">
                                                            <div className="act_detail_main">
                                                                <div className="act_logo"><img src={Ccard} /></div>
                                                                <div className="act_detail">
                                                                    <div class="act_info">
                                                                        <div><a href="#"><strong></strong></a><strong>TF Mastercard Gold</strong></div>
                                                                        <div class="hidden-sm">Archive</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><div className='act_box'><div className="actimg">
                                                            <div className='bach_deg'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send me-2" viewBox="0 0 16 16">
                                                                <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                                                            </svg><span>Bachelor </span></div>
                                                            <div className='mt-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tag" viewBox="0 0 16 16">
                                                                <path d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z" />
                                                                <path d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586 7 7L13.586 9l-7-7H2v4.586z" />
                                                            </svg>
                                                                <span className="ps-2">Masters</span></div>
                                                        </div></div></td>
                                                        <td>5.075 ETH</td>
                                                        <td>            <div className=' stay_fil city_fill'>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                                    Offers
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>


                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div></td>
                                                        <td> <div className=' stay_fil city_fill'>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                                    Offers
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>


                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div></td>
                                                        <td className="text-center ">
                                                            <button class="new-bg-trans"><span>Edit</span></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="6">
                                                            <div className="act_detail_main">
                                                                <div className="act_logo"><img src={Ccard} /></div>
                                                                <div className="act_detail">
                                                                    <div class="act_info">
                                                                        <div><a href="#"><strong></strong></a><strong>TF Mastercard Gold</strong></div>
                                                                        <div class="hidden-sm">Archive</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><div className='act_box'><div className="actimg">
                                                            <div className='bach_deg'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send me-2" viewBox="0 0 16 16">
                                                                <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                                                            </svg><span>Bachelor </span></div>
                                                            <div className='mt-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tag" viewBox="0 0 16 16">
                                                                <path d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z" />
                                                                <path d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586 7 7L13.586 9l-7-7H2v4.586z" />
                                                            </svg>
                                                                <span className="ps-2">Masters</span></div>
                                                        </div></div></td>
                                                        <td>5.075 ETH</td>
                                                        <td>            <div className=' stay_fil city_fill'>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                                    Offers
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>


                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div></td>
                                                        <td> <div className=' stay_fil city_fill'>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                                    Offers
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>


                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div></td>
                                                        <td className="text-center ">
                                                            <button class="new-bg-trans"><span>Edit</span></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="6">
                                                            <div className="act_detail_main">
                                                                <div className="act_logo"><img src={Ccard} /></div>
                                                                <div className="act_detail">
                                                                    <div class="act_info">
                                                                        <div><a href="#"><strong></strong></a><strong>TF Mastercard Gold</strong></div>
                                                                        <div class="hidden-sm">Archive</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><div className='act_box'><div className="actimg">
                                                            <div className='bach_deg'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send me-2" viewBox="0 0 16 16">
                                                                <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                                                            </svg><span>Bachelor </span></div>
                                                            <div className='mt-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tag" viewBox="0 0 16 16">
                                                                <path d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z" />
                                                                <path d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586 7 7L13.586 9l-7-7H2v4.586z" />
                                                            </svg>
                                                                <span className="ps-2">Masters</span></div>
                                                        </div></div></td>
                                                        <td>5.075 ETH</td>
                                                        <td>            <div className=' stay_fil city_fill'>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                                    Offers
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>


                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div></td>
                                                        <td> <div className=' stay_fil city_fill'>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                                    Offers
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>


                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div></td>
                                                        <td className="text-center ">
                                                            <button class="new-bg-trans"><span>Edit</span></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="6">
                                                            <div className="act_detail_main">
                                                                <div className="act_logo"><img src={Ccard} /></div>
                                                                <div className="act_detail">
                                                                    <div class="act_info">
                                                                        <div><a href="#"><strong></strong></a><strong>TF Mastercard Gold</strong></div>
                                                                        <div class="hidden-sm">Archive</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><div className='act_box'><div className="actimg">
                                                            <div className='bach_deg'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send me-2" viewBox="0 0 16 16">
                                                                <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576 6.636 10.07Zm6.787-8.201L1.591 6.602l4.339 2.76 7.494-7.493Z" />
                                                            </svg><span>Bachelor </span></div>
                                                            <div className='mt-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tag" viewBox="0 0 16 16">
                                                                <path d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z" />
                                                                <path d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586 7 7L13.586 9l-7-7H2v4.586z" />
                                                            </svg>
                                                                <span className="ps-2">Masters</span></div>
                                                        </div></div></td>
                                                        <td>5.075 ETH</td>
                                                        <td>            <div className=' stay_fil city_fill'>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                                    Offers
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>


                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div></td>
                                                        <td> <div className=' stay_fil city_fill'>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="" id="dropdown-basic">
                                                                    Offers
                                                                </Dropdown.Toggle>

                                                                <Dropdown.Menu>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>
                                                                    <Dropdown.Item href="#/action-1"><label class="check_custom">alle     </label>
                                                                    </Dropdown.Item>


                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div></td>
                                                        <td className="text-center ">
                                                            <button class="new-bg-trans"><span>Edit</span></button>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>
      </Tab>
      <Tab eventKey="Blogpost" title="Blog"></Tab>
      <Tab eventKey="Profile" title="Profile">


        
      </Tab>
    
    </Tabs>
                      </div>
                                        
                                        

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

<Footer />

            </main>

        </>
    )
}