import { useEffect, useState, useCallback, useRef } from 'react';
import { Loader } from '../components/Loader';
import { LOADER_TIMER } from '../constants';
import '../assets/css/uniswap2.scss';
import '../assets/css/museum.scss';
import Footer from "../components/Footer";
import React from "react";
import utick from '../assets/images/u_tick.svg';
import filcard from '../assets/images/u_filter.png';
import ulogo from '../assets/images/ulogo.svg';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import HeaderNew2 from "../components/HeaderNew2";
import Dropdown from 'react-bootstrap/Dropdown';
import slidernew1 from '../assets/images/sw-g1.webp';
import slidernew2 from '../assets/images/sw-g2.webp';
import slidernew3 from '../assets/images/sw-g3.webp';
import Accordion from 'react-bootstrap/Accordion';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Ccard from '../assets/images/tfbank_mc_gold.png';





export const Museum2 = () => {
    const [loader, setLoader] = useState(true);
    const Ref = useRef(null);
    const [showTabs, setShowTabs] = useState(false)
    const [searchActive, setSearchActive] = useState(false)
    const [navigationActive, setNavigationActive] = useState(false)

    const onSearchFocusIn = useCallback(() => {
        setShowTabs(true)
    }, [showTabs])

    const onSearchFocusOut = useCallback(() => {
        Ref.current.blur();
        setShowTabs(false)
        hideSearch()
    }, [showTabs])

    const handleClickOutside = (event) => {
        if (
            Ref.current &&
            !Ref.current.contains(event.target)
        ) {
            onSearchFocusOut()
        }

    }

    const onEscapeClick = (event) => {
        if (Ref.current && Ref.current === event.target && event.key.toUpperCase() === "ESCAPE") {
            onSearchFocusOut();
        }
    }


    useEffect(() => {
        document
            .addEventListener('mousedown', handleClickOutside);

        return () => {
            document
                .removeEventListener('mousedown', handleClickOutside);
        }
    }, [])

    const showSearch = () => {
        setSearchActive(true)
    }

    const hideSearch = () => {
        setSearchActive(false)
    }

    const openNav = () => {
        setNavigationActive(true)
    }

    const closeNav = () => {
        setNavigationActive(false)
    }
    return (
        <>

            <main>
                <HeaderNew2 />

                <div className='uniswap_body museum_body'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-12 s_bag_width_left'>
                                <div className='section_left'>
                                    <div className='section_head'>
                                        <h5>Mutant Ape Yacht Club</h5>  <img src={utick} />
                                    </div>
                                    <p>The MUTANT APE YACHT CLUB is a collection of up to 20,000 Mutant Apes that can only</p>
                                    <div className='count_head'>
                                        <div className='count_del'> <label>4.87 ETH</label>
                                            <p>Global floor</p>  </div>
                                        <div className='count_del'> <label className='text_red'><svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="down" class="Delta__StyledDownArrow-sc-358d6432-1 eNRaUT"><path d="M10.6979 16.2453L6.31787 9.75247C5.58184 8.66118 6.2058 7 7.35185 7L16.6482 7C17.7942 7 18.4182 8.66243 17.6821 9.75247L13.3021 16.2453C12.623 17.2516 11.377 17.2516 10.6979 16.2453Z" fill="currentColor"></path></svg>2%</label>
                                            <p>Floor 24H</p>  </div>
                                        <div className='count_del'> <label>1M ETH</label>
                                            <p>Global floor</p>  </div>
                                        <div className='count_del'> <label>19.5k</label>
                                            <p>Global floor</p>  </div>
                                        <div className='count_del'> <label>59%</label>
                                            <p>Global floor</p>  </div>
                                        <div className='count_del'> <label>3%</label>
                                            <p>Global floor</p>  </div>
                                    </div>
                                    <div className='filter_head'>
                                        <div className='tabs_section'>
                                            <Tabs
                                                defaultActiveKey="home"
                                                id="uncontrolled-tab-example"
                                                className="mb-3"
                                            >
                                                <Tab eventKey="home" title="Museum">
                                                <div className='sti_filter' style={{position:'revert'}}>
                                                        <div className='filter_search'>
                                                            <div className='fil_left'>
                                                                <div className='c_fil filter' onClick={() => openNav()}>
                                                                    <svg className='me-2' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M5.5 3H18.5C19.328 3 20 3.672 20 4.5V6.75699C20 7.15499 19.842 7.53599 19.561 7.81799L14.4399 12.939C14.1589 13.22 14.001 13.602 14.001 14V21L10.001 18V14C10.001 13.602 9.84302 13.221 9.56202 12.939L4.44104 7.81799C4.16004 7.53699 4.00196 7.15499 4.00196 6.75699V4.5C3.99996 3.672 4.672 3 5.5 3Z"></path></svg>
                                                                    <span>Filter • 506 results</span>
                                                                </div>
                                                                {/* <div className='c_fil filter'>
                                                <svg width="20" height="20" viewBox="0 3 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.4834 5.71191C19.0879 5.29883 18.4727 5.30762 18.0859 5.71191L13.6562 10.2471C13.4805 10.4229 13.3662 10.6953 13.3662 10.9326C13.3662 11.4863 13.7529 11.8643 14.2979 11.8643C14.5615 11.8643 14.7725 11.7764 14.9482 11.5918L16.7588 9.71094L17.9189 8.375L17.8486 10.2383L17.8486 21.6465C17.8486 22.1914 18.2441 22.5869 18.7891 22.5869C19.334 22.5869 19.7207 22.1914 19.7207 21.6465L19.7207 10.2383L19.6592 8.375L20.8105 9.71094L22.6211 11.5918C22.7969 11.7764 23.0166 11.8643 23.2803 11.8643C23.8164 11.8643 24.2031 11.4863 24.2031 10.9326C24.2031 10.6953 24.0889 10.4229 23.9131 10.2471L19.4834 5.71191ZM7.84668 22.2793C8.24218 22.6924 8.85742 22.6836 9.24414 22.2793L13.6738 17.7529C13.8496 17.5684 13.9639 17.2959 13.9639 17.0586C13.9639 16.5137 13.5771 16.1357 13.0322 16.1357C12.7773 16.1357 12.5576 16.2236 12.3818 16.3994L10.5713 18.2803L9.41992 19.6162L9.48144 17.7529L9.48144 6.34473C9.48144 5.80859 9.08594 5.4043 8.54101 5.4043C8.00488 5.4043 7.60937 5.80859 7.60937 6.34473L7.60937 17.7529L7.6709 19.6162L6.51953 18.2803L4.70898 16.3994C4.5332 16.2236 4.31347 16.1357 4.05859 16.1357C3.51367 16.1357 3.12695 16.5137 3.12695 17.0586C3.12695 17.2959 3.24121 17.5684 3.41699 17.7529L7.84668 22.2793Z" fill="currentColor"></path></svg>                            <span>Price: Low to High</span>
                                            </div> */}
                                                                <div className=' stay_fil pric_high'>
                                                                    <Dropdown>
                                                                        <Dropdown.Toggle variant="" id="dropdown-basic" className='high_lw_dropdown'>
                                                                            <div>
                                                                                <svg className='me-2' width="20" height="20" viewBox="0 3 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.4834 5.71191C19.0879 5.29883 18.4727 5.30762 18.0859 5.71191L13.6562 10.2471C13.4805 10.4229 13.3662 10.6953 13.3662 10.9326C13.3662 11.4863 13.7529 11.8643 14.2979 11.8643C14.5615 11.8643 14.7725 11.7764 14.9482 11.5918L16.7588 9.71094L17.9189 8.375L17.8486 10.2383L17.8486 21.6465C17.8486 22.1914 18.2441 22.5869 18.7891 22.5869C19.334 22.5869 19.7207 22.1914 19.7207 21.6465L19.7207 10.2383L19.6592 8.375L20.8105 9.71094L22.6211 11.5918C22.7969 11.7764 23.0166 11.8643 23.2803 11.8643C23.8164 11.8643 24.2031 11.4863 24.2031 10.9326C24.2031 10.6953 24.0889 10.4229 23.9131 10.2471L19.4834 5.71191ZM7.84668 22.2793C8.24218 22.6924 8.85742 22.6836 9.24414 22.2793L13.6738 17.7529C13.8496 17.5684 13.9639 17.2959 13.9639 17.0586C13.9639 16.5137 13.5771 16.1357 13.0322 16.1357C12.7773 16.1357 12.5576 16.2236 12.3818 16.3994L10.5713 18.2803L9.41992 19.6162L9.48144 17.7529L9.48144 6.34473C9.48144 5.80859 9.08594 5.4043 8.54101 5.4043C8.00488 5.4043 7.60937 5.80859 7.60937 6.34473L7.60937 17.7529L7.6709 19.6162L6.51953 18.2803L4.70898 16.3994C4.5332 16.2236 4.31347 16.1357 4.05859 16.1357C3.51367 16.1357 3.12695 16.5137 3.12695 17.0586C3.12695 17.2959 3.24121 17.5684 3.41699 17.7529L7.84668 22.2793Z" fill="currentColor"></path></svg>
                                                                                Sort By</div>
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item href="#/action-1"><span>Price: Low to High</span>
                                                                            </Dropdown.Item>
                                                                            <Dropdown.Item href="#/action-2"><span>Price: High to Low</span></Dropdown.Item>
                                                                            <Dropdown.Item href="#/action-3"><span>Rarity: Rare to Common</span></Dropdown.Item>
                                                                            <Dropdown.Item href="#/action-3"><span>Rarity: Common to Rare</span> </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                                <div><input type="text" class="input_filter" placeholder="Serach by name" /></div>



                                                              
                                                            </div>
                                                            <div className='fil_left'>
                                                            <div className=' stay_fil gallery_section'>
                                                                    <Dropdown>
                                                                        <Dropdown.Toggle variant="" id="dropdown-basic" className='high_lw_dropdown'>
                                                                            <div>
                                                                            Museum </div>
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item href="#/action-1"><span>Gallery</span>
                                                                            </Dropdown.Item>
                                                                     
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                            
                                                            </div>

                                                        </div>

                                                    </div>
                                                    <div className='row colm_width'>
                                                        <div className='col-12  col-md-6 col-lg-3 pe-0'>
                                                            <div className='filter_card'>
                                                                <div className='f_-card_img'>
                                                                    <img src={slidernew1} />
                                                                    {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                                </div>
                                                                <div className='f_card_detail'>
                                                                    <div className='card_count'>
                                                                        <div className='loc_app'> <h6>Neues Museum</h6>
                                                                            <p><span className=''>Bodestraße 1-3, 10178 Berlin, Germany </span></p></div>
                                                                        <div>   <label>#18,495</label></div>

                                                                    </div>
                                                                    <div className='dimen_app'>
                                                                        <h6>    Berlin</h6>
                                                                    </div>
                                                                    <div className='add_btn'>
                                                                        <button className=''>Save to list</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='col-12 col-md-6 col-lg-3 pe-3 pe-md-0'>
                                                            <div className='filter_card'>
                                                                <div className='f_-card_img'>
                                                                    <img src={slidernew1} />
                                                                    {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                                </div>
                                                                <div className='f_card_detail'>
                                                                    <div className='card_count'>
                                                                        <div className='loc_app'> <h6>Neues Museum</h6>
                                                                            <p><span className=''>Bodestraße 1-3, 10178 Berlin, Germany </span></p></div>
                                                                        <div>   <label>#18,495</label></div>

                                                                    </div>
                                                                    <div className='dimen_app'>
                                                                        <h6>    Berlin</h6>
                                                                    </div>
                                                                    <div className='add_btn'>
                                                                        <button className=''>Save to list</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='col-12 col-md-6 col-lg-3 pe-0'>
                                                            <div className='filter_card'>
                                                                <div className='f_-card_img'>
                                                                    <img src={slidernew1} />
                                                                    {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                                </div>
                                                                <div className='f_card_detail'>
                                                                    <div className='card_count'>
                                                                        <div className='loc_app'> <h6>Neues Museum</h6>
                                                                            <p><span className=''>Bodestraße 1-3, 10178 Berlin, Germany </span></p></div>
                                                                        <div>   <label>#18,495</label></div>

                                                                    </div>
                                                                    <div className='dimen_app'>
                                                                        <h6>    Berlin</h6>
                                                                    </div>
                                                                    <div className='add_btn'>
                                                                        <button className=''>Save to list</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='col-12 col-md-6 col-lg-3'>
                                                            <div className='filter_card'>
                                                                <div className='f_-card_img'>
                                                                    <img src={slidernew1} />
                                                                    {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                                </div>
                                                                <div className='f_card_detail'>
                                                                    <div className='card_count'>
                                                                        <div className='loc_app'> <h6>Neues Museum</h6>
                                                                            <p><span className=''>Bodestraße 1-3, 10178 Berlin, Germany </span></p></div>
                                                                        <div>   <label>#18,495</label></div>

                                                                    </div>
                                                                    <div className='dimen_app'>
                                                                        <h6>    Berlin</h6>
                                                                    </div>
                                                                    <div className='add_btn'>
                                                                        <button className=''>Save to list</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='col-12 col-md-6 col-lg-3 pe-0'>
                                                            <div className='filter_card'>
                                                                <div className='f_-card_img'>
                                                                    <img src={slidernew1} />
                                                                    {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                                </div>
                                                                <div className='f_card_detail'>
                                                                    <div className='card_count'>
                                                                        <div className='loc_app'> <h6>Neues Museum</h6>
                                                                            <p><span className=''>Bodestraße 1-3, 10178 Berlin, Germany </span></p></div>
                                                                        <div>   <label>#18,495</label></div>

                                                                    </div>
                                                                    <div className='dimen_app'>
                                                                        <h6>    Berlin</h6>
                                                                    </div>
                                                                    <div className='add_btn'>
                                                                        <button className=''>Save to list</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='col-12 col-md-6 col-lg-3 pe-3 pe-md-0'>
                                                            <div className='filter_card'>
                                                                <div className='f_-card_img'>
                                                                    <img src={slidernew1} />
                                                                    {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                                </div>
                                                                <div className='f_card_detail'>
                                                                    <div className='card_count'>
                                                                        <div className='loc_app'> <h6>Neues Museum</h6>
                                                                            <p><span className=''>Bodestraße 1-3, 10178 Berlin, Germany </span></p></div>
                                                                        <div>   <label>#18,495</label></div>

                                                                    </div>
                                                                    <div className='dimen_app'>
                                                                        <h6>    Berlin</h6>
                                                                    </div>
                                                                    <div className='add_btn'>
                                                                        <button className=''>Save to list</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='col-12 col-md-6 col-lg-3 pe-0'>
                                                            <div className='filter_card'>
                                                                <div className='f_-card_img'>
                                                                    <img src={slidernew1} />
                                                                    {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                                </div>
                                                                <div className='f_card_detail'>
                                                                    <div className='card_count'>
                                                                        <div className='loc_app'> <h6>Neues Museum</h6>
                                                                            <p><span className=''>Bodestraße 1-3, 10178 Berlin, Germany </span></p></div>
                                                                        <div>   <label>#18,495</label></div>

                                                                    </div>
                                                                    <div className='dimen_app'>
                                                                        <h6>    Berlin</h6>
                                                                    </div>
                                                                    <div className='add_btn'>
                                                                        <button className=''>Save to list</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='col-12 col-md-6 col-lg-3'>
                                                            <div className='filter_card'>
                                                                <div className='f_-card_img'>
                                                                    <img src={slidernew1} />
                                                                    {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                                </div>
                                                                <div className='f_card_detail'>
                                                                    <div className='card_count'>
                                                                        <div className='loc_app'> <h6>Neues Museum</h6>
                                                                            <p><span className=''>Bodestraße 1-3, 10178 Berlin, Germany </span></p></div>
                                                                        <div>   <label>#18,495</label></div>

                                                                    </div>
                                                                    <div className='dimen_app'>
                                                                        <h6>    Berlin</h6>
                                                                    </div>
                                                                    <div className='add_btn'>
                                                                        <button className=''>Save to list</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="table_pagination mt-3 justify-content-center">
                                            <div aria-label="Page navigation example">
                                                <ul class="pagination" style={{ background: '#36383a94' }}>
                                                    <li class="page-item">
                                                        <a class="page-link" href="#" aria-label="Previous">
                                                            <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                                                            </svg></span>
                                                            <span class="sr-only"></span>
                                                        </a>
                                                    </li>
                                                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                                    <li class="page-item">
                                                        <a class="page-link" href="#" aria-label="Next">
                                                            <span aria-hidden="true"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                                                            </svg></span>
                                                            <span class="sr-only"></span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                                    </div>

                                                </Tab>
                                                <Tab eventKey="profile" title="Gallery">
                                 <div className='sti_filter'  >
                                                        <div className='filter_search'>
                                                            <div className='fil_left'>
                                                                <div className='c_fil filter' onClick={() => openNav()}>
                                                                    <svg className='me-2' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M5.5 3H18.5C19.328 3 20 3.672 20 4.5V6.75699C20 7.15499 19.842 7.53599 19.561 7.81799L14.4399 12.939C14.1589 13.22 14.001 13.602 14.001 14V21L10.001 18V14C10.001 13.602 9.84302 13.221 9.56202 12.939L4.44104 7.81799C4.16004 7.53699 4.00196 7.15499 4.00196 6.75699V4.5C3.99996 3.672 4.672 3 5.5 3Z"></path></svg>
                                                                    <span>Filter • 506 results</span>
                                                                </div>
                                                                {/* <div className='c_fil filter'>
                                                <svg width="20" height="20" viewBox="0 3 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.4834 5.71191C19.0879 5.29883 18.4727 5.30762 18.0859 5.71191L13.6562 10.2471C13.4805 10.4229 13.3662 10.6953 13.3662 10.9326C13.3662 11.4863 13.7529 11.8643 14.2979 11.8643C14.5615 11.8643 14.7725 11.7764 14.9482 11.5918L16.7588 9.71094L17.9189 8.375L17.8486 10.2383L17.8486 21.6465C17.8486 22.1914 18.2441 22.5869 18.7891 22.5869C19.334 22.5869 19.7207 22.1914 19.7207 21.6465L19.7207 10.2383L19.6592 8.375L20.8105 9.71094L22.6211 11.5918C22.7969 11.7764 23.0166 11.8643 23.2803 11.8643C23.8164 11.8643 24.2031 11.4863 24.2031 10.9326C24.2031 10.6953 24.0889 10.4229 23.9131 10.2471L19.4834 5.71191ZM7.84668 22.2793C8.24218 22.6924 8.85742 22.6836 9.24414 22.2793L13.6738 17.7529C13.8496 17.5684 13.9639 17.2959 13.9639 17.0586C13.9639 16.5137 13.5771 16.1357 13.0322 16.1357C12.7773 16.1357 12.5576 16.2236 12.3818 16.3994L10.5713 18.2803L9.41992 19.6162L9.48144 17.7529L9.48144 6.34473C9.48144 5.80859 9.08594 5.4043 8.54101 5.4043C8.00488 5.4043 7.60937 5.80859 7.60937 6.34473L7.60937 17.7529L7.6709 19.6162L6.51953 18.2803L4.70898 16.3994C4.5332 16.2236 4.31347 16.1357 4.05859 16.1357C3.51367 16.1357 3.12695 16.5137 3.12695 17.0586C3.12695 17.2959 3.24121 17.5684 3.41699 17.7529L7.84668 22.2793Z" fill="currentColor"></path></svg>                            <span>Price: Low to High</span>
                                            </div> */}
                                                                <div className=' stay_fil pric_high'>
                                                                    <Dropdown>
                                                                        <Dropdown.Toggle variant="" id="dropdown-basic" className='high_lw_dropdown'>
                                                                            <div>
                                                                                <svg className='me-2' width="20" height="20" viewBox="0 3 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.4834 5.71191C19.0879 5.29883 18.4727 5.30762 18.0859 5.71191L13.6562 10.2471C13.4805 10.4229 13.3662 10.6953 13.3662 10.9326C13.3662 11.4863 13.7529 11.8643 14.2979 11.8643C14.5615 11.8643 14.7725 11.7764 14.9482 11.5918L16.7588 9.71094L17.9189 8.375L17.8486 10.2383L17.8486 21.6465C17.8486 22.1914 18.2441 22.5869 18.7891 22.5869C19.334 22.5869 19.7207 22.1914 19.7207 21.6465L19.7207 10.2383L19.6592 8.375L20.8105 9.71094L22.6211 11.5918C22.7969 11.7764 23.0166 11.8643 23.2803 11.8643C23.8164 11.8643 24.2031 11.4863 24.2031 10.9326C24.2031 10.6953 24.0889 10.4229 23.9131 10.2471L19.4834 5.71191ZM7.84668 22.2793C8.24218 22.6924 8.85742 22.6836 9.24414 22.2793L13.6738 17.7529C13.8496 17.5684 13.9639 17.2959 13.9639 17.0586C13.9639 16.5137 13.5771 16.1357 13.0322 16.1357C12.7773 16.1357 12.5576 16.2236 12.3818 16.3994L10.5713 18.2803L9.41992 19.6162L9.48144 17.7529L9.48144 6.34473C9.48144 5.80859 9.08594 5.4043 8.54101 5.4043C8.00488 5.4043 7.60937 5.80859 7.60937 6.34473L7.60937 17.7529L7.6709 19.6162L6.51953 18.2803L4.70898 16.3994C4.5332 16.2236 4.31347 16.1357 4.05859 16.1357C3.51367 16.1357 3.12695 16.5137 3.12695 17.0586C3.12695 17.2959 3.24121 17.5684 3.41699 17.7529L7.84668 22.2793Z" fill="currentColor"></path></svg>
                                                                                Sort By</div>
                                                                        </Dropdown.Toggle>

                                                                        <Dropdown.Menu>
                                                                            <Dropdown.Item href="#/action-1"><span>Price: Low to High</span>
                                                                            </Dropdown.Item>
                                                                            <Dropdown.Item href="#/action-2"><span>Price: High to Low</span></Dropdown.Item>
                                                                            <Dropdown.Item href="#/action-3"><span>Rarity: Rare to Common</span></Dropdown.Item>
                                                                            <Dropdown.Item href="#/action-3"><span>Rarity: Common to Rare</span> </Dropdown.Item>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                                <div><input type="text" class="input_filter" placeholder="Serach by name" /></div>



                                                                {/* <input type='text' className='input_filter' placeholder='Search By Name' /> */}
                                                            </div>
                                                            <div className='fil_right'>

                                                                <Dropdown className='cat_se_menu'>
                                                                    <Dropdown.Toggle variant="" id="dropdown-basic" className='cat_selet'>
                                                                        <div>

                                                                            Gallery</div>
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item href="#/action-1"><span>Museum</span>
                                                                        </Dropdown.Item>

                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>

                                                        </div>

                                                    </div>
                                                    <div className='row colm_width'>
                                                        <div className='col-12 col-12 col-md-6 col-lg-3 pe-0'>
                                                            <div className='filter_card'>
                                                                <div className='f_-card_img'>
                                                                    <img src={slidernew1} />
                                                                    {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                                </div>
                                                                <div className='f_card_detail'>
                                                                    <div className='card_count'>
                                                                        <div className='loc_app'> <h6>Neues Museum</h6>
                                                                            <p><span className=''>Bodestraße 1-3, 10178 Berlin, Germany </span></p></div>
                                                                        <div>   <label>#18,495</label></div>

                                                                    </div>
                                                                    <div className='dimen_app'>
                                                                        <h6>    Berlin</h6>
                                                                    </div>
                                                                    <div className='add_btn'>
                                                                        <button className=''>Save to list</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='col-12 col-md-6 col-lg-3 pe-3 pe-md-0'>
                                                            <div className='filter_card'>
                                                                <div className='f_-card_img'>
                                                                    <img src={slidernew1} />
                                                                    {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                                </div>
                                                                <div className='f_card_detail'>
                                                                    <div className='card_count'>
                                                                        <div className='loc_app'> <h6>Neues Museum</h6>
                                                                            <p><span className=''>Bodestraße 1-3, 10178 Berlin, Germany </span></p></div>
                                                                        <div>   <label>#18,495</label></div>

                                                                    </div>
                                                                    <div className='dimen_app'>
                                                                        <h6>    Berlin</h6>
                                                                    </div>
                                                                    <div className='add_btn'>
                                                                        <button className=''>Save to list</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='col-12 col-md-6 col-lg-3 pe-0'>
                                                            <div className='filter_card'>
                                                                <div className='f_-card_img'>
                                                                    <img src={slidernew1} />
                                                                    {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                                </div>
                                                                <div className='f_card_detail'>
                                                                    <div className='card_count'>
                                                                        <div className='loc_app'> <h6>Neues Museum</h6>
                                                                            <p><span className=''>Bodestraße 1-3, 10178 Berlin, Germany </span></p></div>
                                                                        <div>   <label>#18,495</label></div>

                                                                    </div>
                                                                    <div className='dimen_app'>
                                                                        <h6>    Berlin</h6>
                                                                    </div>
                                                                    <div className='add_btn'>
                                                                        <button className=''>Save to list</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='col-12 col-md-6 col-lg-3'>
                                                            <div className='filter_card'>
                                                                <div className='f_-card_img'>
                                                                    <img src={slidernew1} />
                                                                    {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                                </div>
                                                                <div className='f_card_detail'>
                                                                    <div className='card_count'>
                                                                        <div className='loc_app'> <h6>Neues Museum</h6>
                                                                            <p><span className=''>Bodestraße 1-3, 10178 Berlin, Germany </span></p></div>
                                                                        <div>   <label>#18,495</label></div>

                                                                    </div>
                                                                    <div className='dimen_app'>
                                                                        <h6>    Berlin</h6>
                                                                    </div>
                                                                    <div className='add_btn'>
                                                                        <button className=''>Save to list</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='col-12 col-md-6 col-lg-3 pe-0'>
                                                            <div className='filter_card'>
                                                                <div className='f_-card_img'>
                                                                    <img src={slidernew1} />
                                                                    {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                                </div>
                                                                <div className='f_card_detail'>
                                                                    <div className='card_count'>
                                                                        <div className='loc_app'> <h6>Neues Museum</h6>
                                                                            <p><span className=''>Bodestraße 1-3, 10178 Berlin, Germany </span></p></div>
                                                                        <div>   <label>#18,495</label></div>

                                                                    </div>
                                                                    <div className='dimen_app'>
                                                                        <h6>    Berlin</h6>
                                                                    </div>
                                                                    <div className='add_btn'>
                                                                        <button className=''>Save to list</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='col-12 col-md-6 col-lg-3 pe-3 pe-md-0'>
                                                            <div className='filter_card'>
                                                                <div className='f_-card_img'>
                                                                    <img src={slidernew1} />
                                                                    {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                                </div>
                                                                <div className='f_card_detail'>
                                                                    <div className='card_count'>
                                                                        <div className='loc_app'> <h6>Neues Museum</h6>
                                                                            <p><span className=''>Bodestraße 1-3, 10178 Berlin, Germany </span></p></div>
                                                                        <div>   <label>#18,495</label></div>

                                                                    </div>
                                                                    <div className='dimen_app'>
                                                                        <h6>    Berlin</h6>
                                                                    </div>
                                                                    <div className='add_btn'>
                                                                        <button className=''>Save to list</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='col-12 col-md-6 col-lg-3 pe-0'>
                                                            <div className='filter_card'>
                                                                <div className='f_-card_img'>
                                                                    <img src={slidernew1} />
                                                                    {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                                </div>
                                                                <div className='f_card_detail'>
                                                                    <div className='card_count'>
                                                                        <div className='loc_app'> <h6>Neues Museum</h6>
                                                                            <p><span className=''>Bodestraße 1-3, 10178 Berlin, Germany </span></p></div>
                                                                        <div>   <label>#18,495</label></div>

                                                                    </div>
                                                                    <div className='dimen_app'>
                                                                        <h6>    Berlin</h6>
                                                                    </div>
                                                                    <div className='add_btn'>
                                                                        <button className=''>Save to list</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className='col-12 col-md-6 col-lg-3'>
                                                            <div className='filter_card'>
                                                                <div className='f_-card_img'>
                                                                    <img src={slidernew1} />
                                                                    {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                                </div>
                                                                <div className='f_card_detail'>
                                                                    <div className='card_count'>
                                                                        <div className='loc_app'> <h6>Neues Museum</h6>
                                                                            <p><span className=''>Bodestraße 1-3, 10178 Berlin, Germany </span></p></div>
                                                                        <div>   <label>#18,495</label></div>

                                                                    </div>
                                                                    <div className='dimen_app'>
                                                                        <h6>    Berlin</h6>
                                                                    </div>
                                                                    <div className='add_btn'>
                                                                        <button className=''>Save to list</button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </Tab>

                                            </Tabs>
                                        </div>



                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <Footer />
            </main>
            <div id="mySidenav" className={`sidenav newsticky_blog uniswap_filter museum_filter ${!navigationActive ? 'hide_nav' : ''}`}>
                <span className='ov_title'>Filter</span>
                <a href="javascript:void(0)" className="closebtn" onClick={() => closeNav()}>&times;</a>
                <div className='acc1_detail cate_select_check'>
                    <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                Sort By</Accordion.Header>
                            <Accordion.Body>
                                <div className='cat_all'>
                                    <div className='blog_tick_cat'>
                                        <div><span>Price: Low to High</span></div>

                                    </div>
                                    <div className='blog_tick_cat'>
                                        <div><span>Price: High to Low</span></div>

                                    </div>
                                    <div className='blog_tick_cat'>
                                        <div><span>Rarity: Rare to Common</span></div>

                                    </div>
                                    <div className='blog_tick_cat'>
                                        <div><span>Rarity: Common to Rare</span></div>

                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>


                </div>

            </div>

        </>
    )
}