import { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import HeaderNew2 from "../components/HeaderNew2";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import oreocoin from '../assets/images/oreo.png';
import pandcoin from '../assets/images/pand.png';
import "../assets/css/faq.scss";
import kpr from '../assets/images/kpr-cover.jpg';
import { Loader } from '../components/Loader';
import { LOADER_TIMER } from '../constants';
import FloatingSearch from '../components/FloatingSearch';
import overlayimg1 from '../assets/images/overlayimg1.jpg';
import loadingimg from '../assets/images/Loadingwhite.webm';
import logotech from '../assets/images/tchy logo.png';

export const BlogPost = () => {

    const [loader, setLoader] = useState(true);
    const [discoverSectionLoader, setDiscoverSectionLoader] = useState(false)
    const [isDiscoverSectionVisible, setIsDiscoverSectionVisible] = useState(false)
    const [timeout, setTimeoutState] = useState(null)
    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoader(false);
        }, LOADER_TIMER)
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [])

    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        // Get the current URL
        const currentURL = window.location.href;

        // Create a temporary textarea to copy the URL to the clipboard
        const tempTextArea = document.createElement('textarea');
        tempTextArea.value = currentURL;
        document.body.appendChild(tempTextArea);
        tempTextArea.select();
        document.execCommand('copy');
        document.body.removeChild(tempTextArea);

        // Set the copied state to true and reset it after a delay
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000); // Display the message for 2 seconds
    };
    useEffect(() => {
        if (discoverSectionLoader) {
            const timeout = setTimeout(() => {
                setDiscoverSectionLoader(false);
                setIsDiscoverSectionVisible(true);
                console.log(isDiscoverSectionVisible)
            }, 700)
            setTimeoutState(timeout)
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }


    }, [discoverSectionLoader])

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoader(false);
        }, LOADER_TIMER)
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [])

    const loaderActionDiscoverSection = (value) => {
        setDiscoverSectionLoader(value)
    }
    return (
        <>
            <Loader show={loader} />
            <main className='faq_main faq_component'>
                <HeaderNew2 />
                <section className='blogpost_page'>
                    <div className='container'>
                        <div className='blog_back'>
                            <a href='https://futur3.app/blog'><button className='btn'><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                            </svg></span>Back</button></a>
                        </div>
                        <div className='row'>
                            <div className='col-lg-5 order-lg-0 order-1'>
                                <div className='blogpost_left'>
                                    <span className="post_cat">New nomad spot </span>
                                    <h1>Undiscovered nomad <br /> spot: Pererenan, Bali.Tchy.de </h1>
                                    <div className="d-flex"><div className='d-flex align-items-center'>
                                        <div className="heep_detail2_logo">
                                            <img className='logoimg' src={logotech} />
                                        </div>

                                        <div class="info"><div class="hidden-sm new_color">Tchy.de </div>
                                        </div>
                                    </div></div>
                                    <div className='blogpost_timing'>
                                        <div><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar3" viewBox="0 0 16 16">
                                            <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                                            <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                                        </svg></span><span>Jan 6</span></div>
                                        {/* <div><span><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-clock-fill" viewBox="0 0 16 16">
                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                                        </svg></span><span>6 mins</span></div> */}
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-7'>
                                <div className='blogpost_right_img1'>
                                    <img src={kpr} />
                                </div>
                            </div>
                        </div>
                        <div className='blogpost_center_detail'>
                            <div className='blogpost_center'>
                                <h2>Pererenan, Bali will be the new digital nomad hotspot</h2>
                                <p>Located on the western coast of Bali, in the Tabanan regency, Pererenanis still an undiscovered gem for nomads. This village is known for its black sand beaches, rolling green rice fields, and traditional Balinese lifestyle. Pererenan is a relatively quiet and peaceful place, with a strong sense of community. <br /><br />

                                    Indonesia remains one of the favorite destinations for digital nomads with a low cost of living, solid infrastructure for remote work and a vibrant culture to explore. However, most areas in Bali have changed dramatically because of their popularity amongst travelers. If you want to avoid the crowds and rapidly growing cost of living in Canggu and Ubud, Pererenan might be the perfect spot.</p>
                                <h3>What makes Pererenan a great choice for nomads and remote workers?</h3>
                                <p><b>Written in collaboration with Will Hatton, </b><a>The Broke Backpacker</a><b> & Co-founder of </b><a>Tribal Bali</a></p>
                            </div>
                            <div className='blogpost_center_img2'>
                                <img src={kpr} />
                            </div>
                            <div className='blogpost_center'>
                                <p>I suggest this area of the island to anyone hoping to find a community of nomads and remote workers. Daily life in Pererenan, Bali is generally centered around the concept of "<i>Tri Hita Karana</i>," which translates to "three causes of well-being." This philosophy emphasizes the importance of maintaining harmony and balance with oneself, the people around you, and the environment.  <br /><br />

                                    Pererenan is less hectic than other parts of Bali. Out here, on the outskirts of Canggu, you can find some of the more relaxed Bali vibes that I fell in love with when I first visited the island. It’s an up-and-coming area with new cafes, restaurants, and places to hang out. It’s quieter and less expensive than more popular parts of the island. I find it a better place to work and live</p>
                                <p>My daily routine consists of taking my dogs for a walk along the beach, going to my local CrossFit, and then heading to the hostel & coworking space I started with two close friends on the island. It’s a great way to meet nomads and remote workers, hear about their life and projects they are passionate about. There are lots of hard-working entrepreneurs in Pererenan, making it relatively easy to connect with other like-minded folks while coworking. In the evenings, you’ll have many different events and gatherings to choose from. It’s easy to develop new hobbies here, whether it’s surfing, yoga or taking a local Balinese cooking lesson. For fitness enthusiasts, this island is a mecca.</p>
                            </div>
                            <div className='blogpost_center_img2'>
                                <img src={kpr} />
                            </div>
                            <div className='blogpost_center'>
                                <p>The local artisanal scene is incredible. There numerous small businesses selling handcrafted clothes, sculptures, and home decor. Pererenan also has great local restaurants preparing traditional Indonesian food. You can walk down the streets and be drawn in by the smell of Balinese spices and meat cooking over an open fire. I love that there are so many fresh and healthy food options easily accessible here. XimmoR, WOODS, Mostly, and RiZE are my favorite restaurants in the area. </p>
                                <p>The island of Bali is known for its beautiful beaches, so people usually spend time surfing or attending beach yoga practices. If you want to plan a day trip outside of Pererenan, swing over to Sidemen to see their famous swaying rice fields or head down to Sanur for some scuba diving. The fabulous Gili Islands and Nusa Penida are just a short ferry ride away. If you’re in the mood to party, check out the Kuta area! <br /><br />

                                    While Pererenan is gaining popularity amongst travelers looking for an off-the-beaten-track feel that Canggu, Ubud and Seminyak don’t offer anymore, this area might stand the test of tourism. Thanks to laws protecting the family temples in the area inherited by the Balinese locals and the green rice terraces, Pererenan has remained a quiet seaside village. The area is protected by the locals from excessive building and transformation to support tourism. It will remain largely free from overpriced Airbnbs and hotels. If you want to experience a true Balinese way of life and be a part of a small community that is involved with local businesses and daily routines, this is the spot for you. </p>
                                <div className='bottom_links'>
                                    <a onClick={() => copyToClipboard()} ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-link-45deg" viewBox="0 0 16 16">
                                        <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z" />
                                        <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z" />
                                    </svg></a>
                                    <a className='twitter-icon' href='https://twitter.com/futur3_cc'><svg id="Capa_1" enable-background="new 0 0 1226.37 1226.37" viewBox="0 0 1226.37 1226.37" xmlns="http://www.w3.org/2000/svg"><path d="m727.348 519.284 446.727-519.284h-105.86l-387.893 450.887-309.809-450.887h-357.328l468.492 681.821-468.492 544.549h105.866l409.625-476.152 327.181 476.152h357.328l-485.863-707.086zm-144.998 168.544-47.468-67.894-377.686-540.24h162.604l304.797 435.991 47.468 67.894 396.2 566.721h-162.604l-323.311-462.446z" /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /><g /></svg></a>
                                </div>
                               <div className='c_text'> {copied && <p>Copied to clipboard!</p>}</div>
                            </div>
                        </div>
                    </div>
                    <div className='container'>
                    <h3 className='also_like'>You might also like</h3>
                    <div className="heep_sec_detail heep_sec_detail2 mb-5 post_multi">
              
                        <div className="row">
                           
                            <div className="col-xl-12">
                                <div className="heep_sec_detail2_right">
                                    <div className="row">
                                
                                        <div className="col-md-6 col-lg-4 col-xl-4">
                                            <div className="heep_detail2_right_inner">
                                                <div className="heep_Del_left">
                                                    <div className='d-flex align-items-center'>
                                                        <div className="heep_detail2_logo">
                                                            <img src={pandcoin} />
                                                        </div>

                                                        <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                    </div>
                                                    <div><span className="cat_tag">Category</span></div>
                                                </div>
                                                <div className="heep_detail2_text pt-3">
                                                    <div className="heep_img_new">
                                                        <img src={overlayimg1} /></div>
                                                    <h3 className="heep_top_padding">Build a web app wide Lorem Ipsum has been the industry</h3>
                                                    <p className="mt-1">
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-4 col-xl-4">
                                            <div className="heep_detail2_right_inner">
                                                <div className="heep_Del_left">
                                                    <div className='d-flex align-items-center'>
                                                        <div className="heep_detail2_logo">
                                                            <img src={oreocoin} />
                                                        </div>

                                                        <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                    </div>
                                                    <div><span className="cat_tag">Category</span></div>
                                                </div>
                                                <div className="heep_detail2_text pt-3">
                                                    <div className="heep_img_new">
                                                        <img src={overlayimg1} /></div>
                                                    <h3 className="heep_top_padding">Modern website Lorem Ipsum has been the industry</h3>
                                                    <p className="mt-1">
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                    </p>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 col-lg-4 col-xl-4">
                                            <div className="heep_detail2_right_inner">
                                                <div className="heep_Del_left">
                                                    <div className='d-flex align-items-center'>
                                                        <div className="heep_detail2_logo">
                                                            <img src={pandcoin} />
                                                        </div>

                                                        <div class="info"><div class="hidden-sm">Metaverse </div></div>
                                                    </div>
                                                    <div><span className="cat_tag">Category</span></div>
                                                </div>
                                                <div className="heep_detail2_text pt-3">
                                                    <div className="heep_img_new">
                                                        <img src={overlayimg1} /></div>
                                                    <h3 className="heep_top_padding">Set-up automations Lorem Ipsum has been the industry text of the printing</h3>
                                                    <p className="mt-1">
                                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the
                                                    </p>

                                                </div>
                                            </div>

                                        </div>
                                        
                                    </div>
                                </div>

                            </div>

                        </div>
                     
                        <div className={`load_all_blogs`}>
                            <div class="more_proj dis_btn">
                                 <a href='/Blog'><button className="btn btn-outline-secondary btn-secondary mx-auto" >Discover More</button></a>
                               
                            </div>

                    
                        </div>

                    </div>

                </div>

                </section>
                <Footer />
                <FloatingSearch />
            </main>
        </>
    )
}