import { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import HeaderNew2 from "../components/HeaderNew2";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import "../assets/css/privacy.scss";
import { Loader } from '../components/Loader';
import { LOADER_TIMER } from '../constants';
import FloatingSearch from '../components/FloatingSearch';

export const Disclaimer = () => {
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoader(false);
        }, LOADER_TIMER)
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [])
    return (
        <>
            <Loader show={loader} />
            <main className='privacy_page_main'>
                <HeaderNew2 />
                <div className='container'>
                    <div class="coin_section_coin_section_head"><h3>Imprint</h3><p class="smll_text">last update - december 28, 2022</p></div>
                    <div className='privacypage_detail'>
                        <div>
                            <h3>Introduction</h3>
                            <p>The information provided by Futur3 (“We,” “Us” or “Our”) on the “Website” is for general
                                informational purposes only. All information on the Website is provided in good faith, however,
                                We make no representation or warranty of any kind, express or implied, regarding the accuracy,
                                adequacy, validity, reliability, availability, or completeness of any information on the Website.</p>
                            <p>Under no circumstance shall We have any liability to You for any loss or damage of any kind
                                incurred as a result of the use of the Website or reliance on any information provided on the
                                Website. Your use of the Website and Your reliance on any information on the Website is solely
                                at Your own risk.</p>

                        </div>
                        <div>
                            <h3>Contents</h3>
                            <ol>
                                <li>Consent</li>
                                <li>Content Disclaimer</li>
                                <li>External Links Disclaimer</li>
                                <li>Reviews</li>
                                <li>Earnings Disclaimer</li>
                                <li>Fair Use Notice</li>
                                <li>Personal Responsibility</li>
                                <li>Contact Us</li>
                                <li>Copyright Information</li>

                            </ol>
                        </div>
                        <div>
                            <h3>1. Consent</h3>
                            <p>By using the Website, You hereby consent to this Disclaimer and agree to its terms.</p>
                            <p>We will not be liable for any damages experienced in connection with the use of Our Website.</p>
                            <p><b>If You do not agree with this Disclaimer, STOP now and do not access or use this Website.</b></p>
                            <h3>2. Content Disclaimer</h3>
                            <p>We are not responsible or liable in any manner for any content posted on Our Website or in
                                connection with Our Website by third party. Although We provide rules for user conduct and
                                postings, We do not control and are not responsible for what the third-party post, transmit, or share
                                in connection to our website, and are not responsible for any offensive, inappropriate, obscene,
                                unlawful or otherwise objectionable content You may encounter. We are not responsible for the
                                conduct, whether online or offline, of any user of Our Website.</p>
                            <h3>3. External Links Disclaimer</h3>
                            <p>The Website may contain (or You may be sent through the Website links to other sites or content
                                belonging to or originating from third parties or links to sites and features in banners or other
                                advertising. We do not investigate, monitor, or check such external links for accuracy, adequacy,
                                validity, reliability, availability, or completeness.</p>
                            <p>We do not warrant, endorse, guarantee, or assume responsibility for the accuracy or reliability of
                                any information offered by third-party websites linked through the Website or any site or feature
                                linked in any banner or other advertising. We will not be a party to or in any way be responsible
                                for monitoring any transaction between You and third-party providers of products or services.</p>
                            <h3>4. Reviews</h3>
                            <p>At various times, We may provide reviews of products, services, or other resources. This may
                                include reviews of new tokens, cryptocurrencies, NFT platforms, Launchpads, Exchanges,
                                services, and/or software applications. Any such reviews will represent the good-faith opinions of
                                the author of such reviews. The products and services reviewed may be provided to Us for free or
                                at a reduced price as an incentive to provide a review.</p>
                            <p>Regardless of any such discounts, We will provide honest reviews of these products and/or
                                services. You recognize that You should conduct Your own due diligence and should not rely
                                solely upon any reviews provided on this Website. We will showcase if a review will be paid or
                                unpaid and while making any purchase decision, You should not rely on such reviews, but rather
                                on facts & figures.</p>
                            <p>If You would like more information about any such reviews, send an email to
                                <b>foundation@futur3.app</b> that includes the title of the reviewed product as the subject line. We
                                will respond via email and disclose any incentives or discounts We received in association with
                                any such review.</p>
                            <h3>5. Earnings Disclaimer</h3>
                            <p>From time to time, We may report on the success of one of Our existing or prior clients/customers.
                                The information about this success is accurately portrayed by the clients/customers. You
                                acknowledge that the prior success of others does not guarantee Your success.</p>
                            <p>As with any business, Your results may vary and will be based on Your individual capacity,
                                business experience, expertise, and level of desire. There are no guarantees concerning the level
                                of success You may experience. There is no guarantee that You will make any income at all and
                                You accept the risk that the earnings and income statements differ by individual. Each individual's
                                success depends on his or her background, dedication, desire, and motivation.</p>
                            <p>The use of Our information, products, and services should be based on Your own due diligence
                                and You agree that We are not liable for any success or failure of Your business that is directly or
                                indirectly related to the purchase and use of Our information, products, and services reviewed or
                                advertised on this Website.</p>
                            <h3>6. Fair Use Notice</h3>
                            <p>The Website contains copyrighted material the use of which has not always been specifically
                                authorized by the copyright owner. We believe this constitutes a “fair use” of any such copyrighted
                                material. If You wish to use copyrighted material from the Website for purposes of Your own that
                                go beyond fair use, You must obtain permission from the copyright owner.</p>
                            <h3>7. Personal Responsibility</h3>
                            <p>You acknowledge You are using Our Website voluntarily and that any choices, actions, and results
                                now and in the future are solely Your responsibility.</p>
                            <p>We will not be liable to You or any other party for any decision made or action taken in reliance
                                on the information given on the Website.</p>
                            <h3>8. Contact Us</h3>
                            <p>If You require any more information or have any questions about Our Website's disclaimer, please
                                feel free to contact Us at <b> foundation@futur3.app</b></p>
                            <h3>9. Copyright Information</h3>
                            <p>COPYRIGHT 2023 Futur3. ALL RIGHTS RESERVED.</p>

                        </div>
                    </div>
                </div>
                <Footer />
                <FloatingSearch />
            </main>
        </>
    )
}