import { useEffect, useState } from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../assets/css/faq.scss";
import { Loader } from '../components/Loader';
import { LOADER_TIMER } from '../constants';
import FloatingSearch from '../components/FloatingSearch';

export const NoPage = () => {

    const [loader, setLoader] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoader(false);
        }, LOADER_TIMER)
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [])

    return (
        <>
            <Loader show={loader} />
     <main className='nopage'>
     <Header />
<div className='container'>
<div className='nopagefound'>
    <p>404</p>
    <span>We can't find the page that you're looking for.</span>
    <a href="/">Take me back home</a>
</div>
</div>

     <Footer />
     <FloatingSearch />
        </main>
        </>
    )
}