import { useEffect, useState } from 'react';
import { Loader } from '../components/Loader';
import { LOADER_TIMER } from '../constants';
import '../assets/css/wise.scss';
import logo from '../assets/images/wiselogo.svg';
import down from '../assets/images/down.svg';
import flag from '../assets/images/de.svg';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import React from "react";
import Nav from 'react-bootstrap/Nav';
import Accordion from 'react-bootstrap/Accordion';

export const Wise = () => {
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        console.log(loader, "Loader");
        const timeout = setTimeout(() => {

            setLoader(false);
        }, LOADER_TIMER)
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [])
    return (
        <>
            <Loader show={loader} />
            <main>

                <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary header-top">
                    <Container>
                        <Navbar.Brand href="#home" className='logo'><img src={logo} rounded /></Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="m-auto nav-menu align-items-center nav">
                                <ul className='tabs tabs-left'>
                                    <li>
                                        <a href="" className='header_btn'>Persönlich</a>
                                    </li>
                                    <li>
                                        <a href="" className="active">Geschäftlich</a>
                                    </li>
                                </ul>

                                <ul className="menulinks">
                                    <li>
                                        <a href="">Zahlungen <img src={down} /> </a>
                                    </li>
                                    <li>
                                        <a href="">Karten <img src={down} /> </a>
                                    </li>
                                    <li>
                                        <a href="">Plattform <img src={down} /> </a>
                                    </li>
                                    <li>
                                        <a href="">Gebühren <img src={down} /> </a>
                                    </li>
                                    <li>
                                        <a href="">Hilfe <img src={down} /> </a>
                                    </li>
                                    <li>
                                        <a href=""><img src={flag} className="flag" />  DE</a>
                                    </li>
                                </ul>

                                <ul className='tabs left-0'>
                                    <li>
                                        <a href="">Einloggen</a>
                                    </li>
                                    <li>
                                        <a href="" className="active">Registrieren</a>
                                    </li>
                                </ul>
                            </Nav>

                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <section className='banner-section'>
                    <div className='container'>
                        <div className='banner_text'>
                            <h1>Das unkomplizierte inter-<br />nationale Geschäftskonto</h1>
                            <p>Business-Banking ohne hohe Preise, versteckte Kosten und monatliche Gebühren. Bezahle deine Angestellten, empfange Einzahlungen aus dem Ausland und verwalte deine internationalen Finanzen – alles an einem Ort.</p>
                            <div class="d-flex flex-wrap gap order-1 m-b-4 justify-content-center "><a href="/register?profileType=BUSINESS" class="btn btn-accent">Eröffne jetzt ein Geschäftskonto</a></div>
                        </div></div>
                </section>
                <div className='footerWise'>
<div className='container'>
    <div className='footer_content'>
        <h2>Häufig gestellte Fragen zu Wise Business</h2>
        <div className='footer_acc'>
        <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Was ist der Unterschied zwischen einem persönlichen Konto und einem Geschäftskonto bei Wise?</Accordion.Header>
        <Accordion.Body>
        Mit einem persönlichen Konto von Wise kannst du deine privaten internationalen Finanzen regeln – mit Wise Business kannst du dagegen deinem Unternehmen zu internationalem Wachstum verhelfen. Du kannst dein Geschäftskonto bei Wise Business etwa mit Buchhaltungsprogrammen verknüpfen, Ausgaben von Mitarbeitern verwalten, deinen Teammitgliedern Zugriff auf das Konto ermöglichen und noch vieles mehr. Für einige Währungen gibt es Einschränkungen für Unternehmen – mehr dazu kannst du hier erfahren.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Wie qualifiziere ich mich für ein Geschäftskonto?</Accordion.Header>
        <Accordion.Body>
        Du kannst ein Geschäftskonto bei Wise eröffnen, wenn du ein Einzelunternehmer, ein Freelancer oder der Inhaber einer GmbH, einer Aktiengesellschaft, einer Partnerschaft, einer Wohltätigkeitsorganisation oder eines Treuhandunternehmens (in bestimmten Regionen) bist. Du kannst hier mehr dazu erfahren.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Wie qualifiziere ich mich für ein Geschäftskonto?</Accordion.Header>
        <Accordion.Body>
        Du kannst ein Geschäftskonto bei Wise eröffnen, wenn du ein Einzelunternehmer, ein Freelancer oder der Inhaber einer GmbH, einer Aktiengesellschaft, einer Partnerschaft, einer Wohltätigkeitsorganisation oder eines Treuhandunternehmens (in bestimmten Regionen) bist. Du kannst hier mehr dazu erfahren.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Wie lange dauert die Verifizierung?</Accordion.Header>
        <Accordion.Body>
        Wir bemühen uns, die Verifizierung von Unternehmen in 2-3 Werktagen abzuschließen. Sie können hier mehr über den Verifizierungsprozess erfahren.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>Kann ich ein Geschäftskonto bei Wise eröffnen, ohne dafür Geld einzubezahlen?</Accordion.Header>
        <Accordion.Body>
        Du kannst ein Geschäftskonto bei Wise eröffnen, wenn du ein Einzelunternehmer, ein Freelancer oder der Inhaber einer GmbH, einer Aktiengesellschaft, einer Partnerschaft, einer Wohltätigkeitsorganisation oder eines Treuhandunternehmens (in bestimmten Regionen) bist. Du kannst hier mehr dazu erfahren.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
        </div>
    </div>
</div>
                </div>
            </main>

        </>
    )
}