import { useEffect, useState } from 'react';
import { Loader } from '../components/Loader';
import { LOADER_TIMER } from '../constants';
import '../assets/css/uniswap.scss';
import '../assets/css/search.scss';
import Footer from "../components/Footer";
import HeaderNew2 from "../components/HeaderNew2";
import React from "react";
import utick from '../assets/images/u_tick.svg';
import filcard from '../assets/images/u_filter.png';
import ulogo from '../assets/images/ulogo.svg';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Ccard from '../assets/images/tfbank_mc_gold.png';

export const Search = () => {
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        console.log(loader, "Loader");
        const timeout = setTimeout(() => {

            setLoader(false);
        }, LOADER_TIMER)
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [])
    return (
        <>
            <Loader show={loader} />
            <main>
                <HeaderNew2 />
                <div className='uniswap_body'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <div className='section_left'>
                                    <div className='section_head'>
                                        <h5>Mutant Ape Yacht Club</h5>  <img src={utick} />
                                    </div>
                                    <p>The MUTANT APE YACHT CLUB is a collection of up to 20,000 Mutant Apes that can only be created by exposing an existing Bored Ape to a vial of MUTANT SERUM or by minting a Mutant Ape in the public sale.</p>
                                    <div className='count_head'>
                                        <div className='count_del'> <label>4.87 ETH</label>
                                            <p>Global floor</p>  </div>
                                        <div className='count_del'> <label className='text_red'><svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="down" class="Delta__StyledDownArrow-sc-358d6432-1 eNRaUT"><path d="M10.6979 16.2453L6.31787 9.75247C5.58184 8.66118 6.2058 7 7.35185 7L16.6482 7C17.7942 7 18.4182 8.66243 17.6821 9.75247L13.3021 16.2453C12.623 17.2516 11.377 17.2516 10.6979 16.2453Z" fill="currentColor"></path></svg>2%</label>
                                            <p>Floor 24H</p>  </div>
                                        <div className='count_del'> <label>1M ETH</label>
                                            <p>Global floor</p>  </div>
                                        <div className='count_del'> <label>19.5k</label>
                                            <p>Global floor</p>  </div>
                                        <div className='count_del'> <label>59%</label>
                                            <p>Global floor</p>  </div>
                                        <div className='count_del'> <label>3%</label>
                                            <p>Global floor</p>  </div>
                                    </div>
                                    <div className='filter_head'>
                                        <div className='filter_top '>
                                            <button>Activity</button>
                                        </div>
                                        <div className='table-responsive'>
                                            <table className="table table-control act_table">
                                                <thead>
                                                    <tr>
                                                        <th colspan="6" width="25%">Item</th>
                                                        <th scope="col" width="8%">Event </th>
                                                        <th scope="col" width="15%">Price </th>
                                                        <th scope="col" width="15%">By </th>


                                                        <th scope="col" width="15%">To </th>
                                                        <th scope="col" width="8%" className="text-center"> </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colspan="6">
                                                            <div className="act_detail_main">
                                                                <div className="act_logo"><img src={Ccard} /></div>
                                                                <div className="act_detail">
                                                                    <div class="act_info">
                                                                        <div><a href="#"><strong></strong></a><strong>TF Mastercard Gold</strong></div>
                                                                        <div class="hidden-sm">Archive</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><div className='act_box'><div className="actimg"><div><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tag" viewBox="0 0 16 16">
                                                            <path d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z" />
                                                            <path d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586 7 7L13.586 9l-7-7H2v4.586z" />
                                                        </svg><span className="ps-2">Listed</span></div>
                                                            <p>44 minutes ago</p></div></div></td>
                                                        <td>5.075 ETH</td>
                                                        <td>0xd4...AA1C</td>
                                                        <td>-</td>
                                                        <td className="text-center act_drpdwn_btn">
                                                            <button className="new-bg-act"><span>Remove</span></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="6">
                                                            <div className="act_detail_main">
                                                                <div className="act_logo"><img src={Ccard} /></div>
                                                                <div className="act_detail">
                                                                    <div class="act_info">
                                                                        <div><a href="#"><strong></strong></a><strong>TF Mastercard Gold</strong></div>
                                                                        <div class="hidden-sm">Archive</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><div className='act_box'><div className="actimg"><div><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tag" viewBox="0 0 16 16">
                                                            <path d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z" />
                                                            <path d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586 7 7L13.586 9l-7-7H2v4.586z" />
                                                        </svg><span className="ps-2">Listed</span></div>
                                                            <p>44 minutes ago</p></div></div></td>
                                                        <td>5.075 ETH</td>
                                                        <td>0xd4...AA1C</td>
                                                        <td>-</td>
                                                        <td className="text-center act_drpdwn_btn">
                                                            <button className="new-bg-act cancl_btn"><span>cancelled</span></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="6">
                                                            <div className="act_detail_main">
                                                                <div className="act_logo"><img src={Ccard} /></div>
                                                                <div className="act_detail">
                                                                    <div class="act_info">
                                                                        <div><a href="#"><strong></strong></a><strong>TF Mastercard Gold</strong></div>
                                                                        <div class="hidden-sm">Archive</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><div className='act_box'><div className="actimg"><div><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tag" viewBox="0 0 16 16">
                                                            <path d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z" />
                                                            <path d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586 7 7L13.586 9l-7-7H2v4.586z" />
                                                        </svg><span className="ps-2">Listed</span></div>
                                                            <p>44 minutes ago</p></div></div></td>
                                                        <td>5.075 ETH</td>
                                                        <td>0xd4...AA1C</td>
                                                        <td>-</td>
                                                        <td className="text-center act_drpdwn_btn">
                                                            <button className="new-bg-act"><span>Remove</span></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="6">
                                                            <div className="act_detail_main">
                                                                <div className="act_logo"><img src={Ccard} /></div>
                                                                <div className="act_detail">
                                                                    <div class="act_info">
                                                                        <div><a href="#"><strong></strong></a><strong>TF Mastercard Gold</strong></div>
                                                                        <div class="hidden-sm">Archive</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><div className='act_box'><div className="actimg"><div><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tag" viewBox="0 0 16 16">
                                                            <path d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z" />
                                                            <path d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586 7 7L13.586 9l-7-7H2v4.586z" />
                                                        </svg><span className="ps-2">Listed</span></div>
                                                            <p>44 minutes ago</p></div></div></td>
                                                        <td>5.075 ETH</td>
                                                        <td>0xd4...AA1C</td>
                                                        <td>-</td>
                                                        <td className="text-center act_drpdwn_btn">
                                                            <button className="new-bg-act"><span>Remove</span></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="6">
                                                            <div className="act_detail_main">
                                                                <div className="act_logo"><img src={Ccard} /></div>
                                                                <div className="act_detail">
                                                                    <div class="act_info">
                                                                        <div><a href="#"><strong></strong></a><strong>TF Mastercard Gold</strong></div>
                                                                        <div class="hidden-sm">Archive</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><div className='act_box'><div className="actimg"><div><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tag" viewBox="0 0 16 16">
                                                            <path d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z" />
                                                            <path d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586 7 7L13.586 9l-7-7H2v4.586z" />
                                                        </svg><span className="ps-2">Listed</span></div>
                                                            <p>44 minutes ago</p></div></div></td>
                                                        <td>5.075 ETH</td>
                                                        <td>0xd4...AA1C</td>
                                                        <td>-</td>
                                                        <td className="text-center act_drpdwn_btn">
                                                            <button className="new-bg-act"><span>Remove</span></button>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="6">
                                                            <div className="act_detail_main">
                                                                <div className="act_logo"><img src={Ccard} /></div>
                                                                <div className="act_detail">
                                                                    <div class="act_info">
                                                                        <div><a href="#"><strong></strong></a><strong>TF Mastercard Gold</strong></div>
                                                                        <div class="hidden-sm">Archive</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td><div className='act_box'><div className="actimg"><div><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tag" viewBox="0 0 16 16">
                                                            <path d="M6 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm-1 0a.5.5 0 1 0-1 0 .5.5 0 0 0 1 0z" />
                                                            <path d="M2 1h4.586a1 1 0 0 1 .707.293l7 7a1 1 0 0 1 0 1.414l-4.586 4.586a1 1 0 0 1-1.414 0l-7-7A1 1 0 0 1 1 6.586V2a1 1 0 0 1 1-1zm0 5.586 7 7L13.586 9l-7-7H2v4.586z" />
                                                        </svg><span className="ps-2">Listed</span></div>
                                                            <p>44 minutes ago</p></div></div></td>
                                                        <td>5.075 ETH</td>
                                                        <td>0xd4...AA1C</td>
                                                        <td>-</td>
                                                        <td className="text-center act_drpdwn_btn">
                                                            <button className="new-bg-act"><span>Remove</span></button>
                                                        </td>
                                                    </tr>

                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

<Footer />

            </main>

        </>
    )
}