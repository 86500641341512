import { useEffect, useState } from 'react';
import HeaderNew2 from "../components/HeaderNew2";
import Footer from "../components/Footer";
import Dropdown from 'react-bootstrap/Dropdown';
import { Loader } from "../components/Loader";
import { LOADER_TIMER, WebinarAndVirtualEventsItems, primarilywebinarEventsItems} from '../constants';
import "../assets/css/contact.scss";

export const Contact = () => {
    const [loader, setLoader] = useState(true);
    const [webinarAndVirtualEvents, setWebinarAndVirtualEvents] = useState(null);
    const [primarilywebinarEvents, setprimarilywebinarEvents] = useState(null);
    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoader(false);
        }, LOADER_TIMER)
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [])

    return (
        <>
        <Loader show={loader} />
            <main className="contct_page">
                <HeaderNew2 />
                <section className="contact_details">
                    <div className="container contact_container">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="contact_del_left">
                                    <h1>Unlock<br />
                                        engagement</h1>
                                    <p>Manage your experience from start to finish. From integrations to registration and from interactive stage elements to post event data, it’s all here.</p>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="contact_del_right">
                                    <div className="contact_del_first contact_input">
                                        <div>
                                            <label>First Name<span>*</span></label>
                                            <input type="text" className="form-control" name="firstname" placeholder="First name"></input>
                                        </div>
                                        <div className="ms-3">
                                            <label>Last Name<span>*</span></label>
                                            <input type="text" className="form-control" name="lastname" placeholder="Last name"></input>
                                        </div>
                                    </div>
                                    <div className="contact_input">
                                            <label>Work Email<span>*</span></label>
                                            <input type="text" className="form-control" name="workemail*" placeholder="Work email"></input>
                                        </div>
                                        <div className="contact_input">
                                            <label>Job Title<span>*</span></label>
                                            <input type="text" className="form-control" name="jobTitle*" placeholder="Job Title"></input>
                                        </div>
                                        <div className="contact_input">
                                            <label>How frequently do you run webinars/virtual events?<span>*</span></label>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="btn" id="dropdown-basic" className="form-control"><span>{webinarAndVirtualEvents ? webinarAndVirtualEvents.label: "Please Select"}</span></Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {
                                                        WebinarAndVirtualEventsItems.map(({value, label}) =>(
                                                            <Dropdown.Item key={value} onClick={(e) => {
                                                                setWebinarAndVirtualEvents({ value, label})
                                                            }}>{ label }</Dropdown.Item>
                                                        ))
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="contact_input">
                                            <label>What do you primarily use webinars/virtual events for?<span>*</span></label>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="btn" id="dropdown-basic" className="form-control"><span>{primarilywebinarEvents ? primarilywebinarEvents.label: "Please Select"}</span></Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                {
                                                        primarilywebinarEventsItems.map(({value, label}) =>(
                                                            <Dropdown.Item key={value} onClick={(e) => {
                                                                setprimarilywebinarEvents({ value, label})
                                                            }}>{ label }</Dropdown.Item>
                                                        ))
                                                    }
                                        

                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="contact_input">
                                            <label>How did you hear about us?<span>*</span></label>
                                            <textarea className="form-control contct_textarea" name="aboutus*" placeholder=""></textarea>
                                        </div>
                                        <div className="contact_input contc_check">
                                        <label class="cnt">
                                            <input type="checkbox"/>
                                            <span className="checkmark"></span>
                                        </label>
                                        <span className="pull-down">I agree to receive product updates and marketing emails from Welcome (recommended)</span>

                                        </div>
                                        <div className="contc_sub"><button className="btn btn-outline-secondary btn-secondary">Submit</button></div>
                                        
                                </div>


                            </div>
                        </div>
                    </div>

                </section>
                <Footer />
            </main>
        </>
    )
}