import { useEffect, useState } from 'react';
import { Loader } from '../components/Loader';
import { LOADER_TIMER } from '../constants';
import '../assets/css/uniswap2.scss';
import React from "react";
import Footer from "../components/Footer";
import utick from '../assets/images/u_tick.svg';
import filcard from '../assets/images/u_filter.png';
import ulogo from '../assets/images/ulogo.svg';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import HeaderNew2 from "../components/HeaderNew2";
import Dropdown from 'react-bootstrap/Dropdown';


export const Uniswap2 = () => {
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        console.log(loader, "Loader");
        const timeout = setTimeout(() => {

            setLoader(false);
        }, LOADER_TIMER)
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [])
    return (
        <>
            <Loader show={loader} />
            <main>
                <HeaderNew2 />
            
         <div className='uniswap_body'>
         <div className='container'>
                    <div className='row'>
                        <div className='col-md-7 col-lg-9 sticky_left'>
                            <div className='section_left'>
                                <div className='section_head'>
                                    <h5>Mutant Ape Yacht Club</h5>  <img src={utick} />
                                </div>
                                <p>The MUTANT APE YACHT CLUB is a collection of up to 20,000 Mutant Apes that can only be created by exposing an existing Bored Ape to a vial of MUTANT SERUM or by minting a Mutant Ape in the public sale.</p>
                                <div className='count_head'>
                                    <div className='count_del'> <label>4.87 ETH</label>
                                        <p>Global floor</p>  </div>
                                    <div className='count_del'> <label className='text_red'><svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" aria-label="down" class="Delta__StyledDownArrow-sc-358d6432-1 eNRaUT"><path d="M10.6979 16.2453L6.31787 9.75247C5.58184 8.66118 6.2058 7 7.35185 7L16.6482 7C17.7942 7 18.4182 8.66243 17.6821 9.75247L13.3021 16.2453C12.623 17.2516 11.377 17.2516 10.6979 16.2453Z" fill="currentColor"></path></svg>2%</label>
                                        <p>Floor 24H</p>  </div>
                                    <div className='count_del'> <label>1M ETH</label>
                                        <p>Global floor</p>  </div>
                                    <div className='count_del'> <label>19.5k</label>
                                        <p>Global floor</p>  </div>
                                    <div className='count_del'> <label>59%</label>
                                        <p>Global floor</p>  </div>
                                    <div className='count_del'> <label>3%</label>
                                        <p>Global floor</p>  </div>
                                </div>
                                <div className='filter_head'>
                                    <div className='filter_top'>
                                        <button>Items</button>
                                    </div>
                                  <div className='sti_filter'>
                                  <div className='filter_search'>
                                        <div className='fil_left'>
                                            <div className='c_fil filter'>
                                                <svg className='me-2' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill="currentColor" d="M5.5 3H18.5C19.328 3 20 3.672 20 4.5V6.75699C20 7.15499 19.842 7.53599 19.561 7.81799L14.4399 12.939C14.1589 13.22 14.001 13.602 14.001 14V21L10.001 18V14C10.001 13.602 9.84302 13.221 9.56202 12.939L4.44104 7.81799C4.16004 7.53699 4.00196 7.15499 4.00196 6.75699V4.5C3.99996 3.672 4.672 3 5.5 3Z"></path></svg>
                                                <span>Filter • 506 results</span>
                                            </div>
                                            {/* <div className='c_fil filter'>
                                                <svg width="20" height="20" viewBox="0 3 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.4834 5.71191C19.0879 5.29883 18.4727 5.30762 18.0859 5.71191L13.6562 10.2471C13.4805 10.4229 13.3662 10.6953 13.3662 10.9326C13.3662 11.4863 13.7529 11.8643 14.2979 11.8643C14.5615 11.8643 14.7725 11.7764 14.9482 11.5918L16.7588 9.71094L17.9189 8.375L17.8486 10.2383L17.8486 21.6465C17.8486 22.1914 18.2441 22.5869 18.7891 22.5869C19.334 22.5869 19.7207 22.1914 19.7207 21.6465L19.7207 10.2383L19.6592 8.375L20.8105 9.71094L22.6211 11.5918C22.7969 11.7764 23.0166 11.8643 23.2803 11.8643C23.8164 11.8643 24.2031 11.4863 24.2031 10.9326C24.2031 10.6953 24.0889 10.4229 23.9131 10.2471L19.4834 5.71191ZM7.84668 22.2793C8.24218 22.6924 8.85742 22.6836 9.24414 22.2793L13.6738 17.7529C13.8496 17.5684 13.9639 17.2959 13.9639 17.0586C13.9639 16.5137 13.5771 16.1357 13.0322 16.1357C12.7773 16.1357 12.5576 16.2236 12.3818 16.3994L10.5713 18.2803L9.41992 19.6162L9.48144 17.7529L9.48144 6.34473C9.48144 5.80859 9.08594 5.4043 8.54101 5.4043C8.00488 5.4043 7.60937 5.80859 7.60937 6.34473L7.60937 17.7529L7.6709 19.6162L6.51953 18.2803L4.70898 16.3994C4.5332 16.2236 4.31347 16.1357 4.05859 16.1357C3.51367 16.1357 3.12695 16.5137 3.12695 17.0586C3.12695 17.2959 3.24121 17.5684 3.41699 17.7529L7.84668 22.2793Z" fill="currentColor"></path></svg>                            <span>Price: Low to High</span>
                                            </div> */}
                                            <div className=' stay_fil pric_high'>
                                            <Dropdown>
      <Dropdown.Toggle variant="" id="dropdown-basic" className='high_lw_dropdown'>
<div>      <svg className='me-2' width="20" height="20" viewBox="0 3 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.4834 5.71191C19.0879 5.29883 18.4727 5.30762 18.0859 5.71191L13.6562 10.2471C13.4805 10.4229 13.3662 10.6953 13.3662 10.9326C13.3662 11.4863 13.7529 11.8643 14.2979 11.8643C14.5615 11.8643 14.7725 11.7764 14.9482 11.5918L16.7588 9.71094L17.9189 8.375L17.8486 10.2383L17.8486 21.6465C17.8486 22.1914 18.2441 22.5869 18.7891 22.5869C19.334 22.5869 19.7207 22.1914 19.7207 21.6465L19.7207 10.2383L19.6592 8.375L20.8105 9.71094L22.6211 11.5918C22.7969 11.7764 23.0166 11.8643 23.2803 11.8643C23.8164 11.8643 24.2031 11.4863 24.2031 10.9326C24.2031 10.6953 24.0889 10.4229 23.9131 10.2471L19.4834 5.71191ZM7.84668 22.2793C8.24218 22.6924 8.85742 22.6836 9.24414 22.2793L13.6738 17.7529C13.8496 17.5684 13.9639 17.2959 13.9639 17.0586C13.9639 16.5137 13.5771 16.1357 13.0322 16.1357C12.7773 16.1357 12.5576 16.2236 12.3818 16.3994L10.5713 18.2803L9.41992 19.6162L9.48144 17.7529L9.48144 6.34473C9.48144 5.80859 9.08594 5.4043 8.54101 5.4043C8.00488 5.4043 7.60937 5.80859 7.60937 6.34473L7.60937 17.7529L7.6709 19.6162L6.51953 18.2803L4.70898 16.3994C4.5332 16.2236 4.31347 16.1357 4.05859 16.1357C3.51367 16.1357 3.12695 16.5137 3.12695 17.0586C3.12695 17.2959 3.24121 17.5684 3.41699 17.7529L7.84668 22.2793Z" fill="currentColor"></path></svg>
         Price: Low to High</div>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1"><span>Price: Low to High</span>
  </Dropdown.Item>
        <Dropdown.Item href="#/action-2"><span>Price: High to Low</span></Dropdown.Item>
        <Dropdown.Item href="#/action-3"><span>Rarity: Rare to Common</span></Dropdown.Item>
<Dropdown.Item href="#/action-3"><span>Rarity: Common to Rare</span> </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
                                            </div>
                                            <div className=' stay_fil'>
                                            <Dropdown>
      <Dropdown.Toggle variant="" id="dropdown-basic">
      All Types Of Stay
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1"><label class="check_custom">All Types Of Stay
  <input type="checkbox" checked="" className='drop_input' />
  <span class="checkmark"></span>
</label> 
  </Dropdown.Item>
        <Dropdown.Item href="#/action-2"><label class="check_custom">Private Room
  <input type="checkbox" checked="" className='drop_input' />
  <span class="checkmark"></span>
</label> </Dropdown.Item>
        <Dropdown.Item href="#/action-3"><label class="check_custom">Shared Room
  <input type="checkbox" checked="" className='drop_input' />
  <span class="checkmark"></span>
</label> </Dropdown.Item>
<Dropdown.Item href="#/action-3"><label class="check_custom">Private Appartment
  <input type="checkbox" checked="" className='drop_input' />
  <span class="checkmark"></span>
</label> </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
                                            </div>
                                            <div className=' stay_fil city_fill'>
                                            <Dropdown>
      <Dropdown.Toggle variant="" id="dropdown-basic">
     City
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#/action-1"><label class="check_custom">Berlin
  <input type="checkbox" checked="" className='drop_input' />
  <span class="checkmark"></span>
</label> 
  </Dropdown.Item>
        <Dropdown.Item href="#/action-2"><label class="check_custom">

        Ingolstadt
  <input type="checkbox" checked="" className='drop_input' />
  <span class="checkmark"></span>
</label> </Dropdown.Item>
        <Dropdown.Item href="#/action-3"><label class="check_custom">
Koblenz
  <input type="checkbox" checked="" className='drop_input' />
  <span class="checkmark"></span>
</label> </Dropdown.Item>
<Dropdown.Item href="#/action-3"><label class="check_custom">
Chemnitz
  <input type="checkbox" checked="" className='drop_input' />
  <span class="checkmark"></span>
</label> </Dropdown.Item>
<Dropdown.Item href="#/action-3"><label class="check_custom">

Frankfurt
  <input type="checkbox" checked="" className='drop_input' />
  <span class="checkmark"></span>
</label> </Dropdown.Item>
<Dropdown.Item href="#/action-3"><label class="check_custom">

Dresden
  <input type="checkbox" checked="" className='drop_input' />
  <span class="checkmark"></span>
</label> </Dropdown.Item>
<Dropdown.Item href="#/action-3"><label class="check_custom">
Stuttgart
  <input type="checkbox" checked="" className='drop_input' />
  <span class="checkmark"></span>
</label> </Dropdown.Item>
<Dropdown.Item href="#/action-3"><label class="check_custom">

Cologne
  <input type="checkbox" checked="" className='drop_input' />
  <span class="checkmark"></span>
</label> </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
                                            </div>
                                            
                                            {/* <input type='text' className='input_filter' placeholder='Search By Name' /> */}
                                        </div>
                                        <div className='fil_right'>
                                            <button className='sw_btn'><svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20px" height="20px"><path d="M13.4177 11.9534C12.3508 11.6675 11.2541 12.3006 10.9682 13.3676C9.90129 13.0817 8.80461 13.7148 8.51873 14.7818L8.25991 15.7477M13.4177 11.9534C14.4846 12.2392 15.1178 13.3359 14.8319 14.4028C15.8989 14.6887 16.532 15.7855 16.2461 16.8524L15.9873 17.8183M13.4177 11.9534L16.0059 2.2941M8.25991 15.7477L15.9873 17.8183M8.25991 15.7477C8.25991 15.7477 7.74227 17.6796 7.48345 18.6455C7.22463 19.6114 5.99989 20.3185 5.99989 20.3185C9.86359 21.3538 12.3131 19.9396 12.3131 19.9396L11.7954 21.8714C13.4053 22.3028 14.9197 21.8027 15.2109 20.716L15.9873 17.8183" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path><path d="M5.18229 6.58808C5.25706 6.38601 5.54287 6.38601 5.61764 6.58808C5.99377 7.60457 6.79521 8.406 7.8117 8.78214C8.01377 8.85691 8.01377 9.14272 7.8117 9.21749C6.79521 9.59363 5.99377 10.3951 5.61764 11.4116C5.54286 11.6136 5.25706 11.6136 5.18229 11.4116C4.80615 10.3951 4.00471 9.59363 2.98822 9.21749C2.78615 9.14272 2.78615 8.85691 2.98822 8.78214C4.00471 8.406 4.80615 7.60457 5.18229 6.58808Z" fill="currentColor"></path></svg><span>Adjust</span></button>
                                        </div>

                                    </div>
                                    <div className='filter_thumb'>
                                        <div className='filter_t_inpt'>  <div className=''> <input type='text' className='input_filter' placeholder='0' />
                                            </div>
                                            <input type="range" min="1" max="100" value="0" class="slider" id="myRange" />
                                        </div>
                                        <div className='filter_count'>
                                            <div className=''> <input type='text' className='input_filter' placeholder='30' />
                                            </div>
                                            <div className='filter_tab'><label className='active'>Price</label><label className='me-0'>Size</label></div>
                                            <div className='react_filter'>
                                                <p>€ 0</p>
                                                {/* <div className='reactangle'></div> */}
                                            </div>
                                        </div>
                                    </div>
                                  </div>
                                    <div className='row'>
                                    <div className='col-md-6'>
                                            <div className='filter_card'>
                                                <div className='f_-card_img'>
                                                    <img src={filcard} />
                                                    {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                </div>
                                                <div className='f_card_detail'>
                                                    <div className='card_count'>
                                                       <div className='loc_app'> <h6>1 room apartment with terrace in Wilmersdorf (Berlin)</h6>
                                                        <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
</svg><span className='ms-2'>Zechliner Straße 1a Berlin, Wedding </span></p></div>
                                                     <div>   <label>#18,495</label></div>
                                                      
                                                    </div>
                                                    <div className='dimen_app'>
                                                        <div>
                                                        <h6>2244 €</h6>
                                                        <p>Kaltmiete</p>
                                                        </div>
                                                        <div>
                                                        <h6>88,99 m<sup>2</sup></h6>
                                                        <p>Wohnfinance</p>
                                                        </div>
                                                        <div>
                                                        <h6>4</h6>
                                                        <p>Zi</p>
                                                        </div>
                                                    </div>
                                                    <div className='add_btn'>
                                                        <button className=''>Add to Bag</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='col-md-6'>
                                            <div className='filter_card'>
                                                <div className='f_-card_img'>
                                                    <img src={filcard} />
                                                    {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                </div>
                                                <div className='f_card_detail'>
                                                    <div className='card_count'>
                                                       <div className='loc_app'> <h6>1 room apartment with terrace in Wilmersdorf (Berlin)</h6>
                                                        <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
</svg><span className='ms-2'>Zechliner Straße 1a Berlin, Wedding </span></p></div>
                                                     <div>   <label>#18,495</label></div>
                                                      
                                                    </div>
                                                    <div className='dimen_app'>
                                                        <div>
                                                        <h6>2244 €</h6>
                                                        <p>Kaltmiete</p>
                                                        </div>
                                                        <div>
                                                        <h6>88,99 m<sup>2</sup></h6>
                                                        <p>Wohnfinance</p>
                                                        </div>
                                                        <div>
                                                        <h6>4</h6>
                                                        <p>Zi</p>
                                                        </div>
                                                    </div>
                                                    <div className='add_btn'>
                                                        <button className=''>Add to Bag</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='col-md-6'>
                                            <div className='filter_card'>
                                                <div className='f_-card_img'>
                                                    <img src={filcard} />
                                                    {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                </div>
                                                <div className='f_card_detail'>
                                                    <div className='card_count'>
                                                       <div className='loc_app'> <h6>1 room apartment with terrace in Wilmersdorf (Berlin)</h6>
                                                        <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
</svg><span className='ms-2'>Zechliner Straße 1a Berlin, Wedding </span></p></div>
                                                     <div>   <label>#18,495</label></div>
                                                      
                                                    </div>
                                                    <div className='dimen_app'>
                                                        <div>
                                                        <h6>2244 €</h6>
                                                        <p>Kaltmiete</p>
                                                        </div>
                                                        <div>
                                                        <h6>88,99 m<sup>2</sup></h6>
                                                        <p>Wohnfinance</p>
                                                        </div>
                                                        <div>
                                                        <h6>4</h6>
                                                        <p>Zi</p>
                                                        </div>
                                                    </div>
                                                    <div className='add_btn'>
                                                        <button className=''>Add to Bag</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='col-md-6'>
                                            <div className='filter_card'>
                                                <div className='f_-card_img'>
                                                    <img src={filcard} />
                                                    {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                </div>
                                                <div className='f_card_detail'>
                                                    <div className='card_count'>
                                                       <div className='loc_app'> <h6>1 room apartment with terrace in Wilmersdorf (Berlin)</h6>
                                                        <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
</svg><span className='ms-2'>Zechliner Straße 1a Berlin, Wedding </span></p></div>
                                                     <div>   <label>#18,495</label></div>
                                                      
                                                    </div>
                                                    <div className='dimen_app'>
                                                        <div>
                                                        <h6>2244 €</h6>
                                                        <p>Kaltmiete</p>
                                                        </div>
                                                        <div>
                                                        <h6>88,99 m<sup>2</sup></h6>
                                                        <p>Wohnfinance</p>
                                                        </div>
                                                        <div>
                                                        <h6>4</h6>
                                                        <p>Zi</p>
                                                        </div>
                                                    </div>
                                                    <div className='add_btn'>
                                                        <button className=''>Add to Bag</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='col-md-6'>
                                            <div className='filter_card'>
                                                <div className='f_-card_img'>
                                                    <img src={filcard} />
                                                    {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                </div>
                                                <div className='f_card_detail'>
                                                    <div className='card_count'>
                                                       <div className='loc_app'> <h6>1 room apartment with terrace in Wilmersdorf (Berlin)</h6>
                                                        <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
</svg><span className='ms-2'>Zechliner Straße 1a Berlin, Wedding </span></p></div>
                                                     <div>   <label>#18,495</label></div>
                                                      
                                                    </div>
                                                    <div className='dimen_app'>
                                                        <div>
                                                        <h6>2244 €</h6>
                                                        <p>Kaltmiete</p>
                                                        </div>
                                                        <div>
                                                        <h6>88,99 m<sup>2</sup></h6>
                                                        <p>Wohnfinance</p>
                                                        </div>
                                                        <div>
                                                        <h6>4</h6>
                                                        <p>Zi</p>
                                                        </div>
                                                    </div>
                                                    <div className='add_btn'>
                                                        <button className=''>Add to Bag</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='col-md-6'>
                                            <div className='filter_card'>
                                                <div className='f_-card_img'>
                                                    <img src={filcard} />
                                                    {/* <div className='ab_title'> <img src={cardabs} /></div> */}
                                                </div>
                                                <div className='f_card_detail'>
                                                    <div className='card_count'>
                                                       <div className='loc_app'> <h6>1 room apartment with terrace in Wilmersdorf (Berlin)</h6>
                                                        <p><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
  <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"/>
  <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
</svg><span className='ms-2'>Zechliner Straße 1a Berlin, Wedding </span></p></div>
                                                     <div>   <label>#18,495</label></div>
                                                      
                                                    </div>
                                                    <div className='dimen_app'>
                                                        <div>
                                                        <h6>2244 €</h6>
                                                        <p>Kaltmiete</p>
                                                        </div>
                                                        <div>
                                                        <h6>88,99 m<sup>2</sup></h6>
                                                        <p>Wohnfinance</p>
                                                        </div>
                                                        <div>
                                                        <h6>4</h6>
                                                        <p>Zi</p>
                                                        </div>
                                                    </div>
                                                    <div className='add_btn'>
                                                        <button className=''>Add to Bag</button>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-5 col-lg-3 sticky_right'>
                            <div className='sticky_bag'>
                                <div className='bag_head'>
                                    <h6>My list</h6><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="nft-bag-close-icon"><path d="M18.7071 6.70711C19.0976 6.31658 19.0976 5.68342 18.7071 5.29289C18.3166 4.90237 17.6834 4.90237 17.2929 5.29289L18.7071 6.70711ZM5.29289 17.2929C4.90237 17.6834 4.90237 18.3166 5.29289 18.7071C5.68342 19.0976 6.31658 19.0976 6.70711 18.7071L5.29289 17.2929ZM6.70711 5.29289C6.31658 4.90237 5.68342 4.90237 5.29289 5.29289C4.90237 5.68342 4.90237 6.31658 5.29289 6.70711L6.70711 5.29289ZM17.2929 18.7071C17.6834 19.0976 18.3166 19.0976 18.7071 18.7071C19.0976 18.3166 19.0976 17.6834 18.7071 17.2929L17.2929 18.7071ZM17.2929 5.29289L5.29289 17.2929L6.70711 18.7071L18.7071 6.70711L17.2929 5.29289ZM5.29289 6.70711L17.2929 18.7071L18.7071 17.2929L6.70711 5.29289L5.29289 6.70711Z" fill="currentColor"></path></svg>
                                </div>
                                <div className='em_bag'>
                                    <svg fill="none" xmlns="http://www.w3.org/2000/svg" width="96px" height="96px" viewBox="0 0 24 24" color="var(--genie-colors-neutral3)" stroke-width="1px"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.25 8H7.75V6.5C7.75 4.16 9.66 2.25 12 2.25C14.34 2.25 16.25 4.16 16.25 6.5V8H14.75V6.5C14.75 4.98 13.52 3.75 12 3.75C10.48 3.75 9.25 4.98 9.25 6.5V8ZM18.5 8H16.25V11C16.25 11.41 15.91 11.75 15.5 11.75C15.09 11.75 14.75 11.41 14.75 11V8H9.25V11C9.25 11.41 8.91 11.75 8.5 11.75C8.09 11.75 7.75 11.41 7.75 11V8H5.5C4.67 8 4 8.67 4 9.5V18C4 20 5 21 7 21H17C19 21 20 20 20 18V9.5C20 8.67 19.33 8 18.5 8Z" fill="currentColor"></path></svg>
                                    <h6>Your list is empty</h6>
                                    <p>Selected NFTs will appear here</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

         </div>
<Footer />

            </main>

        </>
    )
}