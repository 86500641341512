import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../assets/css/tabsCommon.scss';
import TabcommData1 from '../components/TabsCommonData.js/TabCommonData1';
import TabcommData2 from './TabsCommonData.js/TabCommonData2';
import TabcommData3 from './TabsCommonData.js/TabCommonData3';
import TabcommData4 from '../components/TabsCommonData.js/TabCommonData4';
import TabcommData5 from '../components/TabsCommonData.js/TabCommonData5';
import TabcommData1c from '../components/TabsCommonData.js/TabCommonData5';
import TabBlog1 from './TabsCommonData.js/TabBlog1';
import TabBlog2 from './TabsCommonData.js/TabBlog2';

const TabsCommonBlog = () => {
  return (
    <Tabs
      defaultActiveKey="Famous"
      id="justify-tab-example"
      className="mb-3 tabs-bg-light"
      justify
    >
      <Tab eventKey="Famous" title="Blog Post">
        <TabBlog1 />
      </Tab>
      <Tab eventKey="Recently Added" title="What people say it">
        <TabBlog2 />
      </Tab>
      
    </Tabs>
  );
}

export default TabsCommonBlog;