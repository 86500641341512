import { useEffect, useState } from 'react';
import HeaderNew2 from "../components/HeaderNew2";
import Footer from "../components/Footer";
import "../assets/css/privacy.scss";
import { Loader } from '../components/Loader';
import { LOADER_TIMER } from '../constants';
import FloatingSearch from '../components/FloatingSearch';

export const TermsUse = () => {
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoader(false);
        }, LOADER_TIMER)
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [])
    return (
        <>
            <Loader show={loader} />
            <main className='privacy_page_main '>
                <HeaderNew2 />
                <div className='container'>
                    <div class="coin_section_coin_section_head termsuse_head"><h3>Terms <br /> <span>of Use</span></h3><p class="smll_text">last update - december 28, 2022</p></div>
                    <div className='privacypage_detail'>
                        <div>
                            <h3>Introduction</h3>
                            <p>These terms and conditions outline the rules and regulations for the use of <b>Futur3's</b> Website
                                <a href='https://futur3.app/'> https://futur3.app/</a>.</p>
                            <p>By accessing this website, we assume you accept these terms and conditions. Do not continue to
                                use Futur3 if you do not agree to take all of the terms and conditions stated on this page.</p>
                            <h4>The following terminology applies to these Terms and Conditions:</h4>
                            <p>"Client", "You" and "Your" refers to you, the person who logs on to this website and is compliant
                                with the Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us",
                                refers to our Company. "Party", "Parties", or "Us", refers to both the Client and ourselves.</p>
                            <p>All terms refer to the offer, acceptance, and consideration of payment necessary to undertake the
                                process of our assistance to the Client in the most appropriate manner for the express purpose of
                                meeting the Client’s needs in respect of the provision of the Company’s stated services, in
                                accordance with and subject to, prevailing law. Any use of the above terminology or other words
                                in the singular, plural, capitalization, and/or he/she or they, are taken as interchangeable and
                                therefore as referring to the same.</p>
                            <h3>Cookies</h3>
                            <p>We employ the use of cookies. By accessing Futur3, you agreed to use cookies in agreement with
                                Futur3's Privacy Policy.</p>
                            <p>Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies
                                are used by our website to enable the functionality of certain areas to make it easier for people
                                visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>
                            <h3>License</h3>
                            <p>Unless otherwise stated, Futur3 and/or its licensors own the intellectual property rights for all
                                material on Futur3. All intellectual property rights are reserved. You may access this from Futur3
                                for your own personal use, subject to restrictions set in these terms of Use.</p>
                            <p>You must not:</p>
                            <ul>
                                <li>Republish material from Futur3 unless otherwise not copyrighted</li>
                                <li>Sell, rent, or sub-license material from Futur3 unless otherwise allowed</li>
                                <li>Reproduce, duplicate or copy material from Futur3</li>
                            </ul>
                            <p>This Agreement shall begin on the date hereof. Parts of this website offer an opportunity for users
                                to post and exchange opinions and information in certain areas of the website.</p>
                            <h3>Hyperlinking to our Content</h3>
                            <p>The following organizations may link to our Website without prior written approval:</p>
                            <ul>
                                <li>Government agencies;</li>
                                <li>Search engines;</li>
                                <li>News organizations;</li>
                                <li>Online directory distributors may link to our Website in the same manner as they hyperlink
                                    to the Websites of other listed businesses; and</li>
                                <li>System-wide Accredited Businesses except soliciting non-profit organizations, charity
                                    shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
                            </ul>
                            <p>These organizations may link to our home page, to publications, or to other Website information
                                so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship,endorsement or approval of the linking party and its products and/or services; and (c) fits within
                                the context of the linking party’s site.</p>
                            <p>We will approve link requests from organizations as well if we decide that: (a) the link would not
                                make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does
                                not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink
                                compensates the absence of Futur3; and (d) the link is in the context of general resource
                                information.</p>
                            <p>These organizations may link to our home page so long as the link: (a) is not in any way deceptive;
                                (b) does not falsely imply sponsorship, endorsement, or approval of the linking party and its
                                products or services; and (c) fits within the context of the linking party’s site.</p>
                            <p>If you are one of the organizations listed in paragraph 2 above and are interested in linking to our
                                website, you must inform us by sending an e-mail to <a href='mailto:foundation@futur3.app'> foundation@futur3.app</a>. Please include your
                                name, and contact information as well as the URL of your site, a list of any URLs from which you
                                intend to link to our Website, and a list of the URLs on our site to which you would like to link.
                                Then wait for a response.</p>
                            <h3>Content Liability</h3>
                            <p>We shall not be held responsible for any content that appears on your Website. You agree to protect
                                and defend us against all claims that are rising on your Website. No link(s) should appear on any
                                Website that may be interpreted as libelous, obscene, or criminal, or which infringes, otherwise
                                violates, or advocates the infringement or other violation of, any third-party rights.</p>
                            <h3>Your Privacy</h3>
                            <p>Please read our Privacy Policy</p>
                            <h3>Reservation of Rights</h3>
                            <p>We reserve the right to request that you remove all links or any particular link to our Website. You
                                approve of immediately removing all links to our Website upon request. We also reserve the right
                                to amend these terms and conditions and its linking policy at any time. By continuously linking to
                                our Website, you agree to be bound to and follow these linking terms and conditions.</p>
                            <h3>Removal of links from our website</h3>
                            <p>If you find any link on our Website that is offensive for any reason, you are free to contact and
                                inform us at any moment. We will consider requests to remove links but we are not obligated to
                                or so or to respond to you directly.</p>
                            <p>We do not ensure that the information on this website is correct, we do not warrant its completeness
                                or accuracy; nor do we promise to ensure that the website remains available or that the material on
                                the website is kept up to date.</p>
                            <h3>Contact Us</h3>
                            <p>If you have any queries about these Terms of Use, please contact us by email: 
                                <a href='mailto: foundation@futur3.app'> foundation@futur3.app</a></p>
                        </div>
                    </div>
                </div>
                <Footer />
                <FloatingSearch />
            </main>
        </>
    )
}