import { useEffect, useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import HeaderNew2 from "../components/HeaderNew2";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import "../assets/css/privacy.scss";
import { Loader } from '../components/Loader';
import { LOADER_TIMER } from '../constants';
import FloatingSearch from '../components/FloatingSearch';

export const Privacy = () => {
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoader(false);
        }, LOADER_TIMER)
        return () => {
            if (timeout) {
                clearTimeout(timeout)
            }
        }
    }, [])
    return (
        <>
            <Loader show={loader} />
            <main className='privacy_page_main'>
                <HeaderNew2 />
                <section className='faq_section privacy_page'>
                    <div className='container'>
                        <div className="coin_section_coin_section_head">
                            <h3>Privacy <br></br> Policy</h3>
                            <p className='smll_text'>last update - december 28, 2022</p>
                          
                        </div>
                        <div className='privacypage_detail'>
                            <div>
                                <h3>Introduction</h3>
                                <p>Welcome to <b>Futur3!</b></p>
                                <p>Futur3 (“us”, “we”, or “our”) operates as <a href='https://futur3.app/'> https://futur3.app/</a> (hereinafter referred to
as <b>“Service”</b>).</p>
<p>Our Privacy Policy governs your visit to <a href='https://futur3.app/'> https://futur3.app/</a>  and explains how we
collect, safeguard and disclose information that results from your use of our Services.</p>
<p>We use your data to provide and improve our Services. By using it, you agree to the collection and
use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy,
the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>
<p>Our Terms and Conditions <b>(“Terms”)</b> govern all use of our Service and together with the Privacy
Policy constitute your agreement with us <b>(“Agreement”)</b>.</p>
                                
                            </div>
                            <div>
                                <h3>Definitions</h3>
                                <p><b>SERVICE</b> means the <a href='https://futur3.app/'> https://futur3.app/</a> website.</p>
                                <p><b>PERSONAL DATA</b> means data about a living individual who can be identified from those data
(or from those and other information either in our possession or likely to come into our possession).</p>
<p><b>USAGE DATA</b>is data collected automatically either generated by the use of the Service or from
the Service infrastructure itself (for example, the duration of a page visit).</p>
<p><b>COOKIES</b>are small files stored on your device (computer or mobile device).</p>
<p><b>DATA CONTROLLER</b>means a natural or legal person who (either alone or jointly or in
common with other persons) determines the purposes for which and the manner in which any
personal data are, or are to be, processed.</p>
<p><b>DATA PROCESSORS (OR SERVICE PROVIDERS)</b>means any natural or legal person who
processes the data on behalf of the Data Controller. We may use the services of various Service
Providers in order to process your data more effectively.</p>
<p><b>DATA SUBJECT</b>is any living individual who is the subject of Personal Data.</p>
<p><b>THE USER</b>is the individual using our Service. The User corresponds to the Data Subject, who is
the subject of Personal Data.</p>
<h3>Information Collection and Use</h3>
<p>We collect several different types of information for various purposes to provide and improve our
Service to you.</p>
<h3>Types of Data Collected</h3>
<h5>Personal Data</h5>
<p>While using our Service, we may ask you to provide us with certain personally identifiable
information that can be used to contact or identify you (“Personal Data”). Personally identifiable
information may include, but is not limited to:</p>
<ul className='dash_list'>
    <li>Email address</li>
    <li>First name and last name</li>
    <li>Address, Country, etc.</li>
    <li>Cookies and Usage Data</li>
</ul>
<p>We may use your Personal Data to contact you with newsletters, marketing or promotional
materials, and other information that may be of interest to you. You may opt out of receiving any,
or all, of these communications from Us.</p>
<h5>Usage Data</h5>
<p>We may also collect information that your browser sends whenever you visit our Service or when
you access the Service by or through any device <b>(“Usage Data”)</b>.</p>
<p>This Usage Data may include information such as your computer’s Internet Protocol address (e.g.
IP address), browser type, browser version, the pages of our Service that you visit, the time and
date of your visit, the time spent on those pages, unique device identifiers and other diagnostic
data.</p>
<p>When you access Service with a device, this Usage Data may include information such as the type
of device you use, your device unique ID, the IP address of your device, your device operating
system, the type of Internet browser you use, unique device identifiers and other diagnostic data.</p>
<h5>Location Data</h5>
<p>We may use and store information about your location if you give us permission to do so
<b>(“Location Data”)</b>. We use this data to provide features of our Service to improve and customize
our Service.</p>
<p>You can enable or disable location services when you use our Service at any time by way of your
device settings.</p>
<h5>Tracking Cookies Data</h5>
<p>We use cookies and similar tracking technologies to track the activity on our Service and we hold
certain information.</p>
<p>Cookies are files with a small amount of data which may include an anonymous unique identifier.
Cookies are sent to your browser from a website and stored on your device. Other tracking
technologies are also used such as beacons, tags, and scripts to collect and track information and
to improve and analyze our Service.</p>
<p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
<h3>Use of Data</h3>
<p>Futur3 uses the collected data for various purposes:</p>
<ul className='circle_list'>
    <li>To provide and maintain our service;</li>
    <li>To notify you about changes to our service;</li>
    <li>To allow you to participate in interactive features of our service when you choose to do so;</li>
    <li>To provide customer support;</li>
    <li>To gather analysis or valuable information so that we can improve our service;</li>
    <li>To monitor the usage of our service;</li>
    <li>To detect, prevent and address technical issues;</li>
    <li>To fulfill any other purpose for which you provide it;</li>
    <li>To carry out our obligations and enforce our rights arising from any contracts entered into
between you and us;</li>
    <li>To provide you with news, special offers, and general information about other goods,
services, and events which we offer that are similar to those that you have already
purchased or enquired about unless you have opted not to receive such information;</li>
    <li>In any other way we may describe when you provide the information;</li>
    <li>For any other purpose with your consent.</li>
</ul>
<h3>Retention of Data</h3>
<p>We will retain your Personal Data only for as long as is necessary for the purposes set out in this
Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with
our legal obligations (for example, if we are required to retain your data to comply with applicable
laws), resolve disputes, and enforce our legal agreements and policies.</p>
<p>We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for
a shorter period, except when this data is used to strengthen the security or to improve the
functionality of our Service, or we are legally obligated to retain this data for longer time periods.</p>
<h3>Transfer of Data</h3>
<p>Your consent to this Privacy Policy followed by your submission of such information represents
your agreement to data transfer.</p>
<p>Futur3 will take all the steps reasonably necessary to ensure that your data is treated securely and
in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an
organization or a country unless there are adequate controls in place including the security of your
data and other personal information.</p>
<h3>Disclosure of Data</h3>
<p>We may disclose personal information that we collect, or you provide:</p>
<h5>Disclosure for Law Enforcement.</h5>
<p>Under certain circumstances, we may be required to disclose your Personal Data if required to do
so by law or in response to valid requests by public authorities.</p>
<h5>Business Transaction.</h5>
<p>If we or our subsidiaries are involved in a merger, acquisition, or asset sale, your Personal Data
may be transferred.</p>
<h3>Security of Data</h3>
<p>The security of your data is important to us but remember that no method of transmission over the
Internet or method of electronic storage is 100% secure. While we strive to use commercially
acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
<p><b>To delete your personal information. If you make this request, we will delete the personal
information we hold about you as of the date of your request from our records and direct
any service providers to do the same. In some cases, deletion may be accomplished through
the de-identification of the information. If you choose to delete your personal information,
you may not be able to use certain functions that require your personal information to
operate.</b></p>
<p>To exercise your data protection rights, please send your request(s) to email:
<b><a href='mailto:foundation@futur3.app' className='text-white'> foundation@futur3.app</a></b></p>
<h5>Children’s Privacy</h5>
<p>Our Services are not intended for use by children under the age of 18 (<b>“Child”</b> or <b>“Children”)</b>.</p>
<p>We do not knowingly collect personally identifiable information from children under 18. If you
become aware that a Child has provided us with Personal Data, please contact us. If we become
aware that we have collected Personal Data from Children without verification of parental consent,
we take steps to remove that information from our servers.</p>
<h5>Changes to This Privacy Policy</h5>
<p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting
the new Privacy Policy on this page.</p>
<p>We will let you know via email and/or a prominent notice on our Service, prior to the change
becoming effective, and update the “effective date” at the top of this Privacy Policy.</p>
<p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
Policy are effective when they are posted on this page.</p>
<h5>Contact Us</h5>
<p>If you have any questions about this Privacy Policy, please contact us by email:
<a href='mailto:foundation@futur3.app'> foundation@futur3.app</a></p>
                                
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <FloatingSearch />
            </main>
        </>
    )
}