import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { Tab1 } from './TabsHeader/Tab1';
import { Tab2 } from './TabsHeader/Tab2';

function TabsHeader() {
    return (
        <Tabs
            defaultActiveKey="profile"
            id="justify-tab-example"
            className="mb-3"
            justify
        >
            <Tab eventKey="home" title="New Projects">
                <Tab1 />
            </Tab>
            <Tab eventKey="profile" title="New Tokens">
                <Tab1 />
            </Tab>
            <Tab eventKey="longer-tab" title="NFTs">
                <Tab1 />
            </Tab>
      
        </Tabs>
    );

}

export default TabsHeader;