import React, { useCallback, useEffect, useState, useRef } from "react";
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import gencoin from '../assets/images/gen.png';
import Tabs from "./Tabs";
import Search from "./Search";
import logoicon from '../assets/images/icon.png';
import logotech from '../assets/images/tchy logo.png';
import { Banner } from "./HeaderComponents/Bannner";
import { MobileHeader } from "./HeaderComponents/MobileHeader";
import { SEARCH_BUTTONS } from "../constants";
import utick from '../assets/images/u_tick.svg';
import oreocoin from '../assets/images/oreo.png';


function NavScrollExample() {
  const Ref = useRef(null);
  const [showTabs, setShowTabs] = useState(false)


  const onSearchFocusIn = useCallback(() => {
    setShowTabs(true)
  }, [showTabs])

  const onSearchFocusOut = useCallback(() => {
    Ref.current.blur();
    setShowTabs(false)
  }, [showTabs])

  const handleClickOutside = (event) => {
    if (
      Ref.current &&
      !Ref.current.contains(event.target)
    ) {
      onSearchFocusOut()
    }

  }


  const onEscapeClick = (event) => {
    console.log(event.key, Ref.current, event, Ref.current === event.target);
    if (Ref.current && Ref.current === event.target && event.key.toUpperCase() === "ESCAPE") {
      onSearchFocusOut();
    }
  }


  useEffect(() => {
    document
      .addEventListener('mousedown', handleClickOutside);

    return () => {
      document
        .removeEventListener('mousedown', handleClickOutside);
    }
  }, [])

  return (
    <>
      <Banner />

      <Navbar expand="lg" className="cus-nav sticky_header sticky_header2 pc new_header uni_header2 ">

        <Container>

          <Navbar.Brand className="text-white header_icon">
            <a href="/home"> <img src={logotech} width="30" /></a>
          </Navbar.Brand>
          {/* <Navbar.Toggle aria-controls="navbarScroll" /> */}
          <Navbar.Toggle aria-controls="navbarScroll"><svg width="30" height="40" viewBox="0 0 121 106" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.4249 25.0009C12.0696 25.0234 10.7797 25.4473 9.8317 26.1814C8.88365 26.9154 8.35277 27.9012 8.35277 28.9281C8.35277 29.955 8.88365 30.9413 9.8317 31.6754C10.7797 32.4094 12.0696 32.8328 13.4249 32.8553H106.775C107.465 32.8668 108.152 32.774 108.794 32.5817C109.436 32.3895 110.021 32.1019 110.515 31.7361C111.009 31.3703 111.401 30.9336 111.669 30.4515C111.936 29.9693 112.074 29.4512 112.074 28.9281C112.074 28.4051 111.936 27.8874 111.669 27.4053C111.401 26.9231 111.009 26.486 110.515 26.1202C110.021 25.7544 109.436 25.4672 108.794 25.275C108.152 25.0828 107.465 24.9895 106.775 25.0009H13.4249ZM13.4249 48.5637C12.0486 48.5637 10.7286 48.9779 9.75536 49.7151C8.78212 50.4523 8.23537 51.4524 8.23537 52.4949C8.23537 53.5375 8.78212 54.5376 9.75536 55.2748C10.7286 56.012 12.0486 56.4257 13.4249 56.4257H106.775C108.151 56.4257 109.471 56.012 110.445 55.2748C111.418 54.5376 111.965 53.5375 111.965 52.4949C111.965 51.4524 111.418 50.4523 110.445 49.7151C109.471 48.9779 108.151 48.5637 106.775 48.5637H13.4249ZM12.9288 72.1345C11.5559 72.188 10.2669 72.6513 9.34423 73.4232C8.42152 74.1951 7.94029 75.2123 8.00593 76.2524C8.07157 77.2925 8.67874 78.2708 9.69444 78.9725C10.7101 79.6742 12.0516 80.0422 13.4249 79.9962H106.775C108.148 79.9924 109.463 79.577 110.432 78.8402C111.402 78.1034 111.946 77.1055 111.946 76.0653C111.946 75.0252 111.402 74.0277 110.432 73.2909C109.463 72.5541 108.148 72.1382 106.775 72.1345H13.4249C13.2596 72.1285 13.0941 72.1285 12.9288 72.1345Z" fill="white" />
          </svg></Navbar.Toggle>
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0 search_inpt"
              style={{ maxHeight: '100px' }}
              navbarScroll
            >
              <div className="serach_container" ref={Ref} onKeyUp={(e) => onEscapeClick(e)} tabIndex="0">
                <Search onSearchFocusIn={onSearchFocusIn} showTabs={showTabs} onSearchFocusOut={onSearchFocusOut} buttonType={SEARCH_BUTTONS.ESCAPE} />
                {showTabs && <div className="header_search_result">
                  <div className="header_s">
                  <div className="saerch_col"><p className="top_filter">Most relevant</p>
                    <div class="header_list">
                      <div class="header_list_left">
                        <img src={gencoin} />
                        <div><div className="serch_tag"><h6>Damien Hirst - The Currency</h6><img src={utick} /></div>
                          <p>Mutant Ape Yacht Club</p></div>
                      </div><div class="header_list_right">
                        <div class="shw_del">
                          <div><h6>5.10 ETH</h6>
                          </div><p>Floor</p></div></div></div>
                    <div class="header_list">
                      <div class="header_list_left">
                        <img src={gencoin} />
                        <div><div className="serch_tag"><h6>#4732</h6><img src={utick} /></div>
                          <p>Mutant Ape Yacht Club</p></div>
                      </div><div class="header_list_right">
                        <div class="shw_del">
                          <div><h6>5.10 ETH</h6>
                          </div><p>$8,435.0</p></div></div></div>
                    <div class="header_list">
                      <div class="header_list_left">
                        <img src={gencoin} />
                        <div><div className="serch_tag"><h6>#4732</h6><img src={utick} /></div>
                          <p>Mutant Ape Yacht Club</p></div>
                      </div><div class="header_list_right">
                        <div class="shw_del">
                          <div><h6>5.10 ETH</h6>
                          </div><p>$8,435.0</p></div></div></div>
           
                    <div class="header_list">
                      <div class="header_list_left">
                        <img src={gencoin} />
                        <div><div className="serch_tag"><h6>#4732</h6><img src={utick} /></div>
                          <p>Mutant Ape Yacht Club</p></div>
                      </div><div class="header_list_right">
                        <div class="shw_del">
                          <div><h6>5.10 ETH</h6>
                          </div><p>$8,435.0</p></div></div></div></div>


                          <div className="saerch_col"><p className="top_filter">Tips & Tricks</p>
                    <div class="header_list">
                      <div class="header_list_left">
                        <img src={oreocoin} />
                        <div><div className="serch_tag"><h6>Damien Hirst - The Currency</h6><img src={utick} /></div>
                          <p>Mutant Ape Yacht Club</p></div>
                      </div><div class="header_list_right">
                        <div class="shw_del">
                          <div><h6>5.10 ETH</h6>
                          </div><p>Floor</p></div></div></div>
                    <div class="header_list">
                      <div class="header_list_left">
                        <img src={oreocoin} />
                        <div><div className="serch_tag"><h6>#4732</h6><img src={utick} /></div>
                          <p>Mutant Ape Yacht Club</p></div>
                      </div><div class="header_list_right">
                        <div class="shw_del">
                          <div><h6>5.10 ETH</h6>
                          </div><p>$8,435.0</p></div></div></div>
            
           </div>
           <div className="saerch_col"><p className="top_filter">Attractions</p>
                    <div class="header_list">
                      <div class="header_list_left">
                        <img src={oreocoin} />
                        <div><div className="serch_tag"><h6>Damien Hirst - The Currency</h6><img src={utick} /></div>
                          <p>Mutant Ape Yacht Club</p></div>
                      </div><div class="header_list_right">
                        <div class="shw_del">
                          <div><h6>5.10 ETH</h6>
                          </div><p>Floor</p></div></div></div>
                    <div class="header_list">
                      <div class="header_list_left">
                        <img src={oreocoin} />
                        <div><div className="serch_tag"><h6>#4732</h6><img src={utick} /></div>
                          <p>Mutant Ape Yacht Club</p></div>
                      </div><div class="header_list_right">
                        <div class="shw_del">
                          <div><h6>5.10 ETH</h6>
                          </div><p>$8,435.0</p></div></div></div>
            
           </div>
                  </div>
                </div>}
              </div>
              <div className="header_left_links"><a href="/apartmentdetail">Living</a>
                <a href="/museum">Attractions</a><a href="/blog">Tips & Tricks</a>
                <a href="#">Crypto</a></div>

            </Nav>
            <Nav>
     

              <Nav.Link href="#action1" className="reward_btn"><span className="ad_btn">
                {/* <svg id="Layer_1" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m7.6 13h1.6l-.8-2.1z" /><path d="m15.9 12c-.5.1-.8.4-.9.9s.3 1 .9 1.1h.1 1v-2h-1z" /><path d="m21 3h-18c-.6 0-1 .4-1 1v16c0 .6.4 1 1 1h18c.6 0 1-.4 1-1v-16c0-.6-.4-1-1-1zm-10.6 13-.4-1h-3.2l-.4 1h-2.2l3.2-8h2l3.2 8zm8.6 0h-3-.2c-1.6-.1-2.8-1.4-2.8-3 0-1.7 1.3-3 3-3h1v-2h2z" /></g></svg> */}
              </span><span className="ad_grade_colorold new_ad">Listing</span></Nav.Link>
              <Nav.Link href="#action1" className="reward_btn p-0"><span className="ad_btn">
                {/* <svg id="Layer_1" enable-background="new 0 0 24 24" height="512" viewBox="0 0 24 24" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m7.6 13h1.6l-.8-2.1z" /><path d="m15.9 12c-.5.1-.8.4-.9.9s.3 1 .9 1.1h.1 1v-2h-1z" /><path d="m21 3h-18c-.6 0-1 .4-1 1v16c0 .6.4 1 1 1h18c.6 0 1-.4 1-1v-16c0-.6-.4-1-1-1zm-10.6 13-.4-1h-3.2l-.4 1h-2.2l3.2-8h2l3.2 8zm8.6 0h-3-.2c-1.6-.1-2.8-1.4-2.8-3 0-1.7 1.3-3 3-3h1v-2h2z" /></g></svg> */}
              </span>    <a href="" className="active_btn">Login</a></Nav.Link>


            </Nav>

          </Navbar.Collapse>
        </Container>
      </Navbar>

      <MobileHeader />

    </>

  );
}

export default NavScrollExample;