import React from "react";
import './App.css';
import { ROUTES } from "./routes/routes";
import { Home } from "./pages/Home";
import { HomeNew } from "./pages/HomeNew";
import { HomeNew2 } from "./pages/HomeNew2";
import { BrowserRouter as Router, Route, Routes, Navigate   } from "react-router-dom";
import { NoPage } from "./pages/NoPage";
import { Faq } from "./pages/Faq";
import { Submit } from "./pages/Submit";
import { Privacy } from "./pages/Privacy";
import { Step } from "./pages/StepForm";
import { Contact } from "./pages/Contact";
import { BlogPost } from "./pages/BlogPost";
import { Launchpads } from "./pages/Launchpads";
import { CoinPage } from "./pages/CoinPage";
import { Exchanges } from "./pages/Exchanges";
import { BlogPage } from "./pages/BlogPage";
import { Disclaimer } from "./pages/Disclaimer";
import { Dyor } from "./pages/Dyor";
import { TermsUse } from "./pages/TermsUse";
import { CoinPageDetail } from "./pages/CoinPageDetail";
import { AboutUs } from "./pages/AboutUs";
import { Wise } from "./pages/Wise";
import { Uniswap } from "./pages/Uniswap";
import { Uniswap2 } from "./pages/Uniswap2";
import { Uniswap3 } from "./pages/Uniswap3";
import { ApartmentDetail } from "./pages/ApartmentDetail";
import { Imprint } from "./pages/Imprint";
import { Login } from "./pages/Login";
import { Signup } from "./pages/Signup";
import { Password } from "./pages/Password";
import { RecoverPass } from "./pages/Recoverpassword";
import { CoinPage2 } from "./pages/CoinPage2";
import { Uniswap4 } from "./pages/Uniswap4";
import { Search } from "./pages/Search";
import { BlogPost2 } from "./pages/BlogPost2";
import { CoinPage3 } from "./pages/CoinPage3";
import { University } from "./pages/University";
import { Profile } from "./pages/Profile";
import { Museum } from "./pages/Museum";
import { Museum2 } from "./pages/Museum2";


function App() {
  return (
    <>
     <Router basename="/">
        <Routes>
            <Route index element={<Home />} />
            <Route path={ROUTES.HOME} element={<Home />} />
            <Route path={ROUTES.HOMENEW} element={<HomeNew />} />
            <Route path={ROUTES.HOMENEW2} element={<HomeNew2 />} />
            <Route path={ROUTES.FAQ} element={<Faq />} />
            <Route path={ROUTES.SUBMIT} element={<Submit />} />
            <Route path={ROUTES.PRIVACY} element={<Privacy />} />
            <Route path={ROUTES.STEP} element={<Step />} />
            <Route path={ROUTES.CONTACT} element={<Contact />} />
            <Route path={ROUTES.BLOGPOST} element={<BlogPost />} />
            <Route path={ROUTES.LAUNCHPADS} element={<Launchpads />} />
            <Route path={ROUTES.COINPAGE} element={<CoinPage />} />
            <Route path={ROUTES.EXCHANGES} element={<Exchanges />} />
            <Route path={ROUTES.BLOGPAGE} element={<BlogPage />} />
            <Route path={ROUTES.NOPAGE} element={<NoPage />} />
            <Route path={ROUTES.DISCLAIMER} element={<Disclaimer />} />
            <Route path={ROUTES.DYOR} element={<Dyor />} />
            <Route path={ROUTES.TERMSUSE} element={<TermsUse />} />
            <Route path={ROUTES.COINPAGEDETAIL} element={<CoinPageDetail />} />
            <Route path={ROUTES.ABOUTUS} element={<AboutUs />} />
            <Route path={ROUTES.WISE} element={<Wise />} />
            <Route path={ROUTES.UNISWAP} element={<Uniswap />} />
            <Route path={ROUTES.UNISWAP2} element={<Uniswap2 />} />
            <Route path={ROUTES.UNISWAP3} element={<Uniswap3 />} />
            <Route path={ROUTES.APARTMENTDETAIL} element={<ApartmentDetail />} />
            <Route path={ROUTES.IMPRINT} element={<Imprint />} />
            <Route path={ROUTES.LOGIN} element={<Login />} />
            <Route path={ROUTES.SIGNUP} element={<Signup />} />
            <Route path={ROUTES.PASSWORD} element={<Password />} />
            <Route path={ROUTES.RECOVERPASS} element={<RecoverPass />} />
            <Route path={ROUTES.COINPAGE2} element={<CoinPage2 />} />
            <Route path={ROUTES.UNISWAP4} element={<Uniswap4 />} />
            <Route path={ROUTES.SEARCH} element={<Search />} />
            <Route path={ROUTES.BLOGPOST2} element={<BlogPost2 />} />
            <Route path={ROUTES.COINPAGE3} element={<CoinPage3 />} />
            <Route path={ROUTES.UNIVERSITY} element={<University />} />
            <Route path={ROUTES.PROFILE} element={<Profile />} />
            <Route path={ROUTES.MUSEUM} element={<Museum />} />
            <Route path={ROUTES.MUSEUM2} element={<Museum2 />} />

            <Route path="*"  element={<Navigate to={ROUTES.NOPAGE} replace />}  />
          {/* <Route path={ROUTES.LANDING_PAGE} element={<Home />}>           
          </Route> */}
        </Routes>
      </Router>
    </>

  );
}

export default App;
